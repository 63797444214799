import {Popover, useTheme} from "@mui/material";
import React from "react";
import "./themePopover.css";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import {type ColorMode, ColorModeEnum, setColorMode, setMode, ThemeEnum} from "../../services/theme/themeSlice";
import {AcClipboard} from "../icon/AcColor";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean
    onClose: any
    anchorEl: Element | null
}

export default function ThemePopover (props: Props) {
    const {mode} = useSelector((state: RootState) => state.theme);
    const dispatch = useDispatch<AppDispatch>();
    const theme = useTheme();
    const { t } = useTranslation();

    const handleChangeMode = () => {
      dispatch(setMode(mode === ThemeEnum.light ? ThemeEnum.dark : ThemeEnum.light));
    };

    const handleChangeColorMode = (colorMode: ColorMode) => {
        dispatch(setColorMode(colorMode));
    };

    return <Popover
        open={props.open}
        onClose={props.onClose}
        anchorEl={props.anchorEl}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
        }}
        transformOrigin={{
            vertical: "bottom",
            horizontal: "left"
        }}
    >
        <div className="theme-popover">
            <p className="theme-mode" onClick={handleChangeMode}>
                <AcClipboard width="16px" color={theme.palette.text.primary} />
                {mode === ThemeEnum.light ? t("Dark Mode") : t("Light Mode")}
            </p>
            {
                mode === ThemeEnum.light &&
                <>
                    <p className="theme-color-switching">
                        <span className="theme-color theme-color-mode"></span>{t("Color Switching")}
                    </p>
                    <p className="theme-color-list">
                        <span className="theme-color slate-blue" onClick={() => {
                            handleChangeColorMode(ColorModeEnum.slate_blue);
                        }}></span>
                        <span className="theme-color steel-blue" onClick={() => {
                            handleChangeColorMode(ColorModeEnum.steel_blue);
                        }}></span>
                        <span className="theme-color warm-gray" onClick={() => {
                            handleChangeColorMode(ColorModeEnum.warm_gray);
                        }}></span>
                        <span className="theme-color dusty-rose" onClick={() => {
                            handleChangeColorMode(ColorModeEnum.dusty_rose);
                        }}></span>
                        <span className="theme-color blue-gray" onClick={() => {
                            handleChangeColorMode(ColorModeEnum.blue_gray);
                        }}></span>
                    </p>
                </>
            }
        </div>
    </Popover>;
}
