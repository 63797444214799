import "./dataAggregation.css";
import Layout from "../layout";
import React, {useEffect, useState} from "react";
import {Button, TextField, Tooltip} from "@mui/material";
import type {DataAggregationDay, DataAggregationResponse} from "../../../types/types";
import axios from "axios";
import {getCookieByName} from "../../../utils/CookieUtil";
import {useSelector} from "react-redux";
import type {RootState} from "../../../store";
import {handleErrorMessage, replaceEmoji} from "../../../utils/utils";
import {useSnackbar} from "notistack";
import moment, {type Moment} from "moment/moment";
import {DatePicker} from "@mui/x-date-pickers";
import {HH_MM_FORMAT, INPUT_FORMAT_DATE} from "../../../constants/constant";
import {useTranslation} from "react-i18next";
import Loading from "../../../components/loading/Loading";

export default function DataAggregationPage () {
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const { selectSectionId, sectionList } = useSelector((state: RootState) => state.sora);
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();
    const [dataAggregationList, setDataAggregationList] = useState<DataAggregationDay[]>([]);
    const [startDate, setStartDate] = React.useState<Moment | null>(moment().subtract(30, "days"));
    const [endDate, setEndDate] = React.useState<Moment | null>(moment());
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let timeout: any;
        if (sectionList) {
            timeout = setTimeout(() => {
                getDataAggregationList();
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [selectSectionId, sectionList]);

    const getDataAggregationList = () => {
        if (!startDate || !endDate) return;
        setDataAggregationList([]);
        const params = new URLSearchParams();
        params.set("u", userId);
        params.set("a", authToken);
        params.set("start_date", startDate.format(INPUT_FORMAT_DATE));
        params.set("end_date", endDate.format(INPUT_FORMAT_DATE));
        if (selectSectionId) {
            params.set("bbt_section_id", selectSectionId);
        }
        setLoading(true);
        axios.get<DataAggregationResponse>(`${process.env.REACT_APP_SESSION_API || ""}/data_aggregation?${params.toString()}`)
            .then(response => {
                const dataAggregationList: DataAggregationDay[] = [];
                response.data.data_aggregation.forEach(dataAggregation => {
                    const createdTime = moment(dataAggregation.created_time).format(INPUT_FORMAT_DATE);
                    const dataAggregationFind = dataAggregationList.find(item => item.day === createdTime);
                    if (dataAggregationFind) {
                        dataAggregationFind.dataAggregation.push(dataAggregation);
                    } else {
                        const dataAggregationD: DataAggregationDay = {
                            day: createdTime,
                            dataAggregation: [dataAggregation]
                        };
                        dataAggregationList.push(dataAggregationD);
                    }
                });
                setDataAggregationList(dataAggregationList);
            })
            .catch(err => {
                handleErrorMessage(err, enqueueSnackbar);
            }).finally(() => {
                setLoading(false);
            });
    };

    const handleStartDateChange = (value: any) => {
        setStartDate(value);
    };

    const handleEndDateChange = (value: any) => {
        setEndDate(value);
    };

    const idButtonDisabled = (): boolean => {
        return !startDate || !endDate || (startDate > endDate);
    };

    const getPositionStyle = (roomStartString: string, roomEndString: string, timeString: string) => {
        const start = new Date(roomStartString).getTime();
        const end = new Date(roomEndString).getTime();
        const time = new Date(timeString).getTime();

        if (time < start || time > end) {
            return {
                left: 0
            };
        }

        let percentage = ((time - start) / (end - start)) * 100;
        if (percentage > 95) {
            percentage = 95;
        }
        return {
            left: `${percentage.toFixed(2)}%`
        };
    };

    const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>, imgDefault: string) => {
        event.currentTarget.src = imgDefault;
    };

    return <Layout>
        <div className="data-aggregation">
            <div className="date-picker">
                <DatePicker className="start-date-picker" inputFormat={INPUT_FORMAT_DATE} value={startDate}
                            onChange={handleStartDateChange} label={t("Start date")} maxDate={endDate}
                            renderInput={props => <TextField error={true} {...props} />}/>
                <DatePicker className="start-date-picker" inputFormat={INPUT_FORMAT_DATE} value={endDate}
                            onChange={handleEndDateChange} label={t("End date")} maxDate={moment()}
                            renderInput={props => <TextField {...props} />}/>
                <Button onClick={getDataAggregationList} disabled={idButtonDisabled()}
                        variant="contained">{t("Search")}</Button>
            </div>
            <div>
                <div className="data-aggregation-loading">
                    {
                        loading && <Loading width="50px" height="50px"/>
                    }
                </div>
                {
                    dataAggregationList.map((dataAggregation, index) => {
                        return <div key={index} className="data-aggregation-date">
                            <span className="data-aggregation-day">{dataAggregation.day}</span>
                            <div className="data-aggregation-meetings">
                                {
                                    dataAggregation.dataAggregation.map((dataAggregationMeeting, index) => {
                                        return <div key={index} className="data-aggregation-meeting">
                                            <div className="meeting-data meeting-time">
                                                <span className="meeting-title">{dataAggregationMeeting.channel_name}</span>
                                                <span className="meeting-start-end">
                                                    { `${moment(dataAggregationMeeting.created_time).format(HH_MM_FORMAT)} - ${moment(dataAggregationMeeting.destroyed_time).format(HH_MM_FORMAT)}` }
                                                </span>
                                            </div>
                                            <div className="meeting-data">
                                                {
                                                    dataAggregationMeeting.participant_list.map((participant, index) => {
                                                       return <Tooltip key={index} title={`${moment(participant.created_time).format(HH_MM_FORMAT)} - ${participant.name} join room`}>
                                                           <img style={getPositionStyle(dataAggregationMeeting.created_time, dataAggregationMeeting.destroyed_time, participant.created_time)}
                                                                className="meeting-img meeting-user-img" src={participant.image_url ? participant.image_url : "/img/ac-user-02.png"}
                                                                onError={(err) => { handleImageError(err, "/img/ac-user-02.png"); }} alt=""/>
                                                       </Tooltip>;
                                                    })
                                                }
                                                {
                                                    dataAggregationMeeting.participant_list.map((participant, index) => {
                                                        return <Tooltip key={index} title={`${moment(participant.destroyed_time).format(HH_MM_FORMAT)} - ${participant.name} escape room`}>
                                                            <img style={getPositionStyle(dataAggregationMeeting.created_time, dataAggregationMeeting.destroyed_time, participant.destroyed_time)}
                                                                 className="meeting-img meeting-user-img" src={participant.image_url ? participant.image_url : "/img/ac-user-03.png"}
                                                                 onError={(err) => { handleImageError(err, "/img/ac-user-03.png"); }} alt=""/>
                                                        </Tooltip>;
                                                    })
                                                }
                                                {
                                                    dataAggregationMeeting.reaction_list.map((reaction, index) => {
                                                        return <Tooltip key={index} title={`${moment(reaction.created_at).format(HH_MM_FORMAT)} - ${reaction.name} reaction`}>
                                                            <img style={getPositionStyle(dataAggregationMeeting.created_time, dataAggregationMeeting.destroyed_time, reaction.created_at)}
                                                                 className="meeting-img" src={`/img/emoji/${reaction.reaction}.gif`} alt=""/>
                                                        </Tooltip>;
                                                    })
                                                }
                                                {
                                                    dataAggregationMeeting.room_chat_list.map((roomChat, index) => {
                                                        return <Tooltip key={index} title={<span dangerouslySetInnerHTML={{ __html: `${moment(roomChat.created_time).format(HH_MM_FORMAT)} - ${roomChat.name} chat ${replaceEmoji(roomChat.chat)}` }} />}>
                                                            <img style={getPositionStyle(dataAggregationMeeting.created_time, dataAggregationMeeting.destroyed_time, roomChat.created_time)}
                                                                 className="meeting-img" src="/img/chat_24dp_FFFFFF.png" alt=""/>
                                                        </Tooltip>;
                                                    })
                                                }
                                            </div>
                                        </div>;
                                    })
                                }
                            </div>
                        </div>;
                    })
                }
            </div>
        </div>
    </Layout>;
}
