import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import "./deviceModal.css";
import {useTranslation} from "react-i18next";
import React from "react";

interface DeviceErrorModalProps {
    open?: any
    handleClose: any
}

function DeviceErrorModal (props: DeviceErrorModalProps) {
    const {t} = useTranslation();

    return <Dialog
        open={props.open}
        onClose={props.handleClose}>
        <DialogContent>
            <p style={{marginBottom: 10}}>{t("device-message-error-first")}</p>
            <p>
                {t("device-message-error-third")}
                <a href="https://aircamp.us/support/system_manuals/6657/" target="_blank"
                   rel="noreferrer">{t("device-message-error-link")}</a>
                {t("device-message-error-last")}
            </p>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>{t("Close")}</Button>
        </DialogActions>
    </Dialog>;
}

export default DeviceErrorModal;
