import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {getCookieByName} from "../../../utils/CookieUtil";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import type {IRecord, RoomDetailAccount} from "../../../types/types";
import {handleErrorMessage} from "../../../utils/utils";

interface Props {
    open: any
    handleClose: any
    updateRecordList: any
    record?: IRecord
}

export default function ShareRecordDialog (props: Props) {
    const [accountId, setAccountId] = useState<string>("");
    const [accountName, setAccountName] = useState<string>("");
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();
    const [sharedAccount, setSharedAccount] = useState<RoomDetailAccount[]>([]);

    useEffect(() => {
        if (props.record) {
            setSharedAccount(props.record.shared_account);
        }
    }, [props.record]);

    useEffect(() => {
        setAccountId("");
        setAccountName("");
    }, [props.open]);

    const getAccountName = () => {
        setAccountName("");
        axios.get(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/account_name?a=${authToken}&u=${userId}&account_id=${accountId}`)
            .then(res => {
                if (res.data?.data?.account_name) {
                    setAccountName(res.data.data.account_name);
                }
            }).catch(err => {
                if (err.response?.data?.data) {
                    enqueueSnackbar(err.response.data.data, { variant: "error" });
                } else {
                    enqueueSnackbar("Error get account name", { variant: "error" });
                }
        });
    };

    const handleAdd = () => {
        if (!sharedAccount.some(item => item.account_id === accountId)) {
            sharedAccount.push({
                account_id: accountId,
                name: accountName
            });
            setAccountId("");
            setAccountName("");
        }
    };

    const handleRemoveAccount = (accountId: string) => {
        const sharedAccountFilter = sharedAccount.filter(account => account.account_id !== accountId);
        setSharedAccount(sharedAccountFilter);
    };

    const handleUpdate = () => {
        if (props.record) {
            const accountIds = sharedAccount.map(account => account.account_id);
            const params = {
                record_id: props.record.id,
                shared_account_ids: accountIds
            };
            axios.post(`${process.env.REACT_APP_SESSION_API || ""}/record_share?a=${authToken}&u=${userId}`, params)
                .then(res => {
                    props.updateRecordList();
                    props.handleClose();
                }).catch(err => {
                    handleErrorMessage(err, enqueueSnackbar);
            });
        }
    };

     return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog" fullWidth>
        <DialogTitle>{t("share record")}
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30, color: "#000"}}/>
            </Icon>
        </DialogTitle>
         <DialogContent>
             <TextField
                 autoFocus
                 margin="dense"
                 id="name"
                 label={t("Please enter your ID")}
                 type="text"
                 fullWidth
                 variant="standard"
                 value={accountId}
                 onChange={(event) => {
                     setAccountId(event.target.value);
                     setAccountName("");
                 }}
                 InputProps={{endAdornment: <Button onClick={getAccountName}>{t("Search")}</Button>}}
             />
             <Typography className={`account-name ${accountName ? "" : "visibility-hidden"}`} variant="subtitle1"
                         style={{marginTop: 20}}>
                 <span style={{marginRight: 20}}>{accountName}</span>
                 <Button onClick={handleAdd} disabled={!accountName} variant="contained">{t("Add")}</Button>
             </Typography>
             <Typography variant="subtitle1">
                 {t("shared account")}
             </Typography>
             <div className="manager-room-list">
                 {
                     sharedAccount.map((manager, index) => {
                         return <span key={index} className="manager-room">{manager.name}
                             <CloseIcon className="remove-manager" onClick={() => {
                                 handleRemoveAccount(manager.account_id);
                             }}></CloseIcon>
                            </span>;
                     })
                 }
             </div>
         </DialogContent>
         <DialogActions>
             <Button onClick={props.handleClose}>{t("cancel")}</Button>
             <Button type="submit" onClick={handleUpdate}>
                 {t("share")}
             </Button>
         </DialogActions>
     </Dialog>;
}
