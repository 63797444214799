import * as React from "react";
import {useEffect, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {useSelector} from "react-redux";
import type {RootState} from "../../../store";
import {type RoomDetailAccount} from "../../../types/types";
import axios from "axios";
import {getCookieByName} from "../../../utils/CookieUtil";
import {useSnackbar} from "notistack";
import {TextFieldManager} from "../../styled/TextFieldManager";
import {useTranslation} from "react-i18next";
import {MAX_MANAGERS_ROOM} from "../../../constants/constant";
import {stringFormat} from "../../../utils/utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags () {
    const {remoteConnection, roomDetail, channelId} = useSelector((state: RootState) => state.sora);
    const [value, setValue] = useState<RoomDetailAccount[]>([]);
    const [options, setOptions] = useState<RoomDetailAccount[]>([]);
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        const options: RoomDetailAccount[] = [];
        remoteConnection.forEach(connect => {
            if (connect.userId && connect.userId !== roomDetail?.owner.account_id && !options.some(option => option.account_id === connect.userId)) {
                const option: RoomDetailAccount = {
                    account_id: connect.userId,
                    name: connect.realName
                };
                options.push(option);
            }
        });

        roomDetail?.managers.forEach(manager => {
            if (!options.some(option => option.account_id === manager.account_id)) {
                const option: RoomDetailAccount = {
                    account_id: manager.account_id,
                    name: manager.name
                };
                options.push(option);
            }
        });
        const newValue: RoomDetailAccount[] = [];
        options.forEach(option => {
            if (roomDetail?.managers.some(manager => manager.account_id === option.account_id)) {
                newValue.push(option);
            }
        });

        setOptions(options);
        setValue(newValue);
    }, [remoteConnection, roomDetail?.managers]);

    const handleUpdateManager = (newValue: RoomDetailAccount[]) => {
        if (roomDetail) {
            const managers = newValue.map(item => item.account_id);
            if (managers.length > MAX_MANAGERS_ROOM) {
                enqueueSnackbar(stringFormat(t("Up to people with the same authority as the owner can register"), MAX_MANAGERS_ROOM), { variant: "error", autoHideDuration: 5000});
                return;
            }
            const params = {
                channel_id: channelId,
                name: roomDetail.name,
                owner_id: roomDetail.owner.account_id,
                allow_user_access: roomDetail.allow_user_access,
                allow_guest_access: roomDetail.allow_guest_access,
                managers,
                main_channel_id: roomDetail.main_channel_id,
                allow_user_pin: roomDetail.allow_user_pin,
                ai_prompt_id: roomDetail.ai_prompt_id
            };
            axios.put(`${process.env.REACT_APP_SESSION_API || ""}/session_room?a=${authToken}&u=${userId}`, params).then(response => {
                const connection = remoteConnection.find(connection => connection.isMine);
                if (connection) {
                    const data = {
                        channel_id: channelId,
                        data: {
                            type: "update_room_details",
                            owners: [roomDetail.owner.account_id, ...managers],
                            connectionId: connection.connectionId
                        }
                    };
                    axios.post(`${process.env.REACT_APP_WEBRTC_API || ""}`, data, {
                        headers: {
                            "x-sora-target": "Sora_20160711.PushChannel"
                        }
                    }).then(null).catch(error => {
                        console.log(error);
                    });
                }
            }).catch(error => {
                enqueueSnackbar("Error update room", { variant: "error", autoHideDuration: 5000});
                console.log(error);
            });
        }
    };

    const handleOnChange = (newValue: RoomDetailAccount[]) => {
        handleUpdateManager(newValue);
    };

    return (
        <div className="checkboxes_tags">
            <Autocomplete
                multiple
                options={options}
                disableCloseOnSelect
                getOptionLabel={(option) => `${option.account_id} - ${option.name}`}
                value={value}
                onChange={(event, newValue) => {
                    handleOnChange(newValue);
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.account_id} - {option.name}
                    </li>
                )}
                renderInput={(params) => (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    <TextFieldManager {...params} variant="standard" placeholder="Select"/>
                )}
            />
        </div>
    );
}
