import "./setupAdministrator.css";
import Layout from "../layout";
import {ButtonAddRoom} from "../../../components/styled/Button";
import {AcAdd} from "../../../components/icon/AcAdd";
import {
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {StyledTableCell} from "../../../components/styled/Table";
import React, {useEffect, useState} from "react";
import {getCookieByName} from "../../../utils/CookieUtil";
import {useTranslation} from "react-i18next";
import type {RoomAdministrator} from "../../../types/types";
import axios from "axios";
import {useSnackbar} from "notistack";
import moment from "moment/moment";
import RoomAdministratorModal from "../../../components/modals/roomAdministrator/roomAdministratorModal";
import {confirmAlert} from "react-confirm-alert";
import {handleErrorMessage} from "../../../utils/utils";
import {useSelector} from "react-redux";
import type {RootState} from "../../../store";

export default function SetupAdministrator () {
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();
    const [roomAdministrators, setRoomAdministrators] = useState<RoomAdministrator[]>([]);
    const [isOpenRoomAdministratorModal, setOpenRoomAdministratorModal] = useState<boolean>(false);
    const { sectionList, selectSectionId } = useSelector((state: RootState) => state.sora);

    useEffect(() => {
        let timeout: any;
        setRoomAdministrators([]);
        if (sectionList) {
            timeout = setTimeout(() => {
                updateRoomAdministrator();
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [sectionList, selectSectionId]);

    const updateRoomAdministrator = () => {
        getRoomAdministrator().then(roomAdministrators => {
            setRoomAdministrators(roomAdministrators);
        }).catch((err) => {
            console.log(err);
            enqueueSnackbar(t("Error call list room administrator"), {variant: "error"});
        });
    };

    const getRoomAdministrator = async () => {
        const roomAdministrators = [] as RoomAdministrator[];
        const params = new URLSearchParams();
        params.set("u", userId);
        params.set("a", authToken);
        if (selectSectionId) {
            params.set("bbt_section_id", selectSectionId);
        }
        const response = await axios.get(`${process.env.REACT_APP_SESSION_API || ""}/room_administrator?${params.toString()}`);
        response.data.room_administrators.forEach((item: RoomAdministrator) => {
            roomAdministrators.push(item);
        });
        return roomAdministrators;
    };

    const handleCloseRoomAdministratorModal = () => {
        setOpenRoomAdministratorModal(false);
        updateRoomAdministrator();
    };

    const handleDeleteRecordRoomAdministrator = (id: number) => {
        axios.delete(`${process.env.REACT_APP_SESSION_API || ""}/room_administrator?u=${userId}&a=${authToken}&id=${id}`).then(() => {
            updateRoomAdministrator();
        }).catch((err) => {
            handleErrorMessage(err, enqueueSnackbar);
        });
    };

    const confirmDeleteRoomAdministrator = (id: number) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Dialog open={true} className="customized-dialog" fullWidth maxWidth="xs">
                        <DialogTitle>{t("confirmation")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t("Do you want to delete it?")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { onClose(); }}>{t("cancel")}</Button>
                            <Button onClick={() => { handleDeleteRecordRoomAdministrator(id); onClose(); }}>{t("delete")}</Button>
                        </DialogActions>
                    </Dialog>
                );
            }
        });
    };

    return <Layout>
        <div className="create-room">
            <ButtonAddRoom startIcon={<AcAdd width="14px" color="#ffffff" />}
                           onClick={() => { setOpenRoomAdministratorModal(true); }}>
                {t("Add")}
            </ButtonAddRoom>
        </div>

        <div style={{minWidth: "768px"}}>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell>{t("Name")}</StyledTableCell>
                            <StyledTableCell>{t("Department")}</StyledTableCell>
                            <StyledTableCell>{t("Registered Date")}</StyledTableCell>
                            <StyledTableCell>{t("Operation")}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {roomAdministrators.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{"&:last-child th": {border: 0}}}
                            >
                                <StyledTableCell>{row.account_id}</StyledTableCell>
                                <StyledTableCell>{row.account_name}</StyledTableCell>
                                <StyledTableCell>{row.bbt_section_name}</StyledTableCell>
                                <StyledTableCell>{moment(row.created_at).format("YYYY/MM/DD HH:mm")}</StyledTableCell>
                                <StyledTableCell>
                                    <IconButton color="error" onClick={() => { confirmDeleteRoomAdministrator(row.id); }}>
                                        <img className="ac-icon-record" src="/img/ac-garbagecan-outline.svg" alt="article"/>
                                    </IconButton>
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        <RoomAdministratorModal
            open={isOpenRoomAdministratorModal}
            handleClose={handleCloseRoomAdministratorModal}
            roomAdministrators={roomAdministrators} />
    </Layout>;
}
