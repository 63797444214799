import React from "react";

interface AcPhoneProp {
    width?: string
    height?: string
    color?: string
}

export function AcPenOutline (props: AcPhoneProp) {
    return <svg width={props.width} height={props.height}
                version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
        <g>
            <path id="Shape_Copy-2_3_" fill={props.color}
                  d="M19.6,8.9l3.3,3.3L5.6,29.6H2.3v-3.4L19.6,8.9 M19.6,5.6L0,25.3v6.6h6.6l19.6-19.7 L19.6,5.6L19.6,5.6z"/>
            <g id="Shape_Copy-2_00000065777604746356198690000006645020715250234527_">
                <path fill={props.color}
                      d="M28.3,10.4L28.3,10.4l-6.8-6.6l3.2-3.2c0.7-0.7,1.8-0.7,2.5,0l0,0l4.3,3.9c0.7,0.7,0.7,1.8,0,2.5l0,0 L28.3,10.4L28.3,10.4z"/>
            </g>
        </g>
    </svg>;
}
