import React from "react";

interface Props {
    width?: string
    height?: string
    color?: string
}

export function AcMember (props: Props) {
    return <svg width={props.width} height={props.height}
                version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
                <path fill={props.color} d="M16,0c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S20.4,0,16,0L16,0z M16,18.7c-10.7,0-13.3,8-13.3,10.7 S5.3,32,5.3,32h21.3c0,0,2.7,0,2.7-2.7S26.7,18.7,16,18.7L16,18.7z"/>
        </svg>;
}
