import "./sidebarRight.css";
import React, {useEffect, useRef} from "react";
import Icon from "../icon/icon";
import {useTranslation} from "react-i18next";
import {PopoverSubtitleSetting} from "../subTitle/popoverSubtitleSetting";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import {setWidthSidebar} from "../../services/sora/soraSlice";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import {IconButton, useTheme} from "@mui/material";
import {AcDownload} from "../icon/AcDownload";

interface Props {
    title: string
    handleCloseClick: any
    children: React.ReactNode
    onclickDownload?: any
    hiddenDownload?: any
    isShowSubtitle?: boolean
    isOpen: boolean
}

export default function SidebarRight (props: Props) {
    const { t } = useTranslation();
    const [anchorShowSubtitleTranslation, setAnchorShowSubtitleTranslation] = React.useState<HTMLElement | null>(null);
    const openShowSubtitleTranslation = Boolean(anchorShowSubtitleTranslation);
    const dispatch = useDispatch<AppDispatch>();
    const {widthSidebar, channelId} = useSelector((state: RootState) => state.sora);
    const isResizingRef = useRef<boolean>(false);
    const widthRef = useRef<number>(250);
    const subtitleSettingRef = useRef<HTMLAnchorElement | null>(null);
    const theme = useTheme();

    const handleClickShowSubtitleTranslation = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorShowSubtitleTranslation(event.currentTarget);
    };

    const handleCloseShowSubtitleTranslation = () => {
        setAnchorShowSubtitleTranslation(null);
    };

    // Bắt đầu kéo
    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();
        isResizingRef.current = true;
    };

    const handleMouseMove = (e: MouseEvent): void => {
        e.preventDefault();
        if (isResizingRef.current) {
            let newWidth = window.innerWidth - e.clientX;
            if (newWidth < 350) {
                newWidth = 350;
            }
            if (newWidth > 700) {
                newWidth = 700;
            }
            widthRef.current = newWidth;
            dispatch(setWidthSidebar(newWidth));
        }
    };

    // Kết thúc kéo
    const handleMouseUp = () => {
        isResizingRef.current = false;
    };

    // Thêm sự kiện cho toàn bộ document khi kéo
    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);

        // Cleanup khi component bị unmount
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, []);

    // Open settings when pressing to open subtitles
    // useEffect(() => {
    //     if (isOpenSubtitleSetting && subtitleSettingRef.current) {
    //         setAnchorShowSubtitleTranslation(subtitleSettingRef.current);
    //         dispatch(setOpenSubtitleSetting(false));
    //     }
    // }, [isOpenSubtitleSetting]);

    return <div className={`sidebar-right ${props.isOpen ? "is-open" : ""}`} style={{ width: widthSidebar, right: `-${widthSidebar}px` }}>
        <div className="sidebar-right-head">
            <div className="sidebar-right-container-title">
                <span className="sidebar-right-title">{props.title}</span>
                {
                    !props.hiddenDownload &&
                    <Icon handleOnclick={props.onclickDownload} titleTooltip={t("Download Current Participant List")}>
                        <AcDownload width="20px" color={theme.palette.text.secondary} />
                    </Icon>
                }
            </div>
            <div>
                {
                    props.isShowSubtitle &&
                    <a ref={subtitleSettingRef} className="show-subtitle-translation" onClick={handleClickShowSubtitleTranslation}>
                        <SettingsIcon />
                    </a>
                }
                <IconButton
                    className="close-icon"
                    aria-label="close"
                    onClick={props.handleCloseClick}
                    sx={(theme) => ({
                        color: theme.palette.text.secondary
                    })}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        </div>
        <div className="sidebar-right-body">
            {props.children}
        </div>
        <div className="sidebar-handle-width" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}/>
        <PopoverSubtitleSetting anchorShowSubtitleTranslation={anchorShowSubtitleTranslation}
                                handleCloseShowSubtitleTranslation={handleCloseShowSubtitleTranslation}
                                openShowSubtitleTranslation={openShowSubtitleTranslation}
                                channelId={channelId}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }} />
    </div>;
}
