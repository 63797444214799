import Layout from "../layout";
import {Button, Table, TableBody, TableContainer, TableFooter, TableHead, TableRow, TextField} from "@mui/material";
import {StyledTableCell} from "../../../components/styled/Table";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getCookieByName} from "../../../utils/CookieUtil";
import {DatePicker} from "@mui/x-date-pickers";
import "./totaling.css";
import moment, {type Moment} from "moment";
import axios from "axios";
import {handleErrorMessage, secondsToHms, secondsToHmsJa} from "../../../utils/utils";
import {useSnackbar} from "notistack";
import Loading from "../../../components/loading/Loading";
import i18next from "i18next";
import {useSelector} from "react-redux";
import type {RootState} from "../../../store";
import {INPUT_FORMAT_DATE} from "../../../constants/constant";

interface RoomStatistics {
    channel_id: string
    channel_name: string
    session_id: string
    total_usage_time: number | null
    number_characters_translated: number | null
    record_storage: number | null
    create_date: string
}

interface RoomStatisticsResponse {
    room_statistics: RoomStatistics[]
    total_usage_time: number
    total_number_characters_translated: number
    total_record_storage: number
}

export default function Totaling () {
    const { t } = useTranslation();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const [startDate, setStartDate] = React.useState<Moment | null>(moment().subtract(30, "days"));
    const [endDate, setEndDate] = React.useState<Moment | null>(moment());
    const {enqueueSnackbar} = useSnackbar();
    const [roomStatisticsResponse, setRoomStatisticsResponse] = useState<RoomStatisticsResponse>();
    const [loading, setLoading] = useState(false);
    const currentLanguage = i18next.language;
    const { selectSectionId, sectionList } = useSelector((state: RootState) => state.sora);

    useEffect(() => {
        let timeout: any;
        if (sectionList) {
            timeout = setTimeout(() => {
                getRoomStatistics();
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [selectSectionId, sectionList]);

    const handleStartDateChange = (value: any) => {
        setStartDate(value);
    };

    const handleEndDateChange = (value: any) => {
        setEndDate(value);
    };

    const idButtonDisabled = (): boolean => {
        return !startDate || !endDate || (startDate > endDate);
    };

    const getRoomStatistics = () => {
        if (!startDate || !endDate) return;
        setRoomStatisticsResponse(undefined);
        setLoading(true);
        const params = new URLSearchParams();
        params.set("u", userId);
        params.set("a", authToken);
        params.set("start_date", startDate.format(INPUT_FORMAT_DATE));
        params.set("end_date", endDate.format(INPUT_FORMAT_DATE));
        if (selectSectionId) {
            params.set("bbt_section_id", selectSectionId);
        }
        axios.get<RoomStatisticsResponse>(`${process.env.REACT_APP_SESSION_API || ""}/room_statistics?${params.toString()}`)
            .then(response => {
                if (response.status === 200) {
                    setRoomStatisticsResponse(response.data);
                }
            })
            .catch(err => {
                handleErrorMessage(err, enqueueSnackbar);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getRecordStorage = (recordStorage: number | null) => {
        return recordStorage ? `${(recordStorage / 1024).toFixed(2)} MB` : "-";
    };

    const getTotalUsageTime = (totalUsageTime: number) => {
        return currentLanguage === "ja" ? secondsToHmsJa(totalUsageTime) : secondsToHms(totalUsageTime);
    };

    return <Layout>
        <div className="totaling-page">
            <div className="date-picker">
                <DatePicker className="start-date-picker" inputFormat={INPUT_FORMAT_DATE} value={startDate}
                            onChange={handleStartDateChange} label={t("Start date")} maxDate={endDate}
                            renderInput={props => <TextField error={true} {...props} />}/>
                <DatePicker className="start-date-picker" inputFormat={INPUT_FORMAT_DATE} value={endDate}
                            onChange={handleEndDateChange} label={t("End date")} maxDate={moment()}
                            renderInput={props => <TextField {...props} />}/>
                <Button onClick={getRoomStatistics} disabled={idButtonDisabled()} variant="contained">{t("Search")}</Button>
            </div>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">{t("room name")}</StyledTableCell>
                            <StyledTableCell align="center">{t("Session ID")}</StyledTableCell>
                            <StyledTableCell align="center">{t("Total usage time")}</StyledTableCell>
                            <StyledTableCell align="center">{t("Translation characters")}</StyledTableCell>
                            <StyledTableCell align="center">{t("Recording storage capacity")}</StyledTableCell>
                            <StyledTableCell align="center">{t("Created Time")}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            roomStatisticsResponse?.room_statistics.map((item, index) => {
                                return <TableRow
                                    key={index}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <StyledTableCell align="left">{item.channel_name}</StyledTableCell>
                                    <StyledTableCell align="center">{item.session_id}</StyledTableCell>
                                    <StyledTableCell align="center">{item.total_usage_time ? getTotalUsageTime(item.total_usage_time) : "-"}</StyledTableCell>
                                    <StyledTableCell align="center">{item.number_characters_translated ? item.number_characters_translated : "-"}</StyledTableCell>
                                    <StyledTableCell align="center">{getRecordStorage(item.record_storage)}</StyledTableCell>
                                    <StyledTableCell align="center">{moment(item.create_date).format("YYYY/MM/DD HH:mm")}</StyledTableCell>
                                </TableRow>;
                            })
                        }
                        {
                            roomStatisticsResponse &&
                            <TableRow className="totaling">
                                <StyledTableCell align="center" colSpan={1}/>
                                <StyledTableCell align="center" colSpan={1}>{t("Total")}</StyledTableCell>
                                <StyledTableCell align="center">{getTotalUsageTime(roomStatisticsResponse.total_usage_time)}</StyledTableCell>
                                <StyledTableCell align="center">{roomStatisticsResponse.total_number_characters_translated}</StyledTableCell>
                                <StyledTableCell align="center">{getRecordStorage(roomStatisticsResponse.total_record_storage)}</StyledTableCell>
                                <StyledTableCell />
                            </TableRow>
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <td colSpan={9} style={{ textAlign: "center" }}>
                                {
                                    loading && <Loading width="50px" height="50px"/>
                                }
                            </td>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    </Layout>;
}
