import React, {useEffect} from "react";
import "./App.css";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Meeting from "./pages/meeting/meeting";
import {useSelector} from "react-redux";
import {type RootState} from "./store";
import PageNotFound from "./components/error/notFound/pageNotFound";
import {SnackbarProvider} from "notistack";
import {Admin} from "./pages/settings/mychannel";
import {Channel} from "./pages/settings/channel/channel";
import {
    CHANNEL_LIMIT,
    MEETING_TYPE,
    PATH_ACCESS_LOG,
    PATH_ADMIN,
    PATH_ADMIN_CHANNEL,
    PATH_ADMINISTRATOR_SETUP,
    PATH_AI_PROMPT,
    PATH_DATA_AGGREGATION,
    PATH_FORUM,
    PATH_MEETING,
    PATH_RECORD,
    PATH_TOTALING
} from "./constants/constant";
import SnackbarCloseButton from "./components/icon/snackbarCloseButton";
import AuthRoute from "./components/privateRoute/authRoute";
import i18next from "i18next";
import {StyledMaterialDesignContent} from "./components/styled/notistackStyled";
import {ThemeProvider} from "@mui/material/styles";
import {Record} from "./pages/settings/record";
import AccessLog from "./pages/settings/access-log";
import {getRoom} from "./services/sora/soraApi";
import SetupAdministrator from "./pages/settings/setupAdministrator/setupAdministrator";
import type {ListenLiveClient} from "@deepgram/sdk";
import Appsync from "./pages/appsync/appsync";
import AiPrompt from "./pages/settings/ai-prompt/aiPrompt";
import Totaling from "./pages/settings/totaling/totaling";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import DataAggregationPage from "./pages/settings/data-aggregation/dataAggregation";
import {getTheme} from "./services/theme/themeSlice";
import {useTheme} from "@mui/material";

const router = createBrowserRouter([
    {
        path: "/",
        element: <PageNotFound/>
    },
    {
        path: PATH_MEETING,
        element: <Meeting/>,
        loader: ({ params }) => {
            if (params.channelId && !CHANNEL_LIMIT.includes(params.channelId)) {
                throw Error("Channel limit");
            }
            return null;
        },
        errorElement: <PageNotFound/>
    },
    {
        path: PATH_FORUM,
        element: <Meeting/>,
        loader: async ({ params}) => {
            if (MEETING_TYPE === params.type && params.id) {
                try {
                    return await getRoom(params.id);
                } catch (err) {
                    throw new Error("Invalid parameters");
                }
            }
        },
        errorElement: <PageNotFound/>
    },
    {
        path: PATH_ADMIN,
        element: <AuthRoute component={Admin}/>
    },
    {
        path: PATH_ADMIN_CHANNEL,
        element: <AuthRoute component={Channel}/>
    },
    {
        path: PATH_RECORD,
        element: <AuthRoute component={Record}/>
    },
    {
        path: PATH_ACCESS_LOG,
        element: <AuthRoute component={AccessLog}/>
    },
    {
        path: PATH_ADMINISTRATOR_SETUP,
        element: <AuthRoute component={SetupAdministrator} checkAdmin={true}/>
    },
    {
        path: "/appsync",
        element: <Appsync/>
    },
    {
        path: PATH_AI_PROMPT,
        element: <AuthRoute component={AiPrompt}/>
    },
    {
        path: PATH_TOTALING,
        element: <AuthRoute component={Totaling}/>
    },
    {
        path: PATH_DATA_AGGREGATION,
        element: <AuthRoute component={DataAggregationPage}/>
    }
]);

declare global {
    interface Window {
        webkitAudioContext: typeof AudioContext
        nameFakeVideoTrack: string
        redirectParam: boolean
        socketDeepGram: ListenLiveClient
        microphoneDeepGram: MediaRecorder
        languageAppsync: string
        isTranslateTranslatedSentences: boolean
    }
}

const SetCSSVariables = () => {
    const theme = useTheme();

    useEffect(() => {
        document.documentElement.style.setProperty("--mui-color-mode-main", theme.palette.colorMode.main);
        document.documentElement.style.setProperty("--mui-color-mode-one", theme.palette.colorMode.one);
        document.documentElement.style.setProperty("--mui-color-mode-two", theme.palette.colorMode.two);
        document.documentElement.style.setProperty("--mui-color-mode-three", theme.palette.colorMode.three);
        document.documentElement.style.setProperty("--mui-color-mode-four", theme.palette.colorMode.four);
        document.documentElement.style.setProperty("--mui-color-mode-five", theme.palette.colorMode.five);
        document.documentElement.style.setProperty("--mui-error", theme.palette.error.main);
        document.documentElement.style.setProperty("--mui-background-default", theme.palette.background.default);
        document.documentElement.style.setProperty("--mui-text-primary", theme.palette.text.primary);
        document.documentElement.style.setProperty("--mui-text-secondary", theme.palette.text.secondary);
        document.documentElement.style.setProperty("--mui-text-color-share", theme.palette.textColorShare);
        document.documentElement.style.setProperty("--mui-icon-background-color-custom", theme.palette.iconBackgroundColorCustom);
        document.documentElement.style.setProperty("--mui-icon-border-default", theme.palette.border.default);
    }, [theme]);

    return null;
};

function App () {
    const {mode, colorMode} = useSelector((state: RootState) => state.theme);

    const theme = getTheme(mode, colorMode);

    const currentLanguage = i18next.language;
    useEffect(() => {
        let lang = currentLanguage;
        if (currentLanguage !== "ja" && currentLanguage !== "en") {
            lang = "en";
        }
        document.documentElement.setAttribute("lang", lang);
    }, [currentLanguage]);

    return (
        <ThemeProvider theme={theme}>
            <SetCSSVariables />
            <SnackbarProvider action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
                              autoHideDuration={5000}
                              anchorOrigin={{ vertical: "top", horizontal: "right" }}
                              hideIconVariant={true}
                              maxSnack={5}
                              Components={{
                                  error: StyledMaterialDesignContent,
                                  default: StyledMaterialDesignContent,
                                  info: StyledMaterialDesignContent,
                                  success: StyledMaterialDesignContent
                              }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <RouterProvider router={router}/>
                </LocalizationProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}
export default App;
