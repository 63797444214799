import "./chat.css";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {useDispatch, useSelector} from "react-redux";
import {setObjectChat, setShowChat, setTextMessage} from "../../services/sora/soraSlice";
import React, {useEffect, useRef, useState} from "react";
import SidebarRight from "../sidebarRight/sidebarRight";
import {type AppDispatch, type RootState} from "../../store";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {handleImageError, replaceEmoji, replaceUrlToTagA} from "../../utils/utils";
import {AVATAR_DEFAULT} from "../../constants/constant";
import Emoji from "./emoji";

interface ChatProps {
    sendMessageChat?: any
    handleKeyUp?: any
}
export default function Chat (props: ChatProps) {
    const {isShowChat, messageChats, textMessage, remoteConnection, objectChat} = useSelector((state: RootState) => state.sora);
    const dispatch = useDispatch<AppDispatch>();
    const scroll = useRef<HTMLInputElement>(null);
    const [isComposing, setComposing] = useState<boolean>(false);
    const [rowChat, setRowChat] = useState<number>(1);
    const { t } = useTranslation();

    useEffect(() => {
        scroll.current?.scrollTo(0, scroll.current?.scrollHeight);
    }, [messageChats.length]);

    const getTime = (time: number) => {
        return moment(time).format("HH:mm");
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const ctrlPressed = event.ctrlKey || event.keyCode === 17;

        const shiftPressed = event.shiftKey || event.keyCode === 16;

        const metaPressed = event.metaKey || event.keyCode === 91;

        const enterPressed = event.keyCode === 13;

        const textarea = event.currentTarget;

        if ((ctrlPressed || shiftPressed || metaPressed) && enterPressed) {
            event.preventDefault();

            const selectionStart = textarea.selectionStart;
            const selectionEnd = textarea.selectionEnd;

            const newValue = textMessage.slice(0, selectionStart) + "\n" + textMessage.slice(selectionEnd);

            dispatch(setTextMessage(newValue));

            textarea.selectionStart = textarea.selectionEnd + selectionStart + 1;

            if (textareaRef.current) {
                const text = textareaRef.current;
                const lineHeight = getLineHeight(text);
                const linesCount = text.value.split("\n").length;
                text.scrollTop = linesCount * lineHeight;
            };
        } else if (!isComposing && enterPressed) {
            event.preventDefault();
            props.handleKeyUp(event);
        }
    };

    const onKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const rows = textMessage.split("\n");
        if (rows.length < 1) {
            setRowChat(1);
        } else if (rows.length > 5) {
            setRowChat(5);
        } else setRowChat(rows.length);
    };

    const getLineHeight = (element: HTMLTextAreaElement) => {
        const lineHeightString = window.getComputedStyle(element).lineHeight;
        const lineHeightNumber = parseInt(lineHeightString, 10);
        return isNaN(lineHeightNumber) ? 0 : lineHeightNumber;
    };

    const onchangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(setTextMessage(e.target.value));
    };
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const onchangeObjectChat = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        dispatch(setObjectChat(value));
    };

    useEffect(() => {
        if (objectChat !== "all") {
            const connection = remoteConnection.find(connection => connection.connectionId === objectChat);
            if (!connection) {
                dispatch(setObjectChat("all"));
            }
        }
    }, [remoteConnection]);

    const findNameReceiver = (connectionId: string | undefined) => {
        const connection = remoteConnection.find(connection => connection.connectionId === connectionId);
        if (connection?.isMine) {
            return connection.name;
        }
        return connection?.realName;
    };

    return <SidebarRight title={t("message on call")} handleCloseClick={() => {
        dispatch(setShowChat(!isShowChat));
    }} hiddenDownload={true} isOpen={isShowChat}>
        <div className="chat">
            <div className="chat-body" ref={scroll}>
                {
                    messageChats?.map((messageChat, index) =>
                        <div className="chat-item" key={index}>
                            <img className="avatar"
                                 src={messageChat.senderImageUrl ? messageChat.senderImageUrl : AVATAR_DEFAULT} alt="avatar"
                                 onError={handleImageError}/>
                            <div>
                                {
                                    messageChat.receiverId === "all"
                                        ? <span className="chat-name">{messageChat.senderName}</span>
                                        : <span className="chat-name chat-name-object"><>{messageChat.senderName} to {findNameReceiver(messageChat?.receiverId)}</></span>
                                }
                                <span className="chat-at">{getTime(messageChat.time)}</span>
                                <span className="chat-message" dangerouslySetInnerHTML={{__html: replaceEmoji(replaceUrlToTagA(messageChat.content))}}></span>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="container-select-object-chat">
                <span>To</span>
                <select className= "select-object-chat" onChange= {onchangeObjectChat} defaultValue={objectChat}>
                    <option value="all">{t("select all")}</option>
                    {
                        remoteConnection.map(connection => {
                            if (!connection.isMine && !connection.isShareScreen) {
                                return <option key={connection.connectionId} value={connection.connectionId}>{connection.realName}</option>;
                            }
                            return null;
                        })
                    }
                </select>
            </div>
            <div className="chat-footer">
                <div className="input-message">
                <textarea className={rowChat === 5 ? "" : "scroll-text" } ref={textareaRef} onKeyDown={onKeyDown} onKeyUp={onKeyUp} rows={rowChat} value={textMessage}
                          onChange={onchangeMessage}
                          onCompositionStart={() => { setComposing(true); }}
                          onCompositionEnd={() => { setComposing(false); }}
                          placeholder={t("send message")}></textarea>
                    <Emoji />
                    <SendOutlinedIcon className="send-icon" onClick={() => { props.sendMessageChat(); setRowChat(1); }} sx={{fontSize: "16px", color: "#17AE8C"}}/>
                </div>
            </div>
        </div>
    </SidebarRight>;
}
