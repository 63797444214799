import {Dialog, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, type SelectChangeEvent} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {type ChangeEvent, type ReactNode} from "react";
import {type Device, setVideoQuality} from "../../../services/devices/devicesSlice";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../../store";
import {setIsNoiseSuppression} from "../../../services/virtualBackground/virtualBackgroundSlice";
import {
    setLightAdjustment,
    setMirroringWebcam,
    setScreenShareAudio,
    setStrengthLightAdjustment
} from "../../../services/sora/soraSlice";
import {
    IS_NOISE_SUPPRESSION,
    LIGHT_ADJUSTMENT,
    MIRRORING_WEBCAM,
    SCREEN_SHARE_AUDIO,
    STRENGTH_LIGHT_ADJUSTMENT
} from "../../../constants/constant";
import Select from "@mui/material/Select";

interface DeviceModalProps {
    open?: any
    handleClose?: any
    cameras: Device[]
    micros: Device[]
    speakers: Device[]
    handleChangeCamera?: any
    handleChangeMic?: any
    handleChangeSpeaker?: any
}
function DeviceModal (props: DeviceModalProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const {videoQuality, deviceUsing} = useSelector((state: RootState) => state.devices);
    const {isNoiseSuppression} = useSelector((state: RootState) => state.virtualBackground);
    const {isLightAdjustment, strengthLightAdjustment, isMirroringWebcam, isScreenShareAudio} = useSelector((state: RootState) => state.sora);

    const handleChangeVideoQuality = (event: SelectChangeEvent<number>, child: ReactNode) => {
        const value = event.target.value;
        dispatch(setVideoQuality(Number.parseInt(value.toString())));
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        dispatch(setIsNoiseSuppression(checked));
        localStorage.setItem(IS_NOISE_SUPPRESSION, checked.toString());
    };

    const handleCheckboxLightAdjustmentChange = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        dispatch(setLightAdjustment(checked));
        localStorage.setItem(LIGHT_ADJUSTMENT, checked.toString());
    };

    const handleMirroringWebcamCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        dispatch(setMirroringWebcam(checked));
        localStorage.setItem(MIRRORING_WEBCAM, checked.toString());
    };

    const handleChangeScreenShareAudio = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        dispatch(setScreenShareAudio(checked));
        localStorage.setItem(SCREEN_SHARE_AUDIO, checked.toString());
    };

    const handleChangeStrengthLightAdjustment = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        dispatch(setStrengthLightAdjustment(Number.parseFloat(value)));
        localStorage.setItem(STRENGTH_LIGHT_ADJUSTMENT, value);
    };

    return <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullWidth={true}
        maxWidth="sm"
        className="customized-dialog"
    >
        <DialogTitle>
            {t("setting")}
            <IconButton
                aria-label="close"
                onClick={props.handleClose}
                sx={(theme) => ({
                    position: "absolute",
                    right: 0,
                    top: 0,
                    color: theme.palette.text.secondary
                })}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <div className="container-box">
                <div className="content-container">
                    <div className="content-dialog content-first">
                        <label>{t("camera")}</label>
                        <FormControl fullWidth margin="dense">
                            <Select
                                labelId="demo-simple-select-label"
                                value={deviceUsing.camera?.deviceId}
                                onChange={props.handleChangeCamera}
                            >
                                {
                                    props.cameras.map((camera, index) => {
                                        return <MenuItem value={camera.deviceId} key={index}>{camera.label}</MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="content-dialog content-second">
                        <label>{t("microphone")}</label>
                        <FormControl fullWidth margin="dense">
                            <Select
                                labelId="demo-simple-select-label"
                                value={deviceUsing.micro?.deviceId}
                                onChange={props.handleChangeMic}
                            >
                                {
                                    props.micros.map((micro, index) => {
                                        return <MenuItem value={micro.deviceId} key={index}>{micro.label}</MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="content-dialog content-third">
                        <label>{t("speaker")}</label>
                        <FormControl fullWidth margin="dense">
                            <Select
                                labelId="demo-simple-select-label"
                                value={deviceUsing.speaker?.deviceId}
                                onChange={props.handleChangeSpeaker}
                            >
                                {
                                    props.speakers.map((speaker, index) => {
                                        return <MenuItem value={speaker.deviceId} key={index}>{speaker.label}</MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="content-dialog content-third">
                        <label>{t("video quality")}</label>
                        <FormControl fullWidth margin="dense">
                            <Select
                                labelId="demo-simple-select-label"
                                value={videoQuality}
                                onChange={handleChangeVideoQuality}
                            >
                                <MenuItem value="1080">最高 (1080p)</MenuItem>
                                <MenuItem value="720">高 (720p)</MenuItem>
                                <MenuItem value="360">中 (360p)</MenuItem>
                                <MenuItem value="240">低 (240p)</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="content-dialog media-processors">
                        <div className="media-processor">
                            <span className="media-processor-name">{t("Noise Suppression")}</span>
                            <label className="toggle-switch">
                                <input type="checkbox" checked={isNoiseSuppression} onChange={handleCheckboxChange}/>
                                <span className="slider"></span>
                            </label>
                        </div>
                        <div className="media-processor">
                            <span className="media-processor-name">{t("Mirroring webcam")}</span>
                            <label className="toggle-switch">
                                <input type="checkbox" checked={isMirroringWebcam}
                                       onChange={handleMirroringWebcamCheckboxChange}/>
                                <span className="slider"></span>
                            </label>
                        </div>
                        <div className="media-processor">
                            <span className="media-processor-name">{t("Brightness adjustment")}</span>
                            <div className="beauty-effects">
                                <label className="toggle-switch">
                                    <input type="checkbox" checked={isLightAdjustment}
                                           onChange={handleCheckboxLightAdjustmentChange}/>
                                    <span className="slider"></span>
                                </label>
                                <input id="strength" type="range" min="0.0" max="1.0" value={strengthLightAdjustment}
                                       step="0.05" onChange={handleChangeStrengthLightAdjustment}/>
                            </div>
                        </div>
                    </div>
                    <div className="content-dialog content-third">
                        <span>{t("Share computer audio")}</span>
                        <label className="toggle-switch">
                            <input type="checkbox" checked={isScreenShareAudio}
                                   onChange={handleChangeScreenShareAudio}/>
                            <span className="slider"></span>
                        </label>
                    </div>
                </div>
            </div>
        </DialogContent>
    </Dialog>;
}

export default DeviceModal;
