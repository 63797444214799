import React from "react";

interface Props {
    width?: string
    height?: string
    color?: string
}

export default function AcClockOutline (props: Props) {
    return <svg width={props.width} height={props.height}
                version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
        <g>
            <g>
                <path fill={props.color}
                      d="M16,2.3c7.6,0,13.7,6.1,13.7,13.7S23.6,29.7,16,29.7S2.3,23.6,2.3,16S8.4,2.3,16,2.3 M16,0C7.2,0,0,7.2,0,16 s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0L16,0z"/>
            </g>
            <path fill={props.color}
                  d="M23.7,16.1h-6.1V7.3c0-0.8-0.5-1.3-1.3-1.3h-0.5c-0.8,0-1.3,0.5-1.3,1.3v10.1V18c0,0.8,0.5,1.3,1.3,1.3h0.5 h7.5c0.8,0,1.3-0.5,1.3-1.3v-0.5C25.1,16.7,24.5,16.1,23.7,16.1z"/>
        </g>
    </svg>;
}
