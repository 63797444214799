import React from "react";

interface Props {
    width?: string
    height?: string
    color?: string
}

export default function AcParticipantsOutline01 (props: Props) {
    return <svg width={props.width} height={props.height}
                xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32">
        <defs>
        </defs>
        <g>
            <g id="_レイヤー_1" data-name="レイヤー_1">
                <path fill={props.color}
                      d="M28,3H4C1.8,3,0,4.8,0,7v18c0,2.2,1.8,4,4,4h24c2.2,0,4-1.8,4-4V7c0-2.2-1.8-4-4-4ZM19.6,5.6v4.4h-7.2v-4.4h7.2ZM2.6,7c0-.8.6-1.4,1.4-1.4h6.1v4.4H2.6v-3ZM29.4,25c0,.8-.6,1.4-1.4,1.4H4c-.8,0-1.4-.6-1.4-1.4v-12.7h26.8v12.7ZM21.9,10v-4.4h6.1c.8,0,1.4.6,1.4,1.4v3h-7.5Z"/>
            </g>
        </g>
    </svg>;
}
