import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    FormLabel, IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {getCookieByName} from "../../../utils/CookieUtil";
import CloseIcon from "@mui/icons-material/Close";
import {ulid} from "ulid";
import {useTranslation} from "react-i18next";
import {clipboardLink, getUrlMeeting, handleErrorMessage, stringFormat} from "../../../utils/utils";
import {MAX_MANAGERS_ROOM, RECVONLY} from "../../../constants/constant";
import {AcClipboard} from "../../icon/AcClipboard";
import {useSnackbar} from "notistack";
import type {Room, RoomAiPrompts, RoomDetailAccount} from "../../../types/types";
import AddManagersDialog from "./addManagersDialog";
import {type Channel, getRoomAiPrompts} from "../../../services/sora/soraApi";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {useSelector} from "react-redux";
import type {RootState} from "../../../store";

interface CreateRoomModalProps {
    open?: any
    handleClose?: any
    setListOwnersChannelOfUser: any
    isUpdate: boolean
    roomModal: Room
    listChannel?: Channel[]
    channelIdModalFocus?: string
}

function CreateRoomModal (props: CreateRoomModalProps) {
    const { t } = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const [channelId, setChannelId] = React.useState(props.roomModal.channel_id);
    const [nameRoom, setNameRoom] = React.useState(props.roomModal.name);
    const [ownerId, setOwnerId] = React.useState(props.roomModal.owner.account_id);
    const [allowUserAccessValue, setAllowUserAccessValue] = React.useState(props.roomModal.allow_user_access);
    const [allowGuestAccessValue, setAllowGuestAccessValue] = React.useState(props.roomModal.allow_guest_access);
    const [managers, setManagers] = React.useState(props.roomModal.managers);
    const [allowUserPinValue, setAllowUserPinValue] = React.useState<boolean>(props.roomModal.allow_user_pin || false);
    const [aiPromptIdValue, setAiPromptIdValue] = React.useState<string>("");
    const [isShowAddManagersDialog, setShowAddManagersDialog] = useState<boolean>(false);
    const [ownerName, setOwnerName] = React.useState(props.roomModal.owner.name);
    const [roomAiPrompts, setRoomAiPrompts] = useState<RoomAiPrompts[]>();
    const { selectSectionId, sectionList } = useSelector((state: RootState) => state.sora);
    const [selectSectionIdValue, setSelectSectionIdValue] = React.useState<string>("");

    useEffect(() => {
        setChannelId(props.roomModal.channel_id);
        setNameRoom(props.roomModal.name);
        setOwnerId(props.roomModal.owner.account_id);
        setAllowUserAccessValue(props.roomModal.allow_user_access);
        setAllowGuestAccessValue(props.roomModal.allow_guest_access);
        setManagers(props.roomModal.managers);
        setOwnerName(props.roomModal.owner.name);
        setAllowUserPinValue(props.roomModal.allow_user_pin || false);
        if (props.roomModal.ai_prompt_id) {
            setAiPromptIdValue(props.roomModal.ai_prompt_id.toString());
        } else {
            setAiPromptIdValue("");
        }
        setSelectSectionIdValue(props.roomModal.bbt_section_id ? props.roomModal.bbt_section_id.toString() : "");
    }, [props.roomModal]);

    useEffect(() => {
        updateRoomAiPromptsList();
    }, []);

    const submitFormRoom = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!nameRoom) return;
        if (managers.length > MAX_MANAGERS_ROOM) {
            enqueueSnackbar(stringFormat(t("Up to people with the same authority as the owner can register"), MAX_MANAGERS_ROOM), { variant: "error", autoHideDuration: 5000});
            return;
        }
        const button = event.target as HTMLButtonElement;
        button.disabled = true;
        const managersId = managers.map(manager => manager.account_id);
        try {
            if (props.isUpdate) {
                const channel = props.listChannel?.find(item => item.channel_id === channelId);
                if (channel?.listConnections.length === undefined || channel?.listConnections.length === 0) {
                    const params = {
                        channel_id: channelId,
                        name: nameRoom,
                        owner_id: ownerId,
                        allow_user_access: allowUserAccessValue,
                        allow_guest_access: allowGuestAccessValue,
                        managers: managersId,
                        main_channel_id: null,
                        allow_user_pin: allowUserPinValue,
                        ai_prompt_id: aiPromptIdValue || null,
                        bbt_section_id: selectSectionIdValue || null,
                        is_admin_update: true
                    };

                    if (authToken && userId) {
                        await axios.put(`${process.env.REACT_APP_SESSION_API || ""}/session_room?a=${authToken}&u=${userId}`, params);
                        props.handleClose();
                        props.setListOwnersChannelOfUser();
                    }
                }
            } else {
                const params = {
                    channel_id: ulid(),
                    name: nameRoom,
                    allow_user_access: allowUserAccessValue,
                    allow_guest_access: allowGuestAccessValue,
                    managers: managersId,
                    main_channel_id: null,
                    allow_user_pin: allowUserPinValue,
                    ai_prompt_id: aiPromptIdValue || null,
                    bbt_section_id: selectSectionId || null
                };

                if (authToken && userId) {
                    await axios.post(`${process.env.REACT_APP_SESSION_API || ""}/session_room?a=${authToken}&u=${userId}`, params);
                    props.handleClose();
                    props.setListOwnersChannelOfUser();
                }
            }
        } catch (err) {
            handleErrorMessage(err, enqueueSnackbar);
        } finally {
            button.disabled = false;
        }
    };

    const handleAllowUserAccessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllowUserAccessValue(Number.parseInt((event.target as HTMLInputElement).value));
    };

    const handleAllowGuestAccessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllowGuestAccessValue(Number.parseInt((event.target as HTMLInputElement).value));
    };

    const showAddManagersDialog = () => {
        setShowAddManagersDialog(true);
    };
    const handleCloseAddManagersDialog = () => {
        setShowAddManagersDialog(false);
    };

    const addManager = (manager: RoomDetailAccount) => {
        if (manager.account_id !== ownerId && !managers.some(m => m.account_id === manager.account_id)) {
            managers.push(manager);
            handleCloseAddManagersDialog();
        }
    };

    const handleRemoveManager = (accountId: string) => {
        if (!isDisable()) {
            const managersFilter = managers.filter(manager => manager.account_id !== accountId);
            setManagers(managersFilter);
        }
    };

    const isDisable = () => {
        const channel = props.listChannel?.find(item => item.channel_id === channelId);
        return !!channel?.listConnections.length;
    };

    const handleAllowUserPinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllowUserPinValue(event.target.checked);
    };

    const updateRoomAiPromptsList = () => {
        setRoomAiPrompts([]);
        getRoomAiPrompts(userId, authToken).then(list => {
            setRoomAiPrompts(list);
        }).catch(err => {
            handleErrorMessage(err, enqueueSnackbar);
        });
    };

    return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog" fullWidth>
            <DialogTitle>{props.isUpdate ? stringFormat(t("update room"), nameRoom) : t("create room")}
                <IconButton
                    aria-label="close"
                    onClick={props.handleClose}
                    sx={(theme) => ({
                        position: "absolute",
                        right: 0,
                        top: 0,
                        color: theme.palette.text.secondary
                    })}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t("room name")}
                    type="text"
                    fullWidth
                    variant="standard"
                    required={true}
                    value={nameRoom}
                    onChange={(event) => {
                        setNameRoom(event.target.value);
                    }}
                    disabled={isDisable()}
                />
                {
                    props.isUpdate &&
                    <TextField
                        margin="dense"
                        id="owner"
                        label={t("Owner")}
                        type="text"
                        fullWidth
                        variant="standard"
                        required={true}
                        value={ownerName}
                        disabled={isDisable()}
                    />
                }
                <Typography variant="subtitle1" style={{marginTop: 20}}>
                    {t("Acting owner")}
                </Typography>
                <div className="manager-room-list">
                    {
                        managers.map((manager, index) => {
                            return <span key={index} className="manager-room">{manager.name}
                                <CloseIcon className= {`remove-manager  ${isDisable() ? "disable-remove-manage" : ""}`} onClick={() => { handleRemoveManager(manager.account_id); }}></CloseIcon>
                            </span>;
                        })
                    }
                    <Button onClick={showAddManagersDialog} disabled={isDisable()}>{t("Add")}</Button>
                </div>
                <Typography variant="subtitle1" style={{marginTop: 20}}>
                    {t("Users who can enter the room")}
                </Typography>
                <FormGroup style={{marginTop: 10}}>
                    <FormLabel>{t("AirCampus User")}</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        style={{marginLeft: 20}}
                        value={allowUserAccessValue}
                        onChange={handleAllowUserAccessChange}
                    >
                        <FormControlLabel value={2} control={<Radio />} label={t("You can enter if the owner approves")} disabled={isDisable()}/>
                        <FormControlLabel value={3} control={<Radio />} label={t("You can enter the room if the AirCampus user who is in the room approves it")} disabled={isDisable()}/>
                        <FormControlLabel value={1} control={<Radio />} label={t("If you know the URL, you can enter the room without approval")} disabled={isDisable()}/>
                    </RadioGroup>
                </FormGroup>
                <FormGroup>
                    <FormLabel>{t("guest")}</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        style={{marginLeft: 20}}
                        value={allowGuestAccessValue}
                        onChange={handleAllowGuestAccessChange}
                    >
                        <FormControlLabel value={2} control={<Radio />} label={t("You can enter if the owner approves")} disabled={isDisable()}/>
                        <FormControlLabel value={3} control={<Radio />} label={t("You can enter the room if the AirCampus user who is in the room approves it")} disabled={isDisable()}/>
                        <Typography variant="subtitle1">
                            {t("Guests are not allowed to enter without authorization")}
                        </Typography>
                    </RadioGroup>
                </FormGroup>
                {
                    (props.isUpdate && channelId) &&
                    <div className="url-recv-only">
                        <Typography variant="subtitle1" style={{marginTop: 20}}>
                            {t("Room URL")}
                        </Typography>
                        <div style={{marginLeft: 20, marginTop: 10}}>
                            <Typography variant="subtitle2">
                                {t("Please share this URL with others when inviting them to a web conference.")}
                            </Typography>
                            <Typography style={{marginTop: 10}} variant="subtitle2">
                                <a href={getUrlMeeting(channelId)}
                                   target="_blank" rel="noreferrer">{getUrlMeeting(channelId)}</a>
                                <span className="clipboard-link"
                                      onClick={() => {
                                          clipboardLink(getUrlMeeting(channelId), enqueueSnackbar, t);
                                      }}>
                                    <AcClipboard height="14px" color="#666666"/>
                                </span>
                            </Typography>
                        </div>
                    </div>
                }
                {
                    (props.isUpdate && channelId) &&
                    <div className="url-recv-only">
                        <Typography variant="subtitle1" style={{marginTop: 20}}>
                            {t("Webinar URL")}
                        </Typography>
                        <div style={{marginLeft: 20, marginTop: 10}}>
                            <Typography variant="subtitle2">
                                {t("This is a URL that allows you to participate only by viewing. People who enter the room from this URL can join the room as viewing-only guests.")}
                            </Typography>
                            <Typography style={{marginTop: 10}} variant="subtitle2">
                                <a href={getUrlMeeting(channelId, RECVONLY)}
                                   target="_blank" rel="noreferrer">{getUrlMeeting(channelId, RECVONLY)}</a>
                                <span className="clipboard-link"
                                      onClick={() => {
                                          clipboardLink(getUrlMeeting(channelId, RECVONLY), enqueueSnackbar, t);
                                      }}>
                                    <AcClipboard height="14px" color="#666666"/>
                                </span>
                            </Typography>
                        </div>
                    </div>
                }
                <FormGroup style={{marginTop: 10}}>
                    <FormControlLabel control={<Checkbox checked={allowUserPinValue} onChange={handleAllowUserPinChange} disabled={isDisable()}/>} label={t("Allow user pin")} />
                </FormGroup>
                <FormControl style={{marginTop: 10}} variant="standard" fullWidth={true}>
                    <InputLabel>{t("AI prompt")}</InputLabel>
                    <Select
                        label={t("AI prompt")}
                        value={aiPromptIdValue}
                        onChange={(event) => {
                            setAiPromptIdValue(event.target.value);
                        }}
                        disabled={isDisable()}
                    >
                        <MenuItem value="">&nbsp;</MenuItem>
                        {
                            roomAiPrompts?.map((item, index) => {
                                return <MenuItem key={index} value={item.id}>{item.title}</MenuItem>;
                            })
                        }
                    </Select>
                </FormControl>
                {
                    props.isUpdate &&
                    <FormControl style={{marginTop: 10}} variant="standard" fullWidth={true}>
                        <InputLabel>{t("Department")}</InputLabel>
                        <Select label={t("Department")}
                                value={selectSectionIdValue}
                                onChange={(event) => {
                                    setSelectSectionIdValue(event.target.value);
                                }}>
                            <MenuItem value="">&nbsp;</MenuItem>;
                            {
                                sectionList?.map((item, index) => {
                                    return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                                })
                            }
                        </Select>
                    </FormControl>
                }
                <AddManagersDialog open={isShowAddManagersDialog} handleClose={handleCloseAddManagersDialog} addManager={addManager}></AddManagersDialog>
                {
                    isDisable() &&
                    <div className="message-is-disable-edit">{t("Editing is not allowed")}</div>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>{t("cancel")}</Button>
                <Button type="submit" disabled={isDisable()} onClick={(event) => { submitFormRoom(event).then(null).catch(null); }}>{props.isUpdate ? t("Update") : t("Create")}</Button>
            </DialogActions>
        </Dialog>;
}

export default CreateRoomModal;
