import React from "react";
import "./icon.css";
import {Tooltip, tooltipClasses, type TooltipProps} from "@mui/material";
import styled from "@emotion/styled";

interface IconProps {
    children: React.ReactNode
    handleOnclick?: any
    classCustom?: string
    titleTooltip?: string
    placementTooltip?: | "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top"
}

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: "12px",
        padding: "3px 5px",
        backgroundColor: "rgba(0,0,0,0.6)",
        color: "#FFFFFF",
        borderRadius: "4px",
        "& .MuiTooltip-arrow::before": {
            backgroundColor: "rgba(0,0,0,0.6)"
        }
    }
}));

function Icon (props: IconProps) {
    return (
        <HtmlTooltip title={props.titleTooltip} placement={props.placementTooltip ? props.placementTooltip : "top"} className="test">
            <button className={`button-icon ${props.classCustom ? props.classCustom : ""}`} onClick={props.handleOnclick}>
                {props.children}
            </button>
        </HtmlTooltip>
    );
}
export default Icon;
