import "./aiPromptDialog.css";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputLabel, MenuItem,
    TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import type {RoomAiPrompts} from "../../../types/types";
import {getCookieByName} from "../../../utils/CookieUtil";
import axios from "axios";
import {handleErrorMessage} from "../../../utils/utils";
import {useSelector} from "react-redux";
import type {RootState} from "../../../store";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

interface Props {
    open: boolean
    handleClose: any
    updateRoomAiPromptsList: any
    roomAiPromptsEdit?: RoomAiPrompts
}

export default function AiPromptDialog (props: Props) {
    const { t } = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const { selectSectionId, sectionList } = useSelector((state: RootState) => state.sora);
    const [selectSectionIdValue, setSelectSectionIdValue] = React.useState<string>("");

    useEffect(() => {
        if (props.roomAiPromptsEdit && props.open) {
            setTitle(props.roomAiPromptsEdit.title);
            setContent(props.roomAiPromptsEdit.content);
            setSelectSectionIdValue(props.roomAiPromptsEdit.bbt_section_id ? props.roomAiPromptsEdit.bbt_section_id.toString() : "");
        } else if (props.open) {
            setTitle("");
            setContent("");
        }
    }, [props.open, props.roomAiPromptsEdit]);

    const handleClickOption = (option: string) => {
        setContent(`${content} {${option}}`);
    };

    const handleClickCreate = (event: React.MouseEvent<HTMLButtonElement>) => {
        const button = event.target as HTMLButtonElement;
        button.disabled = true;
        if (props.roomAiPromptsEdit) {
            const params = {
                id: props.roomAiPromptsEdit.id,
                title,
                content,
                bbt_section_id: selectSectionIdValue || null
            };
            axios.put(`${process.env.REACT_APP_SESSION_API || ""}/room_ai_prompt?u=${userId}&a=${authToken}`, params)
                .then(() => {
                    props.updateRoomAiPromptsList();
                    props.handleClose();
                }).catch(err => {
                    handleErrorMessage(err, enqueueSnackbar);
                }).finally(() => {
                    button.disabled = false;
                });
        } else {
            const params = {
                title,
                content,
                bbt_section_id: selectSectionId || null
            };
            axios.post(`${process.env.REACT_APP_SESSION_API || ""}/room_ai_prompt?u=${userId}&a=${authToken}`, params)
                .then(() => {
                    props.updateRoomAiPromptsList();
                    props.handleClose();
                }).catch(err => {
                    handleErrorMessage(err, enqueueSnackbar);
                }).finally(() => {
                    button.disabled = false;
                });
        }
    };

    return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog ai-prompt-dialog" fullWidth>
        <DialogTitle>
            {props.roomAiPromptsEdit ? t("Update") : t("Create")}
            <IconButton
                aria-label="close"
                onClick={props.handleClose}
                sx={(theme) => ({
                    position: "absolute",
                    right: 0,
                    top: 0,
                    color: "#232323"
                })}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label={t("Title")}
                type="text"
                fullWidth
                variant="standard"
                required={true}
                inputProps={{ maxLength: 100 }}
                value={title}
                onChange={(event) => {
                    setTitle(event.target.value);
                }}
                error={!title}
            />
            <div className="options">
                <span className="option" onClick={() => { handleClickOption("channel_name"); }}>{t("Meeting Room Name")}</span>
                <span className="option" onClick={() => { handleClickOption("participant"); }}>{t("Participants")}</span>
                <span className="option" onClick={() => { handleClickOption("total_speaking_time"); }}>{t("Total speaking time")}</span>
                <span className="option" onClick={() => { handleClickOption("reaction_data"); }}>{t("Reaction Data")}</span>
                <span className="option" onClick={() => { handleClickOption("time_attendance"); }}>{t("Attendance Time Data")}</span>
                <span className="option" onClick={() => { handleClickOption("transcriptions_content"); }}>{t("Subtitle")}</span>
                <span className="option" onClick={() => { handleClickOption("room_chat"); }}>{t("chat")}</span>
            </div>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label={t("Content")}
                type="text"
                fullWidth
                variant="outlined"
                multiline={true}
                rows={5}
                required={true}
                inputProps={{ maxLength: 4000 }}
                value={content}
                onChange={(event) => {
                    setContent(event.target.value);
                }}
                error={!content}
            />
            {
                props.roomAiPromptsEdit &&
                <FormControl style={{marginTop: 10}} variant="standard" fullWidth={true}>
                    <InputLabel>{t("Department")}</InputLabel>
                    <Select label={t("Department")}
                            value={selectSectionIdValue}
                            onChange={(event) => {
                                setSelectSectionIdValue(event.target.value);
                            }}>
                        <MenuItem value="">&nbsp;</MenuItem>;
                        {
                            sectionList?.map((item, index) => {
                                return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                            })
                        }
                    </Select>
                </FormControl>
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>{t("cancel")}</Button>
            <Button disabled={!title || !content} onClick={handleClickCreate}>{props.roomAiPromptsEdit ? t("Update") : t("Create")}</Button>
        </DialogActions>
    </Dialog>;
}
