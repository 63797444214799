import {Menu, type MenuProps} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "top",
            horizontal: "right"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left"
        }}
        {...props}
    />
))(({ theme: Theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        minWidth: 180,
        "& .MuiButtonBase-root .MuiSvgIcon-root": {
            marginRight: "20px"
        },
        "& .MuiMenu-list": {
            padding: "15px",
            "& li": {
                fontSize: "16px",
                minHeight: "24px",
                fontFamily: "\"Noto Sans JP\", sans-serif",
                padding: "5px",
                "& svg": {
                    width: "20px",
                    marginRight: "10px",
                    transform: "translateY(0px)"
                }
            }
        }
    }
}));

export default StyledMenu;
