import {createSlice, type PayloadAction} from "@reduxjs/toolkit";
import {type PaletteMode} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {THEME_COLOR_MODE, THEME_MODE} from "../../constants/constant";

export interface ThemeState {
    mode: PaletteMode
    colorMode: ColorMode
}

export enum ThemeEnum {
    light = "light",
    dark = "dark"
}

export enum ColorModeEnum {
    slate_blue = "slate-blue",
    steel_blue = "steel-blue",
    warm_gray = "warm-gray",
    dusty_rose = "dusty-rose",
    blue_gray = "blue-gray"
}

export interface ColorModeInterface {
    main: string
    one: string
    two: string
    three: string
    four: string
    five: string
}

declare module "@mui/material/styles" {
    interface Palette {
        iconBackgroundColorCustom: string
        colorMode: ColorModeInterface
        textColorShare: string
        border: { default: string }
    }
    interface PaletteOptions {
        iconBackgroundColorCustom?: string
        colorMode?: ColorModeInterface
        textColorShare?: string
        border?: { default: string }
    }
}

export type ColorMode = ColorModeEnum.slate_blue | ColorModeEnum.steel_blue | ColorModeEnum.warm_gray | ColorModeEnum.dusty_rose | ColorModeEnum.blue_gray;

const themeMode = localStorage.getItem(THEME_MODE) as PaletteMode || ThemeEnum.light;
const themeColorMode = localStorage.getItem(THEME_COLOR_MODE) as ColorMode || ColorModeEnum.slate_blue;
const initialState: ThemeState = {
    mode: themeMode,
    colorMode: themeColorMode
};

const getColorMode = (colorMode: ColorMode): ColorModeInterface => {
    switch (colorMode) {
        case ColorModeEnum.slate_blue:
            return {
                main: "#3E4696",
                one: "#13163D99",
                two: "#F1F3F4",
                three: "#CCD2E3",
                four: "#13163D99",
                five: "#13163D99"
            };
        case ColorModeEnum.steel_blue:
            return {
                main: "#577C8E",
                one: "#2F4157A6",
                two: "#F4EFEB",
                three: "#C7D9E5",
                four: "#2F4157A6",
                five: "#2F4157A6"
            };
        case ColorModeEnum.warm_gray:
            return {
                main: "#B7A7A8",
                one: "#91766DB3",
                two: "#F6ECE3",
                three: "#DBDBDB",
                four: "#91766DB3",
                five: "#91766DB3"
            };
        case ColorModeEnum.dusty_rose:
            return {
                main: "#B98F99",
                one: "#772E3FA6",
                two: "#F6ECE3",
                three: "#E8DCDC",
                four: "#772E3FA6",
                five: "#772E3FA6"
            };
        case ColorModeEnum.blue_gray:
            return {
                main: "#6E7F86",
                one: "#2F3E53A6",
                two: "#FAFAFA",
                three: "#B7CFCF",
                four: "#2F3E53A6",
                five: "#2F3E53A6"
            };
    }
};

export const getTheme = (mode: PaletteMode, colorMode: ColorMode) => {
    return createTheme({
        typography: {
            fontFamily: "\"Noto Sans JP\", sans-serif",
            fontWeightMedium: "normal"
        },
        palette: {
            mode,
            ...(mode === ThemeEnum.light
                ? {
                    colorMode: getColorMode(colorMode),
                    error: { main: "#F25A5A" },
                    background: { default: "#FFFFFF", paper: "#FFFFFF" },
                    text: { primary: "#232323", secondary: "#666666" },
                    border: { default: "#E3E9EF" },
                    iconBackgroundColorCustom: "rgba(0, 0, 0, 0.04)",
                    secondary: { main: "#3386CC" },
                    textColorShare: "#666666"
                }
                : {
                    colorMode: {
                        main: "#292C4A",
                        one: "#13163D99",
                        two: "#1B1D30",
                        three: "#383C63",
                        four: "#13163D99",
                        five: "#E3E9EF"
                    },
                    error: { main: "#F25A5A" },
                    background: { default: "#1B1D30", paper: "#1B1D30" },
                    text: { primary: "#FFFFFF", secondary: "#CCCCCC" },
                    border: { default: "#FFFFFF" },
                    iconBackgroundColorCustom: "rgba(255, 255, 255, 0.08)",
                    secondary: { main: "#3386CC" },
                    textColorShare: "#FFFFFF"
                })
        }
    });
};

export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setMode: (state, action: PayloadAction<PaletteMode>) => {
            state.mode = action.payload;
            localStorage.setItem(THEME_MODE, action.payload);
        },
        setColorMode: (state, action: PayloadAction<ColorMode>) => {
            state.colorMode = action.payload;
            localStorage.setItem(THEME_COLOR_MODE, action.payload);
        }
    }
});

export const {
    setMode,
    setColorMode
} = themeSlice.actions;

export default themeSlice.reducer;
