import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import type {RootState} from "../../../store";

interface Props {
    open: boolean
    handleClose: any
}

export default function ImageTranslatedDialog (props: Props) {
    const { t } = useTranslation();
    const {imageTranslatedUrl} = useSelector((state: RootState) => state.sora);

    return <Dialog open={props.open} onClose={props.handleClose} maxWidth="xl" fullWidth>
        <DialogTitle>
            {t("Image translated")}
            <IconButton
                aria-label="close"
                onClick={props.handleClose}
                sx={(theme) => ({
                    position: "absolute",
                    right: 0,
                    top: 0,
                    color: theme.palette.text.secondary
                })}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {
                imageTranslatedUrl && <img style={{ width: "100%" }} src={imageTranslatedUrl} alt=""/>
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>{t("Close")}</Button>
        </DialogActions>
    </Dialog>;
}
