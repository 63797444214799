import "./aiPrompt.css";
import Layout from "../layout";
import {ButtonAddRoom} from "../../../components/styled/Button";
import {AcAdd} from "../../../components/icon/AcAdd";
import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {StyledTableCell} from "../../../components/styled/Table";
import {useTranslation} from "react-i18next";
import AiPromptDialog from "../../../components/modals/aiPrompt/aiPromptDialog";
import type {RoomAiPrompts, RoomAiPromptsResponse} from "../../../types/types";
import {useSnackbar} from "notistack";
import {getCookieByName} from "../../../utils/CookieUtil";
import axios from "axios";
import {handleErrorMessage} from "../../../utils/utils";
import moment from "moment";
import {confirmAlert} from "react-confirm-alert";
import {useSelector} from "react-redux";
import type {RootState} from "../../../store";

export default function AiPrompt () {
    const { t } = useTranslation();
    const [isOpenAiPromptDialog, setOpenAiPromptDialog] = useState(false);
    const [roomAiPrompts, setRoomAiPrompts] = useState<RoomAiPrompts[]>();
    const {enqueueSnackbar} = useSnackbar();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const [roomAiPromptsEdit, setRoomAiPromptsEdit] = useState<RoomAiPrompts>();
    const { selectSectionId, sectionList } = useSelector((state: RootState) => state.sora);

    useEffect(() => {
        let timeout: any;
        setRoomAiPrompts([]);
        if (sectionList) {
            timeout = setTimeout(() => {
                updateRoomAiPromptsList();
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [sectionList, selectSectionId]);

    const updateRoomAiPromptsList = () => {
        setRoomAiPrompts([]);
        getRoomAiPrompts().then(list => {
            setRoomAiPrompts(list);
        }).catch(err => {
            handleErrorMessage(err, enqueueSnackbar);
        });
    };

    const getRoomAiPrompts = async () => {
        const params = new URLSearchParams();
        params.set("u", userId);
        params.set("a", authToken);
        if (selectSectionId) {
            params.set("bbt_section_id", selectSectionId);
        }
        const response = await axios.get<RoomAiPromptsResponse>(`${process.env.REACT_APP_SESSION_API || ""}/room_ai_prompt?${params.toString()}`);
        if (response.status !== 200) return [];
        return response.data.room_ai_prompts;
    };

    const handleOpenAiPromptDialog = () => {
        setRoomAiPromptsEdit(undefined);
        setOpenAiPromptDialog(true);
    };

    const handleCloseAiPromptDialog = () => {
        setOpenAiPromptDialog(false);
    };

    const confirmDelete = (id: number) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Dialog open={true} className="customized-dialog">
                        <DialogTitle>{t("confirmation")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t("Do you want to delete it?")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { onClose(); }}>{t("cancel")}</Button>
                            <Button onClick={() => { handleDelete(id); onClose(); }}>{t("delete")}</Button>
                        </DialogActions>
                    </Dialog>
                );
            }
        });
    };

    const handleDelete = (id: number) => {
        axios.delete<RoomAiPromptsResponse>(`${process.env.REACT_APP_SESSION_API || ""}/room_ai_prompt?u=${userId}&a=${authToken}&id=${id}`)
            .then(() => {
                updateRoomAiPromptsList();
            }).catch(err => {
                handleErrorMessage(err, enqueueSnackbar);
            });
    };

    const handleClickEdit = (item: RoomAiPrompts) => {
        setRoomAiPromptsEdit(item);
        setOpenAiPromptDialog(true);
    };

    return <Layout>
        <div className="create-room">
            <ButtonAddRoom onClick={handleOpenAiPromptDialog}
                startIcon={<AcAdd width="14px" color="#ffffff"/>}>
                {t("Create")}
            </ButtonAddRoom>
        </div>
        <div style={{minWidth: "768px"}}>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{t("Title")}</StyledTableCell>
                            <StyledTableCell>{t("Content")}</StyledTableCell>
                            <StyledTableCell>{t("Department")}</StyledTableCell>
                            <StyledTableCell>{t("Created Time")}</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            roomAiPrompts?.map((item, index) => {
                                return <TableRow
                                    key={index}
                                    sx={{"&:last-child th": {border: 0}}}
                                >
                                    <StyledTableCell>{item.title}</StyledTableCell>
                                    <StyledTableCell className="ai-prompts-content">{item.content}</StyledTableCell>
                                    <StyledTableCell>{item.bbt_section_name}</StyledTableCell>
                                    <StyledTableCell>{moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}</StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton color="warning" onClick={() => { handleClickEdit(item); }}>
                                            <img className="ac-icon-record" src="/img/ac-pen-outline.svg"
                                                 alt="article"/>
                                        </IconButton>
                                        <IconButton color="error" onClick={() => { confirmDelete(item.id); }}>
                                            <img className="ac-icon-record" src="/img/ac-garbagecan-outline.svg" alt="article"/>
                                        </IconButton>
                                    </StyledTableCell>
                                </TableRow>;
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        <AiPromptDialog
            open={isOpenAiPromptDialog}
            handleClose={handleCloseAiPromptDialog}
            updateRoomAiPromptsList={updateRoomAiPromptsList}
            roomAiPromptsEdit={roomAiPromptsEdit}/>
    </Layout>;
}
