import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../../store";
import {useTranslation} from "react-i18next";
import {ButtonUpdateRoom} from "../../styled/Button";
import Checkbox from "@mui/material/Checkbox";
import {getCookieByName} from "../../../utils/CookieUtil";
import axios from "axios";
import {handleErrorMessage} from "../../../utils/utils";
import {useSnackbar} from "notistack";
import {getRecordingData} from "../../../services/sora/soraSlice";

interface Props {
    isShow: boolean
    handleClose: () => void
    startRecording: any
}

interface RecordShare {
    userId: string
    name: string
    checked: boolean
}

export default function RecordModal (props: Props) {
    const {remoteConnection, isRecording, recordingData, roomDetail} = useSelector((state: RootState) => state.sora);
    const {t} = useTranslation();
    const [autoGenerateSummary, setAutoGenerateSummary] = React.useState<boolean>(true);
    const [recordShareList, setRecordShareList] = useState<RecordShare[]>([]);
    const [isAllChecked, setAllChecked] = useState(false);
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        setAllChecked(false);
    }, [props.isShow]);

    useEffect(() => {
        if (!props.isShow) return;

        const recordShareList: RecordShare[] = [];
        roomDetail?.managers.forEach(manager => {
            if (manager.account_id !== userId) {
                const checked = recordingData?.share_accounts.some(account => account === manager.account_id) || false;
                const recordShare: RecordShare = {
                    userId: manager.account_id,
                    name: manager.name,
                    checked
                };
                recordShareList.push(recordShare);
            }
        });
        remoteConnection.forEach(connection => {
            if (connection.userId && connection.userId !== userId && !recordShareList.some(item => item.userId === connection.userId)) {
                const checked = recordingData?.share_accounts.some(account => account === connection.userId) || false;
                const recordShare: RecordShare = {
                    userId: connection.userId,
                    name: connection.realName,
                    checked
                };
                recordShareList.push(recordShare);
            }
        });
        setRecordShareList(recordShareList);
    }, [remoteConnection.length, recordingData, props.isShow]);

    useEffect(() => {
        const recordShareListUpdate = recordShareList.map(item => {
            item.checked = isAllChecked;
            return item;
        });
        setRecordShareList(recordShareListUpdate);
    }, [isAllChecked]);

    const handleRecord = () => {
        if (userId) {
            const shareList: string[] = [];
            recordShareList.forEach(item => {
                if (item.checked) {
                    shareList.push(item.userId);
                }
            });
            props.startRecording(userId, autoGenerateSummary, shareList);
            props.handleClose();
        }
    };

    const handleAutoGenerateSummaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAutoGenerateSummary(event.target.checked);
    };

    const handleChangeRecordShareCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const recordShareListUpdate = recordShareList.map(item => {
            if (item.userId === event.target.value) {
                item.checked = event.target.checked;
            }
            return item;
        });
        setRecordShareList(recordShareListUpdate);
    };

    const handleChangeAllCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllChecked(event.target.checked);
    };

    const handleUpdateRecordingSharing = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!recordingData?.id) return;

        const button = event.target as HTMLButtonElement;
        button.disabled = true;
        const shareList: string[] = [];
        recordShareList.forEach(item => {
            if (item.checked) {
                shareList.push(item.userId);
            }
        });
        const params = {
            record_id: recordingData.id,
            shared_account_ids: shareList
        };
        axios.post(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/record_share?a=${authToken}&u=${userId}`, params)
            .then(res => {
                enqueueSnackbar("Success", { variant: "success" });
                props.handleClose();
                dispatch(getRecordingData()).then(null).catch(null);
            }).catch(err => {
                handleErrorMessage(err, enqueueSnackbar);
            }).finally(() => {
                button.disabled = false;
            });
    };

    return <Dialog
        open={props.isShow}
        onClose={props.handleClose}
        fullWidth={true}
        maxWidth="sm"
        className="customized-dialog"
    >
        <DialogTitle>
            {t("Recording sharing settings")}
            <IconButton
                aria-label="close"
                onClick={props.handleClose}
                sx={(theme) => ({
                    position: "absolute",
                    right: 0,
                    top: 0,
                    color: theme.palette.text.secondary
                })}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <div className="container-box">
                <div className="content-container">
                    <div className="content-dialog">
                        <div className="room-detail-item" hidden={recordShareList.length === 0}>
                            <span className="room-detail-item-title">{t("Choose who you want to share your recording with")}</span>
                            <span className="room-detail-item-data">
                                <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={isAllChecked} onChange={handleChangeAllCheckbox}/>}
                                    label={t("All")}/>
                                    {
                                        recordShareList.map((recordShare, index) => {
                                            return <FormControlLabel key={index}
                                                                     control={<Checkbox value={recordShare.userId}
                                                                                        onChange={handleChangeRecordShareCheckbox}
                                                                                        checked={recordShare.checked}/>}
                                                                     label={recordShare.name}/>;
                                        })
                                    }
                            </FormGroup>
                            </span>
                            <span
                                className="room-detail-item-data">{t("The recording data will be saved in the recording folder of the selected user.")}</span>
                        </div>
                        <div className="room-detail-item" hidden={isRecording}>
                            <span className="room-detail-item-data">
                                <FormControlLabel control={<Checkbox checked={autoGenerateSummary}
                                                                     onChange={handleAutoGenerateSummaryChange}/>}
                                                  label={t("Create a summary of the recording and send it via email.")}/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            {
                isRecording
                    ? <ButtonUpdateRoom variant="contained" type="submit" onClick={handleUpdateRecordingSharing}>
                        {t("share")}
                    </ButtonUpdateRoom>
                    : <ButtonUpdateRoom variant="contained" type="submit" onClick={handleRecord}>
                        {t("Start recording")}
                    </ButtonUpdateRoom>
            }
        </DialogActions>
    </Dialog>;
}
