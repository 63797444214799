import React, {useEffect, useRef, useState} from "react";
import Icon, {HtmlTooltip} from "../icon/icon";
import {getBackgroundUser, handleImageError, hasEllipsis} from "../../utils/utils";
import {AVATAR_DEFAULT, NETWORK_STATUS} from "../../constants/constant";
import EditNameModal from "../modals/device/editNameModal";
import {
    Box,
    LinearProgress,
    linearProgressClasses,
    type LinearProgressProps,
    Typography,
    useTheme
} from "@mui/material";
import {type RemoteConnection, setCheckboxOwner} from "../../services/sora/soraSlice";
import styled from "@emotion/styled";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import type {CheckboxOwner} from "../../types/types";
import {useTranslation} from "react-i18next";
import {pushChannel} from "../../services/sora/soraApi";
import {AcPenOutline} from "../icon/AcPenOutline";

interface ParticipantProps {
    name: any
    totalSpeakingTime: number
    index: number
    connectionIdLocal: string
    connectionIdRemote: string
    connection: RemoteConnection
}

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#E3E9EF"
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: "#3386CC"
    }
}));

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    return (
        <Box sx={{ position: "relative" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <CustomLinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ position: "absolute", width: "100%", textAlign: "center", bottom: 6 }}>
                <Typography variant="body2" sx={{ color: "#666666", fontSize: "12px" }}>
                    {`${(Math.round(props.value * 10) / 10).toFixed(1)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

export default function Participant (props: ParticipantProps) {
    const myElementRef = useRef<HTMLDivElement | null>(null);
    const [hasEllipsisResult, setHasEllipsisResult] = useState(false);
    const [isShowModalEditName, setIsShowModalEditName] = useState<boolean>(false);
    const {roomDetail, isMeetingOwner, messageOnOffMicCameraList, isMicroOn, channelId} = useSelector((state: RootState) => state.sora);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const [isHasAudio, setHasAudio] = useState<boolean>(false);
    const theme = useTheme();

    useEffect(() => {
        if (myElementRef.current) {
            const ellipsisResult = hasEllipsis(myElementRef.current);
            setHasEllipsisResult(ellipsisResult);
        }
    }, [props.name]);

    useEffect(() => {
        if (props.connection?.isMine) {
            setHasAudio(isMicroOn);
        }
    }, [isMicroOn]);

    // handle message mic, camera on of off
    useEffect(() => {
        handleMessageOnOffMicCameraList();
    }, [messageOnOffMicCameraList]);

    const handleMessageOnOffMicCameraList = () => {
        const messageOnOffMicCamera = messageOnOffMicCameraList.find(messageOnOffMicCamera => messageOnOffMicCamera.connectionId === props.connection.mediaStreamId);
        if (messageOnOffMicCamera !== undefined) {
            setHasAudio(messageOnOffMicCamera.isMicroOn);
        }
    };

    const handleOpenCheckboxOwnerModal = () => {
        if (roomDetail?.owner.account_id !== props.connection.userId && props.connection.userId && isMeetingOwner) {
            const checkboxOwner: CheckboxOwner = {
                isShow: true,
                checked: props.connection.isMeetingOwner,
                label: props.connection.realName,
                userId: props.connection.userId
            };
            dispatch(setCheckboxOwner(checkboxOwner));
        }
    };

    const handleTurnOffTheMic = () => {
        const data = {
            channel_id: channelId,
            data: {
                type: "turn_off_the_mic",
                connectionId: props.connection.connectionId
            }
        };
        pushChannel(data).then(null).catch(err => {
            console.log(err);
        });
    };

    return <div style={{order: props.totalSpeakingTime - props.connection.speakingTime}} className="participant">
        <img className={`avatar ${getBackgroundUser(props.index)}`}
             src={props.connection.imageUrl ? props.connection.imageUrl : AVATAR_DEFAULT} alt="avatar"
             onError={handleImageError}/>
        <span className="info-network">
            <HtmlTooltip title={props.connection.networkStatus !== null ? t(NETWORK_STATUS.get(props.connection.networkStatus)) : t("loading network")} placement="top">
                <img
                    src={`/img/ac-reception${props.connection.networkStatus !== null ? props.connection.networkStatus : ""}.svg`}
                    alt="network status"/>
            </HtmlTooltip>
        </span>
        <div className="participant-edit-name">
            <HtmlTooltip title={hasEllipsisResult ? props.name : ""} placement="top">
                <span ref={myElementRef} className="participant-name"
                      onClick={handleOpenCheckboxOwnerModal}>
                    {props.name}
                </span>
            </HtmlTooltip>
            {
                props.connectionIdLocal === props.connection.mediaStreamId &&
                <span>
                    <span className="btn-edit-name" onClick={() => {
                        setIsShowModalEditName(true);
                    }}>
                        <AcPenOutline width="14px" color={theme.palette.text.secondary}/>
                    </span>
                    <EditNameModal open={isShowModalEditName} handleClose={() => {
                        setIsShowModalEditName(false);
                    }} connectionIdRemote={props.connectionIdRemote} connection={props.connection}/>
                </span>
            }
        </div>

        {
            props.connection.speakingTime !== 0 &&
            <div className="speaking-time">
                <Box sx={{width: "100%"}}>
                    <LinearProgressWithLabel value={(props.connection.speakingTime / props.totalSpeakingTime) * 100}/>
                </Box>
            </div>
        }
        <div className="participant-mic">
            {
                isHasAudio
                    ? <Icon classCustom={`participant-mic-on ${(props.connection?.isMine || !isMeetingOwner) ? "disable-hover" : ""}`} handleOnclick={(props.connection?.isMine || !isMeetingOwner) ? null : handleTurnOffTheMic}>
                        <img className="participant-mic-on-normal" src="/img/ac-mic-1.svg" alt="mic"/>
                        <img className="participant-mic-on-hover" src="/img/ac-mic-mute-fill.svg" alt="mic"/>
                    </Icon>
                    : <Icon classCustom="participant-mic-off">
                        <img src="/img/ac-mic-mute-fill.svg" alt="mic"/>
                    </Icon>
            }
        </div>
    </div>;
}
