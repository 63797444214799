import "./breakoutRooms.css";
import {
    type RemoteConnection,
    setBreakoutRoom,
    setBreakoutRoomExpirationTime,
    setCountdownValue,
    setJoinBreakoutRoom,
    setRemoteConnectionBreakoutRoomStarted,
    setShowBreakoutRoom
} from "../../services/sora/soraSlice";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import {useTranslation} from "react-i18next";
import {Button, TextField, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {getCookieByName} from "../../utils/CookieUtil";
import {ulid} from "ulid";
import {pushChannel} from "../../services/sora/soraApi";
import BreakoutRoomDialog from "../modals/breakoutRoom/breakoutRoomDialog";
import {timeMsToSeconds} from "../../utils/utils";
import {useSnackbar} from "notistack";
import SidebarRight from "../sidebarRight/sidebarRight";
import SubRoomIcon from "../icon/SubRoomIcon";
import HomeIcon from "../icon/HomeIcon";
import CiTimeIcon from "../icon/CiTime";
import SignInIcon from "../icon/SignInIcon";
import TrashIcon from "../icon/TrashIcon";
import CloseCircleIcon from "../icon/CloseCircle";
import CiAddIcon from "../icon/CiAddIcon";
import RoomsUser from "./roomsUser";

interface Props {
    sendMessageCountdownUpdate: (countdown: number, isClick: boolean, isStopBreakoutRoom?: boolean) => void
}

export default function BreakoutRooms (props: Props) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const {
        channelId,
        breakoutRoom,
        mainRoomName,
        breakoutRooms,
        isMeetingOwner,
        isShowBreakoutRoom,
        remoteConnection,
        isBreakoutRoomStarted,
        remoteConnectionBreakoutRoomStarted,
        breakoutRoomExpirationTime,
        isJoinBreakoutRoom,
        isUpdatingBreakoutRoom
    } = useSelector((state: RootState) => state.sora);
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const [isOpenBreakoutRoomDialog, setOpenBreakoutRoomDialog] = useState<boolean>(false);
    const [breakoutRoomSetting, setBreakoutRoomSetting] = useState<string>("");
    const {enqueueSnackbar} = useSnackbar();
    const [listRemoteBreakoutRoom, setListRemoteBreakoutRoom] = useState<RemoteConnection[]>();
    const [nameRoomSelected, setNameRoomSelected] = useState<string>();
    const theme = useTheme();

    const updateBreakoutRooms = (channelIdBreakoutRoom: string, roomName: string) => {
        // Keep the original list of participants when entering the child room
        if (channelIdBreakoutRoom !== channelId) {
            if (!isBreakoutRoomStarted) {
                dispatch(setRemoteConnectionBreakoutRoomStarted(remoteConnection));
            }
            dispatch(setJoinBreakoutRoom(true));
        } else {
            dispatch(setJoinBreakoutRoom(false));
        }
        dispatch(setBreakoutRoom({
            channelId: channelIdBreakoutRoom,
            roomName
        }));
    };

    const createRoom = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (breakoutRooms.length >= 10) {
            return;
        }

        const button = event.target as HTMLButtonElement;
        button.disabled = true;

        let nameBreakoutRoom = `Group ${breakoutRooms.length + 1}`;
        for (let i = 0; i < breakoutRooms.length; i++) {
            if (breakoutRooms[i].name !== `Group ${i + 1}`) {
                nameBreakoutRoom = `Group ${i + 1}`;
                break;
            }
        }

        const params = {
            channel_id: ulid(),
            name: nameBreakoutRoom,
            room_type: 0,
            allow_user_access: 0,
            allow_guest_access: 0,
            main_channel_id: channelId,
            managers: [],
            allow_user_pin: false
        };

        axios.post(`${process.env.REACT_APP_SESSION_API || ""}/session_room?a=${authToken}&u=${userId}`, params).then(() => {
            pushCreateBreakoutRoom();
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            button.disabled = false;
        });
    };

    const deleteRoom = (channelIdBreakoutRoom: string) => {
        listRemoteBreakoutRoom?.forEach((connection, index) => {
            if (connection.breakoutRoom === channelIdBreakoutRoom) {
                const data = {
                    channel_id: channelId,
                    data: {
                        type: "setting_breakout_room",
                        connectionId: connection.connectionId,
                        breakoutRoom: null
                    }
                };
                pushChannel(data).then(null).catch((error) => {
                    console.log(error);
                });
            }
        });
        axios.delete(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/room/${channelIdBreakoutRoom}?u=${userId}&a=${authToken}`).then(() => {
            pushCreateBreakoutRoom();
        }).catch((err) => {
            console.log(err);
        });
    };

    const deleteParticipant = (connectionId: string | undefined) => {
        const data = {
            channel_id: channelId,
            data: {
                type: "setting_breakout_room",
                connectionId,
                breakoutRoom: undefined
            }
        };
        pushChannel(data).then(null).catch((error) => {
            console.log(error);
        });
    };

    const pushCreateBreakoutRoom = () => {
        const data = {
            channel_id: channelId,
            data: {
                type: "create_breakout_room"
            }
        };
        pushChannel(data).then(null).catch(err => {
            console.log(err);
        });
    };

    const openBreakoutRoomDialog = (channelId: string) => {
        setBreakoutRoomSetting(channelId);
        setOpenBreakoutRoomDialog(true);
    };

    const handleCloseBreakoutRoomDialog = () => {
        setOpenBreakoutRoomDialog(false);
    };

    const startJoinBreakoutRoom = (event: React.MouseEvent<HTMLButtonElement>) => {
        const button = event.target as HTMLButtonElement;
        button.disabled = true;
        setTimeout(() => {
            button.disabled = false;
        }, 5000);

        const expTime = timeMsToSeconds(breakoutRoomExpirationTime);
        if (!expTime) {
            enqueueSnackbar("Invalid expiration time", {variant: "error"});
            return;
        }

        const data = {
            channel_id: channelId,
            data: {
                type: "start_join_breakout_room",
                expirationTime: expTime
            }
        };
        pushChannel(data).then(null).catch(err => {
            console.log(err);
        });
        breakoutRooms.forEach(room => {
            const data = {
                channel_id: room.channel_id,
                data: {
                    type: "start_join_breakout_room",
                    expirationTime: expTime
                }
            };
            pushChannel(data).then(null).catch(err => {
                console.log(err);
            });
        });
        dispatch(setCountdownValue(expTime));
    };

    useEffect(() => {
        if (!isBreakoutRoomStarted && !isJoinBreakoutRoom) {
            const remoteConnectionNotIsShareScreen = remoteConnection.filter(connection => !connection.isShareScreen);
            setListRemoteBreakoutRoom(remoteConnectionNotIsShareScreen);
        }
    }, [remoteConnection]);

    useEffect(() => {
        // Keep the original list of participants when entering the child room
        if (isBreakoutRoomStarted || isJoinBreakoutRoom) {
            setListRemoteBreakoutRoom(remoteConnectionBreakoutRoomStarted.filter(connection => !connection.isShareScreen));
        }
        if (!isBreakoutRoomStarted) {
            if (breakoutRoom?.channelId === channelId) {
                dispatch(setJoinBreakoutRoom(false));
            }
        }
    }, [isBreakoutRoomStarted]);

    const isCheckInMainRoom = () => {
        return breakoutRoom === null || breakoutRoom?.channelId === channelId;
    };

    const discontinueBreakoutRoom = (event: React.MouseEvent<HTMLButtonElement>) => {
        const button = event.target as HTMLButtonElement;
        button.disabled = true;
        setTimeout(() => {
            button.disabled = false;
        }, 5000);

        props.sendMessageCountdownUpdate(-1, false, true);
        const data = {
            channel_id: channelId,
            data: {
                type: "discontinue_breakout_room"
            }
        };
        pushChannel(data).then(null).catch(err => {
            console.log(err);
        });

        breakoutRooms.forEach(room => {
            const data = {
                channel_id: room.channel_id,
                data: {
                    type: "discontinue_breakout_room"
                }
            };
            pushChannel(data).then(null).catch(err => {
                console.log(err);
            });
        });
    };

    const isDisableBreakoutRoomSetting = () => {
        return isBreakoutRoomStarted || isJoinBreakoutRoom;
    };

    const isDisableClickBreakoutRoom = (channelId: string) => {
        return !isBreakoutRoomStarted || breakoutRoom?.channelId === channelId || isUpdatingBreakoutRoom;
    };

    return <div className="breakout-room">
        <SidebarRight title={t("title breakout room")} handleCloseClick={() => {
            dispatch(setShowBreakoutRoom(!isShowBreakoutRoom));
        }} hiddenDownload={true} isOpen={isShowBreakoutRoom}>
            <div className="main-room">
                <div className="title-main-room">
                    <HomeIcon color={theme.palette.text.primary} width="16px" height="16px"/>
                    <span>{t("title breakout main room")}</span>
                </div>
                <div className="main-room-info">
                    <div>
                        <a className={`${isDisableClickBreakoutRoom(channelId) ? "disable-click" : ""}`} style={{ cursor: "pointer" }} onClick={() => { updateBreakoutRooms(channelId, mainRoomName); }}>
                            <SignInIcon color={theme.palette.text.primary} width="13px" height="14px"/>
                            <span className="room-name">{mainRoomName}</span>
                        </a>
                    </div>
                    <div className="main-room-participant">
                        {
                            listRemoteBreakoutRoom?.map((connection, index) => {
                                if (!connection.breakoutRoom) {
                                    return (<div key={index}>
                                        <RoomsUser
                                            key={index}
                                            name={connection.realName}
                                            connection={connection}
                                            index={index} />
                                    </div>);
                                }
                                return null;
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="sub-room">
                <div className="title-sub-room">
                    <SubRoomIcon color="#232323" width="16px" height="16px"/>
                    <span className="title-room">{t("title breakout side room")}</span>
                </div>
                <div className="sub-room-info">
                    {
                        breakoutRooms.map((room, index) => {
                            let isExistParticipant = false;
                            return <div key={index} className="sub-room-index">
                                <div className="container-name-icon">
                                    <div>
                                        {
                                            isMeetingOwner &&
                                            <a className={`${isDisableClickBreakoutRoom(room.channel_id) ? "disable-click" : ""}`} onClick={() => { updateBreakoutRooms(room.channel_id, `${mainRoomName} : ${room.name}`); }}>
                                                <SignInIcon color={theme.palette.text.primary} width="13px" height="14px"/>
                                                <span className="room-name">{room.name}</span>
                                            </a>
                                        }
                                    </div>
                                    <div>
                                        {
                                            isMeetingOwner && isCheckInMainRoom() &&
                                            <a className={`${isDisableBreakoutRoomSetting() ? "breakout-room-started" : ""}`}
                                                      onClick={() => { deleteRoom(room.channel_id); }}>
                                                <TrashIcon color={theme.palette.text.primary} width="12px" height="16px"/>
                                            </a>
                                        }
                                    </div>
                                </div>
                                <div className="sub-room-participant">
                                    {
                                        listRemoteBreakoutRoom?.map((connection, index) => {
                                            if (connection.breakoutRoom === room.channel_id) {
                                                isExistParticipant = true;
                                                return (<div key={index} className="container-sub-participant">
                                                    <RoomsUser
                                                        key={index}
                                                        name={connection.realName}
                                                        connection={connection}
                                                        index={index} />
                                                    <div className={`container-delete-participant ${isDisableBreakoutRoomSetting() ? "breakout-room-started" : ""}`}>
                                                        <a onClick={() => { deleteParticipant(connection.connectionId); }}>
                                                            <CloseCircleIcon color={theme.palette.text.secondary} width="6px" height="6px"/>
                                                        </a>
                                                    </div>
                                                </div>);
                                            }
                                            return null;
                                        })
                                    }
                                    {
                                        !isExistParticipant &&
                                        <div className="not-participant">{t("not-participant")}</div>
                                    }
                                </div>
                                <div className={`assign-members ${isDisableBreakoutRoomSetting() ? "breakout-room-started" : ""}`}>
                                    <CiAddIcon color="#3386CC" width="12px" height="12px"/>
                                    <span className="assign-members-text" onClick={() => { openBreakoutRoomDialog(room.channel_id); setNameRoomSelected(room.name); }}>{t("assign-members")}</span>
                                </div>
                            </div>;
                        })
                    }
                </div>
                {
                    isCheckInMainRoom() && breakoutRooms.length < 10 &&
                    <div className={`add-breakout-room ${isDisableBreakoutRoomSetting() ? "breakout-room-started" : ""}`}>
                        <Button
                            sx={{
                                color: "#3386CC",
                                fontSize: "14px"
                            }}
                            className="button-add-breakout-room" onClick={createRoom}>
                            <CiAddIcon color="#3386CC" width="14px" height="14px"/>
                            <span className="span-add-breakout-room">{t("add breakout room")}</span>
                        </Button>
                    </div>
                }
            </div>
            <div className="setup-breakout-room">
                <div className={`breakout-room-time-setting ${isDisableBreakoutRoomSetting() ? "breakout-room-started" : ""}`}>
                    <div className="time-text">
                        <CiTimeIcon color={theme.palette.text.primary} width="14px" height="14px"/>
                        <span>{t("breakout session time")}</span>
                    </div>
                    <div className="time">
                        <TextField
                            variant="standard"
                            sx={{
                                width: "45px",
                                "& .MuiInputBase-input": {
                                    color: "#3386CC",
                                    fontSize: "14px",
                                    padding: "2px",
                                    marginTop: "8px"
                                }
                            }}
                            placeholder="00:00"
                            value={breakoutRoomExpirationTime}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                dispatch(setBreakoutRoomExpirationTime(event.target.value));
                            }}
                        />
                        <span className="input-adornment-end">{t("input adornment end")}</span>
                    </div>
                </div>
                {
                    isBreakoutRoomStarted
                        ? <Button className="discontinue-breakout-room"
                                  onClick={discontinueBreakoutRoom}>{t("Discontinue a breakout session")}</Button>
                        : <Button className="start-join-breakout-room"
                                  onClick={startJoinBreakoutRoom}>{t("start join breakout room")}</Button>
                }
                <div className="note-breakout-room">{t("note breakout room")}</div>
            </div>
            <BreakoutRoomDialog open={isOpenBreakoutRoomDialog} handleClose={handleCloseBreakoutRoomDialog}
                                breakoutRoomSetting={breakoutRoomSetting} nameRoomSelected={nameRoomSelected}/>
        </SidebarRight>
    </div>;
}
