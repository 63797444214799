import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    FormLabel,
    MenuItem,
    IconButton,
    Radio,
    RadioGroup,
    Typography, useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getRoomType, getUrlMeeting, handleErrorMessage, stringFormat} from "../../../utils/utils";
import {useSelector} from "react-redux";
import type {RootState} from "../../../store";
import "./roomDetailModal.css";
import type {RoomAiPrompts, RoomDetail} from "../../../types/types";
import CheckboxesTags from "./checkboxesManagers";
import {TextFieldManager} from "../../styled/TextFieldManager";
import {MAX_MANAGERS_ROOM, RECVONLY} from "../../../constants/constant";
import {AcClipboard} from "../../icon/AcClipboard";
import {useSnackbar} from "notistack";
import axios from "axios";
import {getCookieByName} from "../../../utils/CookieUtil";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {getRoomAiPrompts} from "../../../services/sora/soraApi";

interface Props {
    isShow: boolean
    handleClose: () => void
}

export default function RoomDetailModal (props: Props) {
    const {roomDetail, isMeetingOwner, channelId} = useSelector((state: RootState) => state.sora);
    const { t } = useTranslation();
    const [nameRoom, setNameRoom] = React.useState(roomDetail?.name);
    const [allowUserAccessValue, setAllowUserAccessValue] = React.useState(roomDetail?.allow_user_access);
    const [allowGuestAccessValue, setAllowGuestAccessValue] = React.useState(roomDetail?.allow_guest_access);
    const [allowUserPinValue, setAllowUserPinValue] = React.useState<boolean>(roomDetail?.allow_user_pin || false);
    const [aiPromptIdValue, setAiPromptIdValue] = React.useState<string>("");
    const {enqueueSnackbar} = useSnackbar();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const [roomAiPrompts, setRoomAiPrompts] = useState<RoomAiPrompts[]>();
    const theme = useTheme();

    useEffect(() => {
        if (userId && authToken && isMeetingOwner) {
            updateRoomAiPromptsList();
        }
    }, [isMeetingOwner]);

    useEffect(() => {
        if (roomDetail) {
            setNameRoom(roomDetail.name);
            setAllowUserAccessValue(roomDetail.allow_user_access);
            setAllowGuestAccessValue(roomDetail.allow_guest_access);
            setAllowUserPinValue(roomDetail.allow_user_pin || false);
            if (roomDetail.ai_prompt_id) {
                setAiPromptIdValue(roomDetail.ai_prompt_id.toString());
            } else {
                setAiPromptIdValue("");
            }
        }
    }, [roomDetail]);

    const updateRoomAiPromptsList = () => {
        setRoomAiPrompts([]);
        getRoomAiPrompts(userId, authToken).then(list => {
            setRoomAiPrompts(list);
        }).catch(err => {
            handleErrorMessage(err, enqueueSnackbar);
        });
    };

    const getManagers = (roomDetail: RoomDetail) => {
        const managers = roomDetail.managers.map(item => {
            return item.name;
        });
        return managers.join(", ");
    };

    const handleAllowUserAccessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllowUserAccessValue(Number.parseInt((event.target as HTMLInputElement).value));
    };

    const handleAllowGuestAccessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllowGuestAccessValue(Number.parseInt((event.target as HTMLInputElement).value));
    };

    const handleAllowUserPinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllowUserPinValue(event.target.checked);
    };

    const clipboardLink = (url: string) => {
        navigator.clipboard.writeText(url).then(r => {
            enqueueSnackbar(t("copied"), { variant: "success", autoHideDuration: 1000});
        }).catch(null);
    };

    const submitFormRoom = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!nameRoom) return;
        if (!roomDetail) return;
        if (roomDetail.managers?.length && roomDetail.managers.length > MAX_MANAGERS_ROOM) {
            enqueueSnackbar(t("Up to 3 people with the same authority as the owner can register"), { variant: "error", autoHideDuration: 5000});
            return;
        }
        const button = event.target as HTMLButtonElement;
        button.disabled = true;
        try {
            const managersId = roomDetail.managers?.map(manager => manager.account_id);
            const params = {
                channel_id: channelId,
                name: nameRoom,
                owner_id: roomDetail.owner.account_id,
                allow_user_access: allowUserAccessValue,
                allow_guest_access: allowGuestAccessValue,
                managers: managersId,
                main_channel_id: roomDetail.main_channel_id,
                allow_user_pin: allowUserPinValue,
                ai_prompt_id: aiPromptIdValue || null
            };

            await axios.put(`${process.env.REACT_APP_SESSION_API || ""}/session_room?a=${authToken}&u=${userId}`, params);
            const data = {
                channel_id: channelId,
                data: {
                    type: "update_room_details",
                    owners: [roomDetail.owner.account_id, ...managersId]
                }
            };
            axios.post(`${process.env.REACT_APP_WEBRTC_API || ""}`, data, {
                headers: {
                    "x-sora-target": "Sora_20160711.PushChannel"
                }
            }).then(null).catch(error => {
                console.log(error);
            });
            props.handleClose();
        } finally {
            button.disabled = false;
        }
    };

    return <Dialog
        open={props.isShow}
        onClose={props.handleClose}
        fullWidth={true}
        maxWidth="sm"
        className="customized-dialog"
    >
        {
            roomDetail &&
            <>
                <DialogTitle>
                    {stringFormat(t("room details"), roomDetail.name)}
                    <IconButton
                        aria-label="close"
                        onClick={props.handleClose}
                        sx={(theme) => ({
                            position: "absolute",
                            right: 0,
                            top: 0,
                            color: theme.palette.text.secondary
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="container-box">
                        <div className="content-container">
                            <div className="content-dialog">
                                {
                                    isMeetingOwner &&
                                    <div className="room-detail-item">
                                        <span className="room-detail-item-title">{t("room name")}</span>
                                        <TextFieldManager
                                            autoFocus
                                            margin="dense"
                                            id="name"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            required={true}
                                            value={nameRoom}
                                            onChange={(event) => {
                                                setNameRoom(event.target.value);
                                            }}
                                        />
                                    </div>
                                }
                                <div className="room-detail-item">
                                    <span className="room-detail-item-title">{t("owner")}</span>
                                    <span className="room-detail-item-data">{roomDetail.owner.name}</span>
                                </div>
                                {
                                    roomDetail.managers.length > 0 && !isMeetingOwner &&
                                    <div className="room-detail-item">
                                        <span className="room-detail-item-title">{t("Acting owner")}</span>
                                        <span className="room-detail-item-data">{getManagers(roomDetail)}</span>
                                    </div>
                                }
                                {
                                    isMeetingOwner &&
                                    <div className="room-detail-item">
                                        <span className="room-detail-item-title">{t("Acting owner")}</span>
                                        <CheckboxesTags/>
                                    </div>
                                }
                                <div className="room-detail-item">
                                    <span className="room-detail-item-title">{t("Access right")}</span>
                                    {
                                        isMeetingOwner
                                            ? <div>
                                                <Typography variant="subtitle1">
                                                    {t("Users who can enter the room")}
                                                </Typography>
                                                <FormGroup style={{marginTop: 10}}>
                                                    <FormLabel>{t("AirCampus User")}</FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="female"
                                                        name="radio-buttons-group"
                                                        style={{marginLeft: 20}}
                                                        value={allowUserAccessValue}
                                                        onChange={handleAllowUserAccessChange}
                                                    >
                                                        <FormControlLabel value={2} control={<Radio/>}
                                                                          label={t("You can enter if the owner approves")}/>
                                                        <FormControlLabel value={3} control={<Radio/>}
                                                                          label={t("You can enter the room if the AirCampus user who is in the room approves it")}/>
                                                        <FormControlLabel value={1} control={<Radio/>}
                                                                          label={t("If you know the URL, you can enter the room without approval")}/>
                                                    </RadioGroup>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormLabel>{t("guest")}</FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="female"
                                                        name="radio-buttons-group"
                                                        style={{marginLeft: 20}}
                                                        value={allowGuestAccessValue}
                                                        onChange={handleAllowGuestAccessChange}
                                                    >
                                                        <FormControlLabel value={2} control={<Radio/>}
                                                                          label={t("You can enter if the owner approves")}/>
                                                        <FormControlLabel value={3} control={<Radio/>}
                                                                          label={t("You can enter the room if the AirCampus user who is in the room approves it")}/>
                                                        <Typography variant="subtitle1">
                                                            {t("Guests are not allowed to enter without authorization")}
                                                        </Typography>
                                                    </RadioGroup>
                                                </FormGroup>
                                            </div>
                                            : <span className="room-detail-item-data"
                                                    dangerouslySetInnerHTML={{__html: getRoomType(t, roomDetail.allow_user_access, roomDetail.allow_guest_access)}}></span>
                                    }

                                </div>
                                {
                                    isMeetingOwner &&
                                    <div className="room-detail-item">
                                    <span className="room-detail-item-data">
                                        <FormControlLabel control={<Checkbox checked={allowUserPinValue}
                                                                             onChange={handleAllowUserPinChange}/>}
                                                          label={t("Allow user pin")}/>
                                    </span>
                                    </div>
                                }
                                {
                                    isMeetingOwner &&
                                    <div className="room-detail-item">
                                        <span className="room-detail-item-title">{t("AI prompt")}</span>
                                        <span className="room-detail-item-data">
                                            <FormControl variant="standard" fullWidth={true}
                                                         className="ai-prompt-select">
                                                <Select
                                                    value={aiPromptIdValue}
                                                    onChange={(event) => {
                                                        setAiPromptIdValue(event.target.value);
                                                    }}
                                                >
                                                    <MenuItem value="">&nbsp;</MenuItem>
                                                    {
                                                        roomAiPrompts?.map((item, index) => {
                                                            return <MenuItem key={index}
                                                                             value={item.id}>{item.title}</MenuItem>;
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </span>
                                    </div>
                                }
                                <div className="room-detail-item">
                                    <span className="room-detail-item-title">{t("Room URL")}</span>
                                    <span className="room-detail-item-data">
                                        <Typography variant="subtitle2" style={{fontSize: "1rem"}}>
                                            {t("Please share this URL with others when inviting them to a web conference.")}
                                        </Typography>
                                        <a href={getUrlMeeting(channelId)} target="_blank"
                                           style={{fontSize: "1rem"}}
                                           rel="noreferrer">{getUrlMeeting(channelId)}</a>
                                        <span className="clipboard-link"
                                              onClick={() => {
                                                  clipboardLink(getUrlMeeting(channelId));
                                              }}>
                                            <AcClipboard height="14px" color={theme.palette.text.primary}/>
                                        </span>
                                    </span>
                                </div>
                                <div className="room-detail-item">
                                    <span className="room-detail-item-title">{t("Webinar URL")}</span>
                                    <span className="room-detail-item-data">
                                        <Typography variant="subtitle2" style={{fontSize: "1rem"}}>
                                            {t("This is a URL that allows you to participate only by viewing. People who enter the room from this URL can join the room as viewing-only guests.")}
                                        </Typography>
                                        <a href={getUrlMeeting(channelId, RECVONLY)} target="_blank"
                                           style={{fontSize: "1rem"}}
                                           rel="noreferrer">{getUrlMeeting(channelId, RECVONLY)}</a>
                                        <span className="clipboard-link"
                                              onClick={() => {
                                                  clipboardLink(getUrlMeeting(channelId, RECVONLY));
                                              }}>
                                            <AcClipboard height="14px" color={theme.palette.text.primary}/>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                {
                    isMeetingOwner &&
                    <DialogActions>
                        <Button type="submit" onClick={(event) => {
                            submitFormRoom(event).then(null).catch(null);
                        }} color="secondary">{t("Update")}</Button>
                    </DialogActions>
                }
            </>
        }
    </Dialog>;
}
