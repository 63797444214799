import React from "react";

interface SignInIconProps {
    width?: string
    height?: string
    color?: string
}
export default function SignInIcon (props: SignInIconProps) {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} fill={props.color} className="bi bi-border-all" viewBox="0 0 32 32">
        <path fill={props.color} d="M2.1,16.6h19l-4.9,5c-0.4,0.4-0.4,1.1,0,1.3c0.1,0.1,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3l6.1-6.1 c0.1-0.1,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7l-0.3-0.3c0-0.1-0.1-0.1-0.3-0.3l-5.6-5.6c-0.4-0.4-0.9-0.4-1.3,0 c-0.4,0.4-0.4,0.9,0,1.2l4,4H2.1c-0.5,0-0.9,0.4-0.9,0.9C1.1,16.2,1.5,16.6,2.1,16.6L2.1,16.6z M30.9,28.1V4.5 C30.9,2.3,29,0,26.8,0H14.4C12,0,9.9,2.1,9.9,4.5v5.6c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9V4.5c0-1.3,1.3-2.7,2.5-2.7h12.3 c1.2,0,2.3,1.5,2.3,2.7v23.6c0,1.3-1.1,1.7-2.3,1.7H14.3c-1.1,0-2.5-0.4-2.5-1.7v-5.6c0-0.5-0.4-0.9-0.9-0.9 c-0.5,0-0.9,0.4-0.9,0.9v5.7c0,2.3,2,3.7,4.4,3.7h12.3C29.1,31.9,30.9,30.4,30.9,28.1L30.9,28.1z"/>
    </svg>;
}
