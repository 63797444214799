import Features, {type FeaturesRef} from "../../components/features/features";
import "./meeting.css";
import React, {useEffect, useRef, useState} from "react";
import MeetingComponent from "../../components/meeting/meeting";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import InputNameDialog from "../../components/modals/inputNameDialog";
import Chat from "../../components/chat/chat";
import {useMatches, useSearchParams} from "react-router-dom";
import {VirtualBackgroundProcessor} from "@shiguredo/virtual-background";
import {getCookieByName} from "../../utils/CookieUtil";
import ErrorPopup from "../../components/error/errorPopup";
import {
    ALLOW_ACCESS_TYPE,
    AVATAR_DEFAULT,
    COURSE_TYPE,
    FORUM_TYPE,
    IS_CAMERA,
    IS_MIC,
    LOGIN_JOIN_MEETING,
    MEETING_TYPE,
    MEETING_TYPE_VALUE,
    MESSAGE_NOT_ALLOWED,
    MESSAGE_ROOM_IS_FULL,
    RECVONLY,
    ROLE_CONNECT,
    SENDRECV,
    YOU_JP
} from "../../constants/constant";
import CushionPage from "../../components/cushionPage/cushionPage";
import {
    setNoiseSuppressionProcessor,
    setProcessor,
    setShowVirtualBackgroundModal
} from "../../services/virtualBackground/virtualBackgroundSlice";
import VirtualBackgroundModal from "../../components/modals/virtualBackground/virtualBackgroundModal";
import {closeSnackbar, type SnackbarKey, useSnackbar} from "notistack";
import ParticipantList from "../../components/participantList/participantList";
import {Helmet} from "react-helmet";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {
    fetchListRooms,
    getListConnectionsByChannel,
    handleStartSubtitle,
    handleSubtitleTranslationLanguage,
    pushChannel,
    putSignalingNotifyMetadata
} from "../../services/sora/soraApi";
import {
    decodeHTMLandURL,
    getRandomColor,
    handleErrorMessage,
    replaceEmoji,
    replaceUrlToTagA,
    secondsToMs,
    stringFormat
} from "../../utils/utils";
import {ButtonNotAllowed, ButtonUpdateRoom} from "../../components/styled/Button";
import useSound from "use-sound";
import {
    addMessageOnOffMicCameraList,
    addRemoteConnection,
    addRemoteMediaStream,
    type BreakoutRoom,
    getListBreakoutRooms,
    getRoomDetail,
    handleSubtitleLanguageChange,
    type Metadata,
    type Particles,
    ParticlesType,
    type RemoteConnection,
    removeAllIsPin,
    removeRemoteConnection,
    removeRemoteMediaStream,
    resetDataMeeting,
    setBreakoutRoom,
    setBreakoutRoomExpirationTime,
    setBreakoutRooms,
    setBreakoutRoomStarted,
    setCameraOn, setCamerasErrorType,
    setChannelId,
    setConnectionIdPin,
    setConnectionSetPin,
    setConnectType,
    setContentSubTitle,
    setCountdownValue,
    setDisableClickBreakoutRoom,
    setEmojiParticles,
    setEnqueueSnackbarKeyEmptyRoom,
    setEnqueueSnackbarNetworkKey,
    setErrorCamera,
    setErrorMic,
    setFireworksParticles,
    setFirstLogin,
    setIdConnectShared,
    setInitializingStream,
    setIsMineShare,
    setIsPin,
    setIsSeenMessage,
    setIsSubTitle,
    setJoinBreakoutRoom,
    setJoinMeetingButtonDisabled,
    setLightAdjustmentProcessor,
    setLocalMediaStream,
    setMeeting,
    setMeetingOwner,
    setMeetingType,
    setMessageChats, setMicErrorType,
    setMicroOn,
    setMyConnectionId,
    setNameSetCountdown,
    setOneMinuteLeftInTheBreakoutRooms,
    setOpenErrorCameraDialog,
    setOpenErrorMicDialog,
    setOpenInputNameDialog,
    setRecording,
    setRemoteConnectionBreakoutRoomStarted,
    setRoomName,
    setShowBreakoutRoom,
    setShowChat,
    setShowContentChat,
    setShowListUser,
    setSHowParticipantList,
    setSnowParticles,
    setSpeakingTime,
    setSpeakingTimeMine,
    setTextMessage,
    setUpdatingBreakoutRoom,
    type TranscriptDeepGram,
    updateMetadata,
    updateRemoteConnection
} from "../../services/sora/soraSlice";
import Sora, {
    type ConnectionOptions,
    type ConnectionPublisher,
    type ConnectionSubscriber,
    type JSONType
} from "sora-js-sdk";
import {
    type Device,
    initDevices,
    setCameraUsing,
    setIsLoading,
    setMicUsing,
    videoMediaTrackConstraints
} from "../../services/devices/devicesSlice";
import {LabelDataChannel, type MessageChat} from "../../services/type/chatType";
import {NoiseSuppressionProcessor} from "@shiguredo/noise-suppression";
import {LightAdjustmentGpuProcessor, llieModelNames} from "@shiguredo/light-adjustment-gpu/dist/light_adjustment_gpu";
import BreakoutRooms from "../../components/rooms/breakoutRooms";
import ErrorCameraDialog from "../../components/modals/device/errorCameraDialog";
import ErrorMicDialog from "../../components/modals/device/errorMicDialog";
import type {AudioStreamingResult, GetSpeakingTimeResponse} from "../../types/types";
import SubTitleList from "../../components/subTitle/subtitleList";
import SubTitleIcon from "../../components/icon/SubTitleIcon";
import {AcMemberOutline} from "../../components/icon/AcMemberOutline";
import SubRoomIcon from "../../components/icon/SubRoomIcon";
import BreakoutRoomStartDialog from "../../components/modals/breakoutRoom/breakoutRoomStartDialog";
import {confirmAlert} from "react-confirm-alert";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme} from "@mui/material";
import {HtmlTooltip} from "../../components/icon/icon";

const assetsPath = "/virtual-background/";
let isClickMic = false;
const randomColor = getRandomColor();
const assetsNoiseSuppressionPath = "https://cdn.jsdelivr.net/npm/@shiguredo/noise-suppression@latest/dist";

const assetsPathLightAdjustment = "https://cdn.jsdelivr.net/npm/@shiguredo/light-adjustment-gpu@latest/dist/";
const modelLightAdjustment = llieModelNames.semanticGuidedLlie648x360;

interface RequestAccessData {
    name: string
    clientId: string
    userId: string
}

interface JoinBreakoutRoom {
    connectionId: string
    roomName: string
}

let joinBreakoutRoomList: JoinBreakoutRoom[] = [];
let timeoutExpirationTimeBreakoutRoom: any = null;
let timeoutBreakoutRoomStarted: any = null;
let timeoutOneMinuteLeftInTheBreakoutRooms: any = null;
let timeoutTimerSettingBlinker: any = null;
let clientIdUpdateUpdateLanguageSubtitle: string[] = [];

function Meeting () {
    const {
        signalingUrlCandidates,
        channelId,
        metadata,
        isMeeting,
        isMicroOn,
        isCameraOn,
        isMineShare,
        isShowChat,
        isOpenInputNameDialog,
        remoteConnection,
        connectionIdPin,
        connectionSetPin,
        isSHowParticipantList,
        isShowBreakoutRoom,
        countdownValue,
        nameSetCountdown,
        meetingType,
        connectType,
        roomName,
        speakingTimeMine,
        isSeenMessage,
        isSubTitle,
        isMeetingOwner,
        breakoutRoom,
        roomDetail,
        isLightAdjustment,
        lightAdjustmentProcessor,
        strengthLightAdjustment,
        enqueueSnackbarNetworkKey,
        myConnectionId,
        isMirroringWebcam,
        breakoutRooms,
        mainRoomName,
        isScreenShareAudio,
        isOpenErrorCameraDialog,
        isOpenErrorMicDialog,
        subtitleLanguage,
        sessionId,
        subtitleTranslationLanguage,
        widthSidebar,
        isTranslateTranslatedSentences,
        isUpdatingBreakoutRoom
    } = useSelector((state: RootState) => state.sora);
    const dispatch = useDispatch<AppDispatch>();
    const sora = Sora.connection(signalingUrlCandidates, false);
    const userId = getCookieByName("u");
    const authToken = getCookieByName("a");
    const [options, setOptions] = useState<ConnectionOptions>({
        videoCodecType: "VP8",
        multistream: true,
        spotlight: true,
        spotlightNumber: 3,
        signalingNotifyMetadata: {
            userId,
            isRecvonly: false,
            network: null,
            speakingTime: 0,
            isMirroringWebcam
        } as any,
        dataChannelSignaling: true,
        audioStreamingLanguageCode: subtitleLanguage,
        dataChannels: [
            {
                label: LabelDataChannel.LABEL_ON_OFF_MIC_CAMERA,
                direction: "sendrecv",
                compress: true
            },
            {
                label: LabelDataChannel.LABEL_RECORDING,
                direction: "sendrecv",
                compress: true
            },
            {
                label: LabelDataChannel.LABEL_MESSAGE_CHAT,
                direction: "sendrecv",
                compress: true
            },
            {
                label: LabelDataChannel.LABEL_PIN_ALL_ON,
                direction: "sendrecv",
                compress: true
            },
            {
                label: LabelDataChannel.LABEL_PIN_ALL_OFF,
                direction: "sendrecv",
                compress: true
            },
            {
                label: LabelDataChannel.LABEL_COUNT_DOWN,
                direction: "sendrecv",
                compress: true
            },
            {
                label: LabelDataChannel.LABEL_SHOW_PARTICLES,
                direction: "sendrecv",
                compress: true
            }
        ]
    });

    const [optionsShareScreen, setOptionsShareScreen] = useState<ConnectionOptions>({
        videoCodecType: "VP8",
        multistream: true,
        spotlight: true,
        spotlightNumber: 3,
        signalingNotifyMetadata: {isShareScreen: true} as any,
        bundleId: "share-screen"
    });
    const [connectSora, setConnectSora] = useState<ConnectionPublisher | ConnectionSubscriber>(connectType === RECVONLY ? sora.recvonly(channelId, metadata, options) : sora.sendrecv(channelId, metadata, options));
    const [sendrecvShareScreen, setSendrecvShareScreen] = useState<ConnectionPublisher>(sora.sendrecv(channelId, metadata, optionsShareScreen));
    const [isError, setError] = useState<boolean>(false);
    const {localMediaStream, remoteMediaStreams, textMessage, objectChat} = useSelector((state: RootState) => state.sora);
    const {cameras, micros, deviceUsing, videoQuality} = useSelector((state: RootState) => state.devices);
    const {processor, blurRadius, backgroundImage, noiseSuppressionProcessor, isNoiseSuppression} = useSelector((state: RootState) => state.virtualBackground);
    const [screenStream, setScreenStream] = useState<MediaStream>();
    const {isShowVirtualBackgroundModal} = useSelector((state: RootState) => state.virtualBackground);
    const featuresRef = useRef<FeaturesRef>(null);
    const {enqueueSnackbar} = useSnackbar();
    const matches = useMatches();
    const isConnectSora = useRef(false);
    const { t } = useTranslation();
    const [playSound] = useSound("/mp3/join-meeting.mp3");
    const { authenticated } = useSelector((state: RootState) => state.authentication);
    const [isAutoConnectSora, setAutoConnectSora] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const redirect = searchParams.get("redirect");
    const [connectionIdReconnectSora, setConnectionIdReconnectSora] = useState<string>();
    const optionsSoraOwnerJoinBreakoutRoom: ConnectionOptions = {
        videoCodecType: "VP8",
        multistream: true,
        spotlight: true,
        signalingNotifyMetadata: {
            isRecvonly: true,
            isConnectSoraOwnerJoinBreakoutRoom: true
        },
        clientId: userId
    };
    const [connectSoraOwnerJoinBreakoutRoom, setConnectSoraOwnerJoinBreakoutRoom] = useState<ConnectionSubscriber>(sora.recvonly(channelId, metadata, optionsSoraOwnerJoinBreakoutRoom));
    const prevBreakoutRoomRef = useRef<BreakoutRoom>();
    const [playSoundRequest] = useSound("/mp3/request_access.wav");
    const [keyEnqueueSnackbarSubtitleLanguageChange, setKeyEnqueueSnackbarSubtitleLanguageChange] = useState<SnackbarKey>();
    const timeoutUpdateInitializingStream = useRef<any>(null);
    const [keyEnqueueSnackbarLeftRoom, setKeyEnqueueSnackbarLeftRoom] = useState<SnackbarKey>();
    const [isOpenBreakoutRoomStartDialog, setOpenBreakoutRoomStartDialog] = useState(false);
    const isNoDisconnectHandlingSoraRef = useRef<boolean>(false);
    const theme = useTheme();
    const connectionDisconnectRef = useRef<string[]>([]);

    useEffect(() => {
        const match = matches[0];
        if (match) {
            if (match.params.type === FORUM_TYPE && match.params.id) {
                dispatch(updateMetadata({
                    type: FORUM_TYPE,
                    extra_params: {
                        forum_id: match.params.id
                    }
                }));
                dispatch(setChannelId(`${match.params.type} ${match.params.id}`));
                dispatch(setRoomName(`${match.params.type} ${match.params.id}`));
            } else if (match.params.type === COURSE_TYPE && match.params.id) {
                dispatch(updateMetadata({
                    type: COURSE_TYPE,
                    extra_params: {
                        course_code: match.params.id
                    }
                }));
                dispatch(setChannelId(`${match.params.type} ${match.params.id}`));
                dispatch(setRoomName(`${match.params.type} ${match.params.id}`));
            } else if (match.params.type === MEETING_TYPE && match.params.id) {
                const searchParams = new URLSearchParams(location.search);
                const roleParam = searchParams.get(ROLE_CONNECT);
                if (RECVONLY === roleParam) {
                    dispatch(setConnectType(roleParam));
                }
                const channelId = match.params.id;
                dispatch(updateMetadata({
                    type: MEETING_TYPE_VALUE
                }));
                dispatch(setChannelId(channelId));
                dispatch(setMeetingType(MEETING_TYPE_VALUE));
                dispatch(getRoomDetail()).then(null).catch(null);
            } else if (match.params.channelId) {
                dispatch(setChannelId(match.params.channelId));
                dispatch(setRoomName(match.params.channelId));
            }
        }
    }, [matches]);

    useEffect(() => {
        // eslint-disable-next-line
        const metaTag = document.querySelector('meta[name="description"]');

        if (metaTag) {
            metaTag.setAttribute("content", stringFormat(t("meta-content"), roomName));
        }
    }, [roomName]);

    useEffect(() => {
        const getMediaDevices = async () => {
            try {
                await navigator.mediaDevices.getUserMedia({video: true, audio: true});
            } catch (err) {
                console.error(`you got an error: ${err as string}`);
            }
            try {
                const userMediaStream = await navigator.mediaDevices.getUserMedia({video: false, audio: true});
                const audioTracks = userMediaStream.getAudioTracks();
                if (audioTracks.length > 0 && !deviceUsing.micro?.deviceId) {
                    const deviceId = audioTracks[0].getSettings().deviceId;
                    if (deviceId) {
                        const label = audioTracks[0].label;
                        const device: Device = {
                            deviceId,
                            label
                        };
                        dispatch(setMicUsing(device));
                    }
                }
                userMediaStream.getTracks().forEach(track => {
                    track.stop();
                });
            } catch (err) {
                console.error(`you got an error: ${err as string}`);
                dispatch(setErrorMic(true));
                dispatch(setMicErrorType((err as DOMException).name));
            }
            try {
                const userMediaStream = await navigator.mediaDevices.getUserMedia({video: true, audio: false});
                const videoTracks = userMediaStream.getVideoTracks();

                if (videoTracks.length > 0 && !deviceUsing.camera?.deviceId) {
                    const deviceId = videoTracks[0].getSettings().deviceId;
                    if (deviceId) {
                        const label = videoTracks[0].label;
                        const device: Device = {
                            deviceId,
                            label
                        };
                        dispatch(setCameraUsing(device));
                    }
                }
                userMediaStream.getTracks().forEach(track => {
                    track.stop();
                });
            } catch (err) {
                console.error(`you got an error: ${err as string}`);
                dispatch(setErrorCamera(true));
                dispatch(setCamerasErrorType((err as DOMException).name));
            }
            await dispatch(initDevices());
        };
        dispatch(setProcessor(new VirtualBackgroundProcessor(assetsPath)));
        dispatch(setNoiseSuppressionProcessor(new NoiseSuppressionProcessor(assetsNoiseSuppressionPath)));
        dispatch(setLightAdjustmentProcessor(new LightAdjustmentGpuProcessor(assetsPathLightAdjustment, modelLightAdjustment, strengthLightAdjustment)));
        setUserIdAndAuthTokenForMetadata();
        getMediaDevices().then(null).catch(null);
    }, []);

    useEffect(() => {
        try {
            if (navigator?.permissions && navigator.permissions.query) {
                navigator.permissions
                    .query({name: "camera"} as unknown as PermissionDescriptor)
                    .then((permissionStatus) => {
                        permissionStatus.onchange = () => {
                            dispatch(setIsLoading(false));
                            dispatch(initDevices()).then(null).catch(null);
                        };
                    }).catch(null);
                navigator.permissions
                    .query({name: "microphone"} as unknown as PermissionDescriptor)
                    .then((permissionStatus) => {
                        permissionStatus.onchange = () => {
                            dispatch(setIsLoading(false));
                            dispatch(initDevices()).then(null).catch(null);
                        };
                    }).catch(null);
            }

            navigator.mediaDevices.ondevicechange = (event) => {
                dispatch(setIsLoading(false));
                dispatch(initDevices()).then(null).catch(null);
            };
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("offline", handleOffline);
        window.addEventListener("online", handleOnline);

        return () => {
            window.removeEventListener("offline", handleOffline);
            window.removeEventListener("online", handleOnline);
        };
    }, [enqueueSnackbarNetworkKey, isMeeting, connectSora, localMediaStream]);

    const handleOffline = () => {
        console.log("Network connection has been lost.");
        if (isMeeting) {
            const key = enqueueSnackbar(t("A network disconnection has been detected. Please wait while we try to reconnect."), { variant: "info", autoHideDuration: null });
            dispatch(setEnqueueSnackbarNetworkKey(key));
        }
    };

    const handleOnline = () => {
        console.log("Network connection has been restored.");
        if (enqueueSnackbarNetworkKey) {
            closeSnackbar(enqueueSnackbarNetworkKey);
            dispatch(setEnqueueSnackbarNetworkKey(undefined));
            if (localMediaStream) {
                dispatch(resetDataMeeting({isJoinBreakoutRoom: false}));
                connectSora.connect(localMediaStream).then(null).catch(null);

                // reconnect share
                if (isMineShare && screenStream) {
                    sendrecvShareScreen.connect(screenStream).then(() => {
                        setScreenStream(screenStream);
                        dispatch(setIsMineShare(true));
                        if (sendrecvShareScreen.connectionId) {
                            dispatch(setIdConnectShared(sendrecvShareScreen.connectionId));
                            dispatch(removeAllIsPin());
                            dispatch(setIsPin(sendrecvShareScreen.connectionId));
                            startPinAll(sendrecvShareScreen.connectionId);
                        }
                    }).catch(null);
                }
            }
        }
    };

    useEffect(() => {
        setConnectSora(connectType === RECVONLY ? sora.recvonly(channelId, metadata, options) : sora.sendrecv(channelId, metadata, options));
        setSendrecvShareScreen(sora.sendrecv(channelId, metadata, optionsShareScreen));
        setConnectSoraOwnerJoinBreakoutRoom(sora.recvonly(channelId, metadata, optionsSoraOwnerJoinBreakoutRoom));
    }, [channelId, metadata, options]);

    useEffect(() => {
        setSendrecvShareScreen(sora.sendrecv(channelId, metadata, optionsShareScreen));
    }, [optionsShareScreen]);

    useEffect(() => {
        if (isClickMic) {
            if (localMediaStream) {
                for (const track of localMediaStream.getAudioTracks()) {
                    track.enabled = isMicroOn;
                }
            }
            isClickMic = false;
        } else {
            updateLocalMediaStream().then(null).catch(error => {
                console.log(error);
            });
        }
    }, [isMicroOn, isCameraOn, blurRadius, backgroundImage, deviceUsing.camera, deviceUsing.micro, micros, videoQuality, isNoiseSuppression, isLightAdjustment]);

    // useEffect(() => {
    //     if (localMediaStream) {
    //         for (const track of localMediaStream.getAudioTracks()) {
    //             track.enabled = isMicroOn;
    //         }
    //     }
    // }, [isMicroOn]);

    useEffect(() => {
        const connection = remoteConnection.find(connection => connection.mediaStreamId === localMediaStream?.id);
        setOptions({
            ...options,
            signalingNotifyMetadata: {
                userId,
                isRecvonly: connectType === RECVONLY,
                network: {
                    networkStatus: connection?.networkStatus
                },
                isMirroringWebcam
            },
            clientId: userId
        });
    }, [connectType]);

    useEffect(() => {
        if (breakoutRoom && breakoutRoom.channelId !== prevBreakoutRoomRef.current?.channelId) {
            updateConnectSoraForBreakoutRooms(breakoutRoom).then(null).catch(null).finally(() => {
                dispatch(setDisableClickBreakoutRoom(false));
            });
        }
    }, [breakoutRoom]);

    useEffect(() => {
        const breakoutRoom = {
            channelId,
            roomName
        };
        prevBreakoutRoomRef.current = breakoutRoom;
        dispatch(setBreakoutRoom(breakoutRoom));
        if (meetingType === MEETING_TYPE_VALUE) {
            dispatch(getListBreakoutRooms()).then(null).catch(null);
        }
    }, [channelId]);

    const updateConnectSoraForBreakoutRooms = async (breakoutRoom: BreakoutRoom) => {
        dispatch(setDisableClickBreakoutRoom(true));
        dispatch(setUpdatingBreakoutRoom(true));
        if (myConnectionId) {
            const data = {
                channel_id: channelId,
                data: {
                    type: "join_breakout_room",
                    connectionId: myConnectionId,
                    roomName: breakoutRoom.roomName
                }
            };
            await pushChannel(data);
        }
        dispatch(resetDataMeeting({isJoinBreakoutRoom: true}));
        if (isMineShare) {
            screenStream?.getVideoTracks()[0].stop();
            await sendrecvShareScreen.disconnect();
            dispatch(setIsMineShare(false));
        }

        // If you are the owner, create a connection in the main room so the session is not lost.
        if (isMeetingOwner) {
            if (breakoutRoom.channelId === channelId) {
                await connectSoraOwnerJoinBreakoutRoom.disconnect();
            } else if (channelId === prevBreakoutRoomRef.current?.channelId) {
                await connectSoraOwnerJoinBreakoutRoom.connect();
            }
        }
        isConnectSora.current = true;
        setConnectSora(connectType === RECVONLY ? sora.recvonly(breakoutRoom.channelId, metadata, options) : sora.sendrecv(breakoutRoom.channelId, metadata, options));
        setSendrecvShareScreen(sora.sendrecv(breakoutRoom.channelId, metadata, optionsShareScreen));
        dispatch(setRoomName(breakoutRoom.roomName));
        prevBreakoutRoomRef.current = breakoutRoom;
    };

    const connectSendrecv = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if ((userId && authToken) || metadata.name) {
            setError(false);
            dispatch(setJoinMeetingButtonDisabled(true));
            connectSoraFunc().then(() => {
                dispatch(setJoinMeetingButtonDisabled(false));
                dispatch(setFirstLogin(true));
            }).catch((e) => {
                handelErrorConnectSora(e);
            });
        } else {
            dispatch(setOpenInputNameDialog(true));
        }
    };

    useEffect(() => {
        if (isConnectSora.current) {
            dispatch(setJoinMeetingButtonDisabled(true));
            connectSoraFunc().then(() => {
                dispatch(setJoinMeetingButtonDisabled(false));
                dispatch(setFirstLogin(true));
            }).catch((e) => {
                handelErrorConnectSora(e);
                if (isUpdatingBreakoutRoom) {
                    reconnectBreakoutRoom().then(null).catch(null);
                }
            }).finally(() => {
                isConnectSora.current = false;
                dispatch(setUpdatingBreakoutRoom(false));
                if (keyEnqueueSnackbarSubtitleLanguageChange) {
                    closeSnackbar(keyEnqueueSnackbarSubtitleLanguageChange);
                    setKeyEnqueueSnackbarSubtitleLanguageChange(undefined);
                }
            });
        }

        return () => {
            connectSora.disconnect().then(null).catch(null);
        };
    }, [connectSora]);

    const handelErrorConnectSora = (e: any) => {
        if (meetingType === MEETING_TYPE_VALUE) {
            if (e.message === MESSAGE_NOT_ALLOWED) {
                requestAccess().then(null).catch(null);
            } else if (e.message === MESSAGE_ROOM_IS_FULL) {
                dispatch(setJoinMeetingButtonDisabled(false));
                enqueueSnackbar(t("You could not enter the room because the number of people who can enter was exceeded."), { variant: "error" });
            } else {
                console.log(e);
                dispatch(setJoinMeetingButtonDisabled(false));
                enqueueSnackbar(t("An error has occurred"), { variant: "error" });
            }
        } else if (e.message === MESSAGE_ROOM_IS_FULL) {
            dispatch(setJoinMeetingButtonDisabled(false));
            enqueueSnackbar(t("You could not enter the room because the number of people who can enter was exceeded."), { variant: "error" });
        } else {
            console.log(e);
            dispatch(setJoinMeetingButtonDisabled(false));
            enqueueSnackbar(t("An error has occurred"), { variant: "error" });
        }
    };

    const connectSoraFunc = async () => {
        if (localMediaStream) {
            await connectSora.connect(localMediaStream);
            dispatch(setMeeting(true));
            if (keyEnqueueSnackbarLeftRoom) {
                closeSnackbar(keyEnqueueSnackbarLeftRoom);
            }
        }
    };

    const requestAccess = async () => {
        if (channelId) {
            const listConnectionsByChannel = await getListConnectionsByChannel(channelId);
            if (listConnectionsByChannel.length === 0) {
                const enqueueSnackbarKeyEmptyRoom = enqueueSnackbar(t("requesting-if-no-one-is-in-the-room"), { variant: "error", autoHideDuration: null });
                dispatch(setJoinMeetingButtonDisabled(true));
                dispatch(setEnqueueSnackbarKeyEmptyRoom(enqueueSnackbarKeyEmptyRoom));
                return;
            }
        }
        dispatch(setEnqueueSnackbarKeyEmptyRoom(undefined));
        const data = {
            channel_id: channelId,
            data: {
                type: "request_access",
                name: metadata.name,
                userId: metadata.u,
                clientId: options.clientId
            }
        };
        await pushChannel(data);
        const enqueueSnackbarKey = enqueueSnackbar(t("request-has-been-sent"), { autoHideDuration: null });
        const timeout = setTimeout(() => {
            clearInterval(interval);
            closeSnackbar(enqueueSnackbarKey);
            enqueueSnackbar(t("wording-when-permission-to-enter-the-room-is-not-obtained-for-about-30-seconds"), { variant: "error", autoHideDuration: null });
            dispatch(setJoinMeetingButtonDisabled(false));
        }, 30000);

        const interval = setInterval(() => {
            (async () => {
                const urlParam = new URLSearchParams();
                if (options.clientId) {
                    urlParam.append("client_id", options.clientId);
                }
                if (userId && authToken) {
                    urlParam.append("u", userId);
                    urlParam.append("u", authToken);
                }
                const response = await axios.get(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/room/${channelId}/check?${urlParam.toString()}`);
                if (response.data?.data?.allowed === true) {
                    clearInterval(interval);
                    clearInterval(timeout);
                    closeSnackbar(enqueueSnackbarKey);
                    connectSoraFunc().then(() => {
                        dispatch(setJoinMeetingButtonDisabled(false));
                    }).catch((e) => {
                        console.log(e);
                        dispatch(setJoinMeetingButtonDisabled(false));
                        enqueueSnackbar(t("An error has occurred"), { variant: "error" });
                    });
                } else if (response.data?.data?.allowed === false) {
                    clearInterval(interval);
                    clearInterval(timeout);
                    closeSnackbar(enqueueSnackbarKey);
                    const message = <span dangerouslySetInnerHTML={{__html: t("request-denied")}}/>;
                    enqueueSnackbar(message, { variant: "error", autoHideDuration: null });
                    dispatch(setJoinMeetingButtonDisabled(false));
                }
            })().then(null).catch(null);
        }, 3000);
    };

    const getUserMedia = async () => {
        // there's always sound
        let audio: any = micros.length > 0;
        const video = isCameraOn;
        const userMedia: MediaStream = new MediaStream();
        const constraintsAudio: MediaStreamConstraints = {
            audio
        };
        const constraintsVideo: MediaStreamConstraints = {
            video
        };

        if (isCameraOn && deviceUsing.camera !== null) {
            if (videoQuality !== undefined) {
                const videoConstraints = videoMediaTrackConstraints[videoQuality];
                constraintsVideo.video = {
                    ...videoConstraints,
                    deviceId: deviceUsing.camera.deviceId
                };
            } else {
                constraintsVideo.video = {deviceId: deviceUsing.camera.deviceId};
            }
        }

        if (audio) {
            audio = {
                sampleRate: {ideal: 48000},
                sampleSize: {ideal: 480},
                channelCount: {exact: 1}
            };
            constraintsAudio.audio = audio;
        }

        if (audio && deviceUsing.micro !== null) {
            constraintsAudio.audio = {...audio, deviceId: deviceUsing.micro.deviceId};
        }

        if (constraintsAudio.audio) {
            try {
                const userMediaAudio = await navigator.mediaDevices.getUserMedia(constraintsAudio);
                userMediaAudio.getAudioTracks().forEach(track => {
                    userMedia.addTrack(track);
                });
                dispatch(setErrorMic(false));
            } catch (ex) {
                console.log(ex);
                dispatch(setErrorMic(true));
                dispatch(setMicroOn(false));
                dispatch(setMicErrorType((ex as DOMException).name));
            }
        }

        if (constraintsVideo.video) {
            try {
                const userMediaVideo = await navigator.mediaDevices.getUserMedia(constraintsVideo);
                userMediaVideo.getVideoTracks().forEach(track => {
                    userMedia.addTrack(track);
                });
                dispatch(setErrorCamera(false));
            } catch (ex) {
                dispatch(setErrorCamera(true));
                dispatch(setCameraOn(false));
                dispatch(setCamerasErrorType((ex as DOMException).name));
            }
        }

        if (isCameraOn) {
            await setVirtualBackground(userMedia);
            if (isLightAdjustment) {
                await lightAdjustment(userMedia);
            }
        }
        if (isNoiseSuppression) {
            await noiseSuppression(userMedia);
        }
        return userMedia;
    };

    connectSora.on("track", function (event) {
        const stream = event.streams[0];
        if (stream === null) return;
        dispatch(addRemoteMediaStream(stream));
    });

    connectSora.on("removetrack", function (event) {
        const mediaStream = remoteMediaStreams.find((stream) => {
            if (event?.target) {
                return stream.id === (event.target as MediaStream).id;
            }
            return null;
        });
        if (mediaStream) {
            dispatch(removeRemoteMediaStream((event.target as MediaStream).id));
        }
    });

    connectSora.on("notify", function (event) {
        switch (event.event_type) {
            case "connection.created": {
                setTimeout(() => {
                    // sendMessageOnOffMicCamara after 1 second
                    sendMessageOnOffMicCamara(isMicroOn, isCameraOn);

                    // send message Pin all after 1 second
                    if (connectionIdPin) {
                        let connectionId = connectionIdPin;
                        if (connectionId === localMediaStream?.id && connectSora.connectionId) {
                            connectionId = connectSora.connectionId;
                        }
                        const message = {
                            connectionId,
                            connectionSetPin
                        };
                        connectSora.sendMessage(LabelDataChannel.LABEL_PIN_ALL_ON, new TextEncoder().encode(JSON.stringify(message)));
                    }
                }, 1000);

                if (event.data) {
                    event.data?.forEach(item => {
                        addConnection(item.metadata, item.authn_metadata, item.authz_metadata, item.connection_id, item.client_id, false);
                    });
                } else {
                    addConnection(event.metadata, event.authn_metadata, event.authz_metadata, event.connection_id, event.client_id, true);
                }

                const connectionMine = remoteConnection.find(connection => connection.isMine);
                if (connectionMine && event.connection_id === connectionMine.connectionId) {
                    const metadata = getMetadata(event.metadata, event.authn_metadata, event.authz_metadata);
                    const connectionEdit: RemoteConnection = {
                        ...connectionMine,
                        breakoutRoom: metadata.breakout_room
                    };
                    dispatch(updateRemoteConnection(connectionEdit));
                    if (connectionMine.connectionId) {
                        putSignalingNotifyMetadata(channelId, connectionMine.connectionId, "name", connectionMine.realName).then(null).catch(null);
                    }
                }
                break;
            }
            case "connection.destroyed":
                if (event.connection_id !== undefined) {
                    dispatch(removeRemoteConnection(event.connection_id));
                    const metadata = getMetadata(event.metadata, event.authn_metadata, event.authz_metadata);
                    const isUpdateUpdateLanguageSubtitle = clientIdUpdateUpdateLanguageSubtitle.some(item => item === event.client_id);
                    if (!metadata.isConnectSoraOwnerJoinBreakoutRoom && !isUpdateUpdateLanguageSubtitle) {
                        const joinBreakoutRoom = joinBreakoutRoomList.find(joinBreakoutRoom => joinBreakoutRoom.connectionId === event.connection_id);
                        if (metadata.isShareScreen) {
                            dispatch(setShowListUser(false));
                            enqueueSnackbar(stringFormat(t("you have stopped screen sharing"), metadata.name), {variant: "success" });
                        } else if (joinBreakoutRoom) {
                            enqueueSnackbar(stringFormat(t("user has joined room."), metadata.name, joinBreakoutRoom.roomName), {variant: "success" });
                            joinBreakoutRoomList = joinBreakoutRoomList.filter(item => item.connectionId !== joinBreakoutRoom.connectionId);
                        } else {
                            if (connectionDisconnectRef.current.some(item => item === event.connection_id)) { // If you press the leave call button
                                enqueueSnackbar(stringFormat(t("has left the room"), metadata.name), {variant: "success" });
                                connectionDisconnectRef.current = connectionDisconnectRef.current.filter(item => item !== event.connection_id);
                            } else {
                                enqueueSnackbar(stringFormat(t("x's connection has been cut off"), metadata.name), {variant: "success" });
                            }
                        }
                    }
                }
                break;
            case "network.status": {
                const networkStatus = event.unstable_level;
                const connection = remoteConnection.find(connection => connection.isMine);
                if (connection?.connectionId) {
                    if (connection.networkStatus !== networkStatus) {
                        const network = {
                            // network: getNetwork(),
                            networkStatus
                        };
                        putSignalingNotifyMetadata(channelId, connection.connectionId, "network", network).then(null).catch(null);
                    }
                }
                break;
            }
        }
    });

    connectSora.on("message", (event) => {
        switch (event.label) {
            // message mic or camera on off
            case LabelDataChannel.LABEL_ON_OFF_MIC_CAMERA: {
                const message = JSON.parse(new TextDecoder().decode(event.data));
                dispatch(addMessageOnOffMicCameraList(message));
                break;
            }
            // message recording
            case LabelDataChannel.LABEL_RECORDING: {
                const message = JSON.parse(new TextDecoder().decode(event.data));
                dispatch(setRecording(message.isRecording));
                if (message.isRecording) {
                    enqueueSnackbar(stringFormat(t("Recording was started"), message.name as string), {variant: "info", anchorOrigin: {horizontal: "center", vertical: "top"}});
                } else {
                    enqueueSnackbar(stringFormat(t("Recording was stopped"), message.name as string), {variant: "info", anchorOrigin: {horizontal: "center", vertical: "top"}});
                }
                break;
            }
            // message chat
            case LabelDataChannel.LABEL_MESSAGE_CHAT: {
                const message = JSON.parse(new TextDecoder().decode(event.data));

                if (message.receiverId === "all" || message.receiverId === connectSora.connectionId) {
                    const messageChat: MessageChat = {
                        senderImageUrl: message.senderImageUrl,
                        content: message.content,
                        time: message.time,
                        senderName: message.senderName,
                        senderId: message.senderId,
                        locationId: message.locationId,
                        receiverId: message.receiverId
                    };

                    if (remoteConnection.find(connection => connection.isPin)) {
                        const snackbarContent = (
                            <div className="container-snackbar">
                                <img src={messageChat.senderImageUrl ? messageChat.senderImageUrl : AVATAR_DEFAULT}
                                    className="avatar-snackbar"
                                    onError={(e) => {
                                        (e.target as HTMLImageElement).src = AVATAR_DEFAULT;
                                    }} />
                                <div className="name-content-snackbar">
                                    <span className="name-snackbar">{messageChat.senderName}</span>
                                    <span dangerouslySetInnerHTML={{__html: replaceEmoji(replaceUrlToTagA(messageChat.content))}}></span>
                                </div>
                            </div>
                        );
                        enqueueSnackbar(snackbarContent, {
                            variant: "info",
                            autoHideDuration: 20000,
                            anchorOrigin: {horizontal: "center", vertical: "top"}
                        });
                    }

                    if (!isShowChat && isSeenMessage) {
                        dispatch(setIsSeenMessage(false));
                    }

                    dispatch(setMessageChats(messageChat));
                    dispatch(setShowContentChat(messageChat));
                }
                break;
            }
            // on pin all
            case LabelDataChannel.LABEL_PIN_ALL_ON: {
                const message = JSON.parse(new TextDecoder().decode(event.data));
                console.log(message);
                if (localMediaStream && message.connectionId === connectSora.connectionId) {
                    message.connectionId = localMediaStream.id;
                }

                const connection = remoteConnection.find(connection => connection.mediaStreamId === message.connectionId);

                if (connection && !connection.isPin) {
                    enqueueSnackbar(stringFormat(t("It was enlarged"), message.connectionSetPin.realName as string), {
                        variant: "info",
                        anchorOrigin: {horizontal: "center", vertical: "top"}
                    });

                    dispatch(setConnectionSetPin(message.connectionSetPin));
                    dispatch(removeAllIsPin());
                    dispatch(setIsPin(message.connectionId));
                    dispatch(setConnectionIdPin(message.connectionId));
                }
                break;
            }
            // off pin all
            case LabelDataChannel.LABEL_PIN_ALL_OFF: {
                const message = JSON.parse(new TextDecoder().decode(event.data));
                enqueueSnackbar(stringFormat(t("It was reduced"), message?.nameRemovePin as string), {
                    variant: "info",
                    anchorOrigin: {horizontal: "center", vertical: "top"}
                });
                dispatch(setConnectionSetPin(null));
                dispatch(removeAllIsPin());
                break;
            }
            case LabelDataChannel.LABEL_COUNT_DOWN: {
                const message = JSON.parse(new TextDecoder().decode(event.data));
                const countDown = Number.parseInt(message.countdown);
                const isStopBreakoutRoom = message.isStopBreakoutRoom;
                if (Number.isInteger(countDown)) {
                    if (!isStopBreakoutRoom) {
                        if (countDown === -1) {
                            enqueueSnackbar(stringFormat(t("The timer was canceled"), message.nameSetTime as string), {
                                variant: "info",
                                anchorOrigin: {horizontal: "center", vertical: "top"}
                            });
                        } else if (!countdownValue && countDown !== -1) {
                            enqueueSnackbar(stringFormat(t("A timer has been set"), message.nameSetTime as string), {
                                variant: "info",
                                anchorOrigin: {horizontal: "center", vertical: "top"}
                            });
                        }
                    }
                    dispatch(setCountdownValue(countDown));
                    dispatch(setNameSetCountdown(message.nameSetTime));
                }
                break;
            }
            case LabelDataChannel.LABEL_SHOW_PARTICLES: {
                const particles = JSON.parse(new TextDecoder().decode(event.data)) as Particles;
                switch (particles.type) {
                    case ParticlesType.SNOW:
                        dispatch(setSnowParticles(particles));
                        break;
                    case ParticlesType.FIREWORKS:
                        dispatch(setFireworksParticles(particles));
                        break;
                    case ParticlesType.EMOJI:
                        dispatch(setEmojiParticles(particles));
                        break;
                }
                const name = particles.name;
                if (name) {
                    const emojiType = particles.emojiType || particles.type || "";
                    const enqueueSnackbarContent = <span className="emoji-snackbar" dangerouslySetInnerHTML={{__html: stringFormat(t("name sent a reaction"), name, `<img src="/img/emoji/${emojiType}.gif"  alt="${emojiType}"/>`)}}></span>;
                    enqueueSnackbar(enqueueSnackbarContent,
                        {
                            variant: "success",
                            autoHideDuration: 20000,
                            anchorOrigin: {horizontal: "center", vertical: "top"}
                        });
                }
                break;
            }
        }
    });

    connectSora.on("signaling", function (event) {
        if (event.data?.metadata?.name && localMediaStream?.id && event.data.connection_id) {
            dispatch(removeRemoteConnection(localMediaStream.id));
            const connection: RemoteConnection = {
                name: YOU_JP,
                imageUrl: event.data?.metadata?.image_url,
                mediaStreamId: localMediaStream.id,
                isShareScreen: false,
                isPin: false,
                isMine: true,
                realName: event.data?.metadata?.name,
                userId: userId !== undefined ? userId : null,
                speakingTime: 0,
                isMeetingOwner: event.data?.metadata?.is_owner,
                isRecvonly: connectType === RECVONLY,
                networkStatus: null,
                connectionId: event.data.connection_id,
                allow_user_access: event.data?.metadata?.allow_user_access,
                allow_guest_access: event.data?.metadata?.allow_guest_access,
                breakoutRoom: event.data?.metadata?.breakout_room,
                isMirroringWebcam
            };
            dispatch(addRemoteConnection(connection));
            dispatch(setMyConnectionId(event.data.connection_id));
            let nameFakeVideoTrack = "";
            if (event.data?.metadata?.name) {
                nameFakeVideoTrack = event.data?.metadata?.name;
            }
            window.nameFakeVideoTrack = nameFakeVideoTrack;
            dispatch(setMeetingOwner(event.data?.metadata?.is_owner === true));
        }
    });

    connectSora.on("push", function (event) {
        if (event?.data?.type) {
            switch (event.data.type) {
                case "request_access": {
                    const connection = remoteConnection.find(connection => connection.isMine);
                    if (connection) {
                        const data = event.data as {
                            name: string
                            clientId: string
                            userId: string
                        };
                        if (isMeetingOwner) {
                            showRequestAccess(data);
                        } else if (authenticated && data.userId && roomDetail?.allow_user_access === ALLOW_ACCESS_TYPE.user_allow) {
                            showRequestAccess(data);
                        } else if (authenticated && !data.userId && roomDetail?.allow_guest_access === ALLOW_ACCESS_TYPE.user_allow) {
                            showRequestAccess(data);
                        }
                    }
                    break;
                }
                case "signaling_notify_metadata_ext": {
                    if (event.data.action === "PutMetadataItem" && event?.data?.connection_id) {
                        const connection = remoteConnection.find(connection => connection.connectionId === event.data.connection_id);
                        if (connection) {
                            // edit name
                            if (event.data.key === "name") {
                                const connectionEdit: RemoteConnection = {
                                    ...connection,
                                    realName: event.data.value as string
                                };
                                dispatch(updateRemoteConnection(connectionEdit));
                            }
                            if (event.data.key === "speakingTime") {
                                dispatch(setSpeakingTime({
                                    connectionId: event.data.connection_id as string,
                                    speakingTime: event.data.value as number
                                }));
                            }

                            if (event.data.key === "network") {
                                const network = event.data.value as {networkStatus: number };
                                const connectionEdit: RemoteConnection = {
                                    ...connection,
                                    // network: network.network,
                                    networkStatus: network.networkStatus
                                };
                                dispatch(updateRemoteConnection(connectionEdit));
                                networkStatusLog(event.data.connection_id as string, network.networkStatus);
                            }

                            if (event.data.key === "is_owner") {
                                const connectionEdit: RemoteConnection = {
                                    ...connection,
                                    isMeetingOwner: event.data.value as boolean
                                };
                                dispatch(updateRemoteConnection(connectionEdit));
                            }

                            if (event.data.key === "isMirroringWebcam") {
                                const connectionEdit: RemoteConnection = {
                                    ...connection,
                                    isMirroringWebcam: event.data.value as boolean
                                };
                                dispatch(updateRemoteConnection(connectionEdit));
                            }
                        }
                    }
                    break;
                }
                case "audio_streaming_result": {
                    const setAudioStreamingResult = async () => {
                        const connectionId = event.data.connection_id as string;
                        const result = event.data.result as AudioStreamingResult;
                        if (!result.message) return;
                        const resultsId = result.results_id;
                        const isFinal = result.is_final;
                        const sourceLang = result.language;

                        const connection = remoteConnection.find(connection => connection.connectionId === connectionId);
                        const indexBackground = remoteConnection.findIndex(connection => connection.connectionId === connectionId);

                        if (result.message && connection) {
                            const contentMessage = decodeHTMLandURL(result.message);
                            let translate;
                            let translateTranslatedSentences;
                            const translateResult = result.translate?.find(item => item.language === subtitleTranslationLanguage);
                            if (subtitleTranslationLanguage !== sourceLang && translateResult) {
                                try {
                                    translate = decodeHTMLandURL(translateResult.translated_text);
                                    if (isTranslateTranslatedSentences) {
                                        const params = new URLSearchParams();
                                        params.append("text", encodeURIComponent(translate));
                                        params.append("target_lang", sourceLang);
                                        params.append("source_lang", subtitleTranslationLanguage);
                                        params.append("channel_id", channelId);
                                        params.append("session_id", sessionId);
                                        const responseTranslate = await axios.post(`${process.env.REACT_APP_SESSION_API || ""}/google_translate?${params.toString()}`);
                                        translateTranslatedSentences = decodeHTMLandURL(responseTranslate.data.translated_text);
                                    }
                                } catch (error) {
                                    console.log(error);
                                }
                            }
                            const transcriptDeepGram: TranscriptDeepGram = {
                                resultsId,
                                connectionId,
                                name: connection.realName,
                                transcript: contentMessage,
                                isFinal,
                                isMine: connection.isMine,
                                avatarUrl: connection.imageUrl,
                                translate,
                                indexBackground,
                                translateTranslatedSentences,
                                language: result.language
                            };
                            dispatch(setContentSubTitle(transcriptDeepGram));
                        }
                    };
                    setAudioStreamingResult().then(null).catch(error => {
                        console.log(error);
                    });
                    break;
                }
                case "request_access_client": {
                    const { clientId } = event.data as {
                        clientId: string
                    };
                    closeSnackbar(clientId);
                    break;
                }
                case "update_room_details": {
                    const { owners} = event.data as {
                        owners: string[]
                        connectionId: string
                    };
                    dispatch(getRoomDetail()).then().catch(null);
                    let isMeetingOwnerNew = false;
                    if (userId && owners.includes(userId)) {
                        isMeetingOwnerNew = true;
                    }
                    dispatch(setMeetingOwner(isMeetingOwnerNew));
                    if (isMeetingOwner !== isMeetingOwnerNew && connectSora.connectionId) {
                        putSignalingNotifyMetadata(channelId, connectSora.connectionId, "is_owner", isMeetingOwnerNew).then(null).catch(error => {
                            console.log(error);
                        });
                    }
                    break;
                }
                case "create_breakout_room": {
                    fetchListRooms(channelId).then((rooms) => {
                        dispatch(setBreakoutRooms(rooms));
                    }).catch((err) => {
                        console.log(err);
                    });
                    break;
                }
                case "join_breakout_room": {
                    const { connectionId, roomName } = event.data as {
                        connectionId: string
                        roomName: string
                    };
                    const joinBreakoutRoom = {
                        connectionId,
                        roomName
                    };
                    joinBreakoutRoomList.push(joinBreakoutRoom);
                    break;
                }
                case "setting_breakout_room": {
                    const { connectionId, breakoutRoom } = event.data as {
                        connectionId: string
                        breakoutRoom: string
                    };
                    const connection = remoteConnection.find(connection => connection.connectionId === connectionId);
                    if (connection) {
                        const connectionEdit: RemoteConnection = {
                            ...connection,
                            breakoutRoom
                        };
                        dispatch(updateRemoteConnection(connectionEdit));
                        if (connection.isMine) {
                            putSignalingNotifyMetadata(channelId, connectionId, "breakout_room", breakoutRoom || "").then(null)
                                .catch(err => {
                                    console.log(err);
                                });
                        }
                    }
                    break;
                }
                case "start_join_breakout_room": {
                    clearTimeout(timeoutOneMinuteLeftInTheBreakoutRooms);
                    const { expirationTime } = event.data as {
                        expirationTime: number
                    };
                    dispatch(setBreakoutRoomStarted(true));
                    dispatch(setRemoteConnectionBreakoutRoomStarted(remoteConnection));
                    if (expirationTime) {
                        dispatch(setBreakoutRoomExpirationTime(secondsToMs(expirationTime)));
                        dispatch(setCountdownValue(expirationTime));
                        const expirationTimeMs = expirationTime * 1000;
                        clearTimeout(timeoutBreakoutRoomStarted);
                        clearTimeout(timeoutTimerSettingBlinker);
                        // Turn off the countdown timer when time is up
                        timeoutBreakoutRoomStarted = setTimeout(() => {
                            sendMessageCountdownUpdate(-1, false, true);
                            dispatch(setBreakoutRoomStarted(false));
                        }, expirationTimeMs);

                        // When the timer reaches one minute, the countdown timer will flash
                        const oneMinuteMs = 60 * 1000;
                        if (expirationTimeMs > oneMinuteMs) {
                            timeoutOneMinuteLeftInTheBreakoutRooms = setTimeout(() => {
                                dispatch(setOneMinuteLeftInTheBreakoutRooms(true));
                                timeoutTimerSettingBlinker = setTimeout(() => {
                                    dispatch(setOneMinuteLeftInTheBreakoutRooms(false));
                                }, oneMinuteMs);
                            }, expirationTimeMs - oneMinuteMs);
                        }

                        // When the timer is up, return to the main room.
                        clearTimeout(timeoutExpirationTimeBreakoutRoom);
                        timeoutExpirationTimeBreakoutRoom = setTimeout(() => {
                            dispatch(setBreakoutRoomStarted(false));
                            dispatch(setJoinBreakoutRoom(false));
                            dispatch(setBreakoutRoom({
                                channelId,
                                roomName: mainRoomName
                            }));
                        }, expirationTimeMs);
                    }

                    const connection = remoteConnection.find(connection => connection.isMine);
                    if (connection?.breakoutRoom && !isMeetingOwner) {
                        const breakoutRoom = breakoutRooms.find(breakoutRoom => breakoutRoom.channel_id === connection.breakoutRoom);
                        if (breakoutRoom) {
                            const data = {
                                channel_id: channelId,
                                data: {
                                    type: "join_breakout_room",
                                    connectionId: connection.connectionId,
                                    roomName: `${mainRoomName} : ${breakoutRoom.name}`
                                }
                            };
                            pushChannel(data).then(null).catch(null);

                            const signalingNotifyMetadata = {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-expect-error
                                ...options.signalingNotifyMetadata,
                                breakout_room: connection.breakoutRoom
                            };

                            isNoDisconnectHandlingSoraRef.current = true;
                            setOptions({
                                ...options,
                                signalingNotifyMetadata
                            });

                            // Wait for options to set
                            setTimeout(() => {
                                dispatch(setBreakoutRoom({
                                    channelId: breakoutRoom.channel_id,
                                    roomName: `${mainRoomName} : ${breakoutRoom.name}`
                                }));
                            }, 1000);
                        }
                    }

                    if (isMeetingOwner) {
                        setOpenBreakoutRoomStartDialog(true);
                    }
                    break;
                }
                case "turn_off_the_mic": {
                    const { connectionId } = event.data as {
                        connectionId: string
                    };
                    if (connectionId === connectSora.connectionId) {
                        handleClickMicIcon().then(null).catch(null);
                    }
                    break;
                }
                case "discontinue_breakout_room": {
                    clearTimeout(timeoutBreakoutRoomStarted);
                    clearTimeout(timeoutOneMinuteLeftInTheBreakoutRooms);
                    clearTimeout(timeoutTimerSettingBlinker);
                    dispatch(setCountdownValue(0));
                    sendMessageCountdownUpdate(-1, false, true);
                    dispatch(setBreakoutRoomStarted(false));
                    dispatch(setOneMinuteLeftInTheBreakoutRooms(false));
                    dispatch(setBreakoutRoom({
                        channelId,
                        roomName: mainRoomName
                    }));
                    break;
                }
                case "disconnect_connection": {
                    dispatch(setMeeting(false));
                    dispatch(resetDataMeeting({isJoinBreakoutRoom: false}));
                    break;
                }
                case "update_language_subtitle": {
                    const { clientId } = event.data as {
                        clientId: string
                    };
                    clientIdUpdateUpdateLanguageSubtitle.push(clientId);
                    break;
                }
                case "translate_share_screen": {
                    const { connectionId, imageContent } = event.data as {
                        connectionId: string
                        imageContent: string
                    };
                    let languageTranslate = subtitleTranslationLanguage;
                    if (subtitleTranslationLanguage === "0") {
                        languageTranslate = subtitleLanguage;
                    }

                    const connection = remoteConnection.find(connection => connection.connectionId === connectionId);
                    const indexBackground = remoteConnection.findIndex(connection => connection.connectionId === connectionId);
                    if (!connection) return;

                    const data = {
                        languageTranslate,
                        imageContent
                    };

                    let keyEnqueueSnackbar: SnackbarKey;
                    if (connection.isMine) {
                        keyEnqueueSnackbar = enqueueSnackbar(t("Translating image"), { autoHideDuration: null });
                    }
                    axios.post(`${process.env.REACT_APP_SVLAC_ENDPOINT || ""}/TranslateSlide`, data,
                        {
                            responseType: "blob"
                    }).then(response => {
                        const imageUrl = URL.createObjectURL(response.data);
                        const transcriptDeepGram: TranscriptDeepGram = {
                            resultsId: new Date().getTime().toString(),
                            connectionId,
                            name: connection.realName,
                            transcript: t("The shared screen has been translated."),
                            isFinal: true,
                            isMine: connection.isMine,
                            avatarUrl: connection.imageUrl,
                            translate: "",
                            indexBackground,
                            translateTranslatedSentences: "",
                            language: languageTranslate,
                            translateShareScreen: imageUrl
                        };
                        dispatch(setContentSubTitle(transcriptDeepGram));
                    }).catch(err => {
                        console.log(err);
                        if (connection.isMine) {
                            handleErrorMessage(err, enqueueSnackbar);
                        }
                    }).finally(() => {
                        if (keyEnqueueSnackbar) {
                            closeSnackbar(keyEnqueueSnackbar);
                        }
                    });
                    break;
                }
                case "connection_disconnect": {
                    const { connectionId } = event.data as {
                        connectionId: string
                    };
                    connectionDisconnectRef.current.push(connectionId);
                    break;
                }
            }
        }
    });

    connectSora.on("disconnect", function (event) {
        if (isNoDisconnectHandlingSoraRef.current) {
            isNoDisconnectHandlingSoraRef.current = false;
            return;
        }
        // Do not reconnect when using breakout room
        if (event.type === "abend" && !enqueueSnackbarNetworkKey && breakoutRoom?.channelId === connectSora.channelId) {
            reconnectSora().then(null).catch(null);
        }
    });

    sendrecvShareScreen.on("push", function (event) {
        if (event?.data?.type) {
            switch (event.data.type) {
                case "disconnect_connection": {
                    dispatch(setIsMineShare(false));
                    screenStream?.getTracks().forEach(track => {
                        track.stop();
                    });
                    setScreenStream(undefined);
                    break;
                }
            }
        }
    });

    const reconnectSora = async () => {
        if (myConnectionId) {
            try {
                setConnectionIdReconnectSora(myConnectionId);
                const data = {
                    channel_id: channelId,
                    connection_id: myConnectionId
                };
                await axios.post(`${process.env.REACT_APP_WEBRTC_API || ""}`, data, {
                    headers: {
                        "x-sora-target": "Sora_20151104.DisconnectConnection"
                    }
                });
                networkStatusLog(myConnectionId, -1);
            } catch (err) {
                console.log(err);
            }
        }
        if (localMediaStream) {
            let connected = false;
            const key = enqueueSnackbar(t("A network disconnection has been detected. Please wait while we try to reconnect."), { variant: "info", autoHideDuration: null });
            for (let i = 1; i <= 10; i++) {
                try {
                    dispatch(resetDataMeeting({isJoinBreakoutRoom: false}));
                    await connectSora.connect(localMediaStream);
                    connected = true;

                    // reconnect share
                    if (isMineShare && screenStream) {
                        await sendrecvShareScreen.disconnect();
                        await sendrecvShareScreen.connect(screenStream);
                        setScreenStream(screenStream);
                        dispatch(setIsMineShare(true));
                        if (sendrecvShareScreen.connectionId) {
                            dispatch(setIdConnectShared(sendrecvShareScreen.connectionId));
                            dispatch(removeAllIsPin());
                            dispatch(setIsPin(sendrecvShareScreen.connectionId));
                            startPinAll(sendrecvShareScreen.connectionId);
                        }
                    }
                } catch (e) {
                    console.log(e);
                    connected = false;
                }
                if (connected) {
                    closeSnackbar(key);
                    break;
                }
                await new Promise((resolve) => setTimeout(resolve, i * 500 + 500));
            }
        }
    };

    const allowAccessMeeting = async (clientId: string, allow: boolean) => {
        if (userId && authToken) {
            closeSnackbar(clientId);
            const data = {
                user_name: clientId,
                client_id: clientId,
                allowed: allow
            };
            await axios.post(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/room/${channelId}/guests?u=${userId}&a=${authToken}`, data);

            const dataPushChannel = {
                channel_id: channelId,
                data: {
                    type: "request_access_client",
                    clientId
                }
            };
            await pushChannel(dataPushChannel);
        }
    };

    const showRequestAccess = (data: RequestAccessData) => {
        const {name, clientId, userId} = data;
        if (clientId && (name || userId)) {
            const snackbar = (
                <div className="request-join-mtg">
                    <span>{stringFormat(t("request-to-enter-the-room-has-arrived"), `${name || ""}${userId || ""}`)}</span>
                    <ButtonUpdateRoom onClick={() => { allowAccessMeeting(clientId, true).then(null).catch(null); }}>{t("allow-entry")}</ButtonUpdateRoom>
                    <ButtonNotAllowed onClick={() => { allowAccessMeeting(clientId, false).then(null).catch(null); }}>{t("not-allowed")}</ButtonNotAllowed>
                </div>
            );
            const enqueueSnackbarKey = enqueueSnackbar(snackbar, {key: clientId, autoHideDuration: null});
            playSoundRequest();
            setTimeout(() => {
                closeSnackbar(enqueueSnackbarKey);
            }, 30000);
        }
    };

    const addConnection = (metaData?: JSONType, authNMetaData?: JSONType, authZMetaData?: JSONType, connectionId?: string, clientId?: string, isNewConnection?: boolean) => {
        if (remoteConnection.some(connection => connection.connectionId === connectionId)) {
            return;
        }

        if (connectionId === connectionIdReconnectSora) {
            return;
        }
        const metadata = getMetadata(metaData, authNMetaData, authZMetaData);

        if (authNMetaData) {
            for (const [key, value] of Object.entries(authNMetaData)) {
                metadataToObject(key, value, metadata);
            }
        }

        const connection: RemoteConnection = {
            name: metadata.name,
            imageUrl: metadata.imageUrl,
            isShareScreen: metadata.isShareScreen,
            mediaStreamId: connectionId,
            isPin: false,
            isMine: false,
            realName: metadata.name,
            userId: metadata.userId,
            speakingTime: metadata.speakingTime,
            isMeetingOwner: metadata.isMeetingOwner,
            isRecvonly: metadata.isRecvonly,
            // network: metadata.network,
            networkStatus: metadata.networkStatus,
            connectionId,
            allow_user_access: null,
            allow_guest_access: null,
            isMirroringWebcam: metadata.isMirroringWebcam,
            breakoutRoom: metadata.breakout_room
        };
        dispatch(addRemoteConnection(connection));
        if (connectionId && metadata.isShareScreen) {
            dispatch(setIsPin(connectionId));
        }

        const isUpdateUpdateLanguageSubtitle = clientIdUpdateUpdateLanguageSubtitle.some(item => item === clientId);
        if (isUpdateUpdateLanguageSubtitle) {
            clientIdUpdateUpdateLanguageSubtitle = clientIdUpdateUpdateLanguageSubtitle.filter(item => item !== clientId);
        }
        if (isNewConnection && !metadata.isConnectSoraOwnerJoinBreakoutRoom && !isUpdateUpdateLanguageSubtitle) {
            if (metadata.isShareScreen) {
                enqueueSnackbar(stringFormat(t("you have shared your screen"), connection.realName), {variant: "success" });
            } else {
                enqueueSnackbar(stringFormat(t("entered the room"), connection.realName), {variant: "success" });
                playSound();
            }
        }
        if (metadata.isShareScreen) {
            dispatch(setShowListUser(true));
        }
    };

    const getMetadata = (metaData?: JSONType, authNMetaData?: JSONType, authZMetaData?: JSONType) => {
        const metadata: Metadata = {
            name: "",
            imageUrl: "",
            isShareScreen: false,
            userId: "",
            isMeetingOwner: false,
            isRecvonly: false,
            network: null,
            networkStatus: null,
            speakingTime: 0,
            allow_user_access: null,
            allow_guest_access: null,
            isConnectSoraOwnerJoinBreakoutRoom: false
        };

        if (authNMetaData) {
            for (const [key, value] of Object.entries(authNMetaData)) {
                metadataToObject(key, value, metadata);
            }
        }

        if (authZMetaData) {
            for (const [key, value] of Object.entries(authZMetaData)) {
                metadataToObject(key, value, metadata);
            }
        }

        if (metaData) {
            for (const [key, value] of Object.entries(metaData)) {
                metadataToObject(key, value, metadata);
            }
        }
        return metadata;
    };

    const metadataToObject = (key: string, value: any, metadata: Metadata) => {
        if (key === "name") {
            metadata.name = value;
        }
        if (key === "image_url") {
            metadata.imageUrl = value;
        }
        if (key === "is_owner") {
            metadata.isMeetingOwner = value;
        }
        if (key === "isShareScreen") {
            metadata.isShareScreen = value;
        }
        if (key === "userId") {
            metadata.userId = value;
        }
        if (key === "isRecvonly") {
            metadata.isRecvonly = value;
        }
        if (key === "network") {
            metadata.network = value?.network;
            metadata.networkStatus = value?.networkStatus || null;
        }
        if (key === "speakingTime") {
            metadata.speakingTime = value;
        }
        if (key === "allow_user_access") {
            metadata.allow_user_access = value;
        }
        if (key === "allow_guest_access") {
            metadata.allow_guest_access = value;
        }
        if (key === "isMirroringWebcam") {
            metadata.isMirroringWebcam = value;
        }
        if (key === "isConnectSoraOwnerJoinBreakoutRoom") {
            metadata.isConnectSoraOwnerJoinBreakoutRoom = value;
        }
        if (key === "breakout_room") {
            metadata.breakout_room = value;
        }
    };

    const callEnd = async () => {
        await sendMessageWhenLeavingRoom();
        if (isMineShare) {
            screenStream?.getVideoTracks()[0].stop();
            setScreenStream(undefined);
            await sendrecvShareScreen.disconnect();
        }
        await connectSora.disconnect();
        dispatch(setMeeting(false));
        dispatch(resetDataMeeting({isJoinBreakoutRoom: false}));
        dispatch(setIsMineShare(false));
        dispatch(setShowChat(false));
        dispatch(setSHowParticipantList(false));
        dispatch(setShowBreakoutRoom(false));
        const key = enqueueSnackbar(stringFormat(t("You have left X."), roomName), { variant: "info", autoHideDuration: null, anchorOrigin: {horizontal: "center", vertical: "top"} });
        setKeyEnqueueSnackbarLeftRoom(key);
    };

    const shareScreen = async () => {
        // get display media
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
            audio: true,
            video: {
                width: {ideal: 1920, max: 1920},
                height: {ideal: 1080, max: 1080},
                // @ts-expect-error because this is set by the application to identify to the user agent the type of
                // display surface (window, browser, or monitor) preferred by the application.
                displaySurface: "window"
            }
        });
        screenStream.getAudioTracks().forEach(track => {
            track.enabled = isScreenShareAudio;
        });
        await sendrecvShareScreen.connect(screenStream);
        setScreenStream(screenStream);
        dispatch(setIsMineShare(true));
        if (sendrecvShareScreen.connectionId) {
            dispatch(setIdConnectShared(sendrecvShareScreen.connectionId));
        }

        screenStream.getVideoTracks()[0].addEventListener("ended", () => {
            (async () => {
                await sendrecvShareScreen.disconnect();
                dispatch(setIsMineShare(false));
            })().catch(null);
        });
    };
    useEffect(() => {
        if (screenStream) {
             screenStream.getAudioTracks().forEach(track => {
                track.enabled = isScreenShareAudio;
            });
        }
    }, [isScreenShareAudio]);

    const stopShare = async () => {
        if (isMineShare) {
            screenStream?.getTracks().forEach(track => {
                track.stop();
            });
            setScreenStream(undefined);
            await sendrecvShareScreen.disconnect();
        }
        dispatch(setIsMineShare(false));
    };

    const stopDevice = async (mediaStream: MediaStream) => {
        await connectSora.stopVideoTrack(mediaStream);
    };

    // mic on || off
    const handleClickMicIcon = async () => {
        if (micros.length > 0 && connectType === SENDRECV) {
            const isMic = !isMicroOn;
            isClickMic = true;
            dispatch(setMicroOn(isMic));
            localStorage.setItem(IS_MIC, String(isMic));
            if (localMediaStream !== null) {
                sendMessageOnOffMicCamara(isMic, isCameraOn);
            }
        }
    };

    const sendMessageOnOffMicCamara = (isMicroOn: boolean, isCameraOn: boolean) => {
        if (connectSora.connectionId !== null) {
            const message = {
                connectionId: connectSora.connectionId,
                isMicroOn,
                isCameraOn
            };
            // send message mix on or off
            connectSora.sendMessage(LabelDataChannel.LABEL_ON_OFF_MIC_CAMERA, new TextEncoder().encode(JSON.stringify(message)));
        }
    };

    // camera on || off
    const handleClickCameraIcon = async () => {
        if (cameras.length > 0 && connectType === SENDRECV) {
            const isCamera = !isCameraOn;
            if (localMediaStream) {
                localMediaStream.getVideoTracks().forEach((track) => {
                    track.stop();
                });
            }
            dispatch(setCameraOn(isCamera));
            localStorage.setItem(IS_CAMERA, String(isCamera));
            if (localMediaStream !== null) {
                sendMessageOnOffMicCamara(isMicroOn, isCamera);
            }
        }
    };

    const setVirtualBackground = async (mediaStream: MediaStream) => {
        clearTimeout(timeoutUpdateInitializingStream.current);
        try {
            if (mediaStream?.getVideoTracks()?.length > 0 && processor) {
                if (processor.isProcessing()) {
                    processor.stopProcessing();
                }
                const track = mediaStream.getVideoTracks()[0];

                if (track !== undefined && backgroundImage !== undefined) { // background image
                    const virtualBackgroundImage = new Image();
                    virtualBackgroundImage.crossOrigin = "anonymous";
                    virtualBackgroundImage.src = `${backgroundImage}?${new Date().getTime()}`;
                    const options = {
                        backgroundImage: virtualBackgroundImage
                    };
                    // start virtual background processing
                    const processedTrack = await processor.startProcessing(track, options);
                    await updateStreamVirtualBackground(mediaStream, processedTrack);
                } else if (track !== undefined && blurRadius !== undefined) { // background blur
                    if (blurRadius === 0) {
                        await updateStreamVirtualBackground(mediaStream, track);
                    } else {
                        const options = {
                            blurRadius
                        };
                        // start virtual background processing
                        const processedTrack = await processor.startProcessing(track, options);
                        await updateStreamVirtualBackground(mediaStream, processedTrack);
                    }
                }
            }
        } catch (err) {
            console.error(`you got an error: ${err as string}`);
        }
        return mediaStream;
    };

    const updateStreamVirtualBackground = async (mediaStream: MediaStream, processedTrack: MediaStreamVideoTrack) => {
        mediaStream.getVideoTracks().forEach(track => {
            mediaStream.removeTrack(track);
        });
        mediaStream.addTrack(processedTrack);
    };

    const updateLocalMediaStream = async () => {
        try {
            clearTimeout(timeoutUpdateInitializingStream.current);
            dispatch(setInitializingStream(true));
            if (processor?.isProcessing()) {
                const originalTrack = processor.getOriginalTrack();
                if (originalTrack) {
                    originalTrack.stop();
                }
                processor.stopProcessing();
            }

            const mediaStream = await getUserMedia();
            if (!isCameraOn) {
                const videoTrack = createFakeVideoTrack();
                mediaStream.getVideoTracks().forEach(track => {
                    mediaStream.removeTrack(track);
                });
                mediaStream.addTrack(videoTrack);
            }

            mediaStream.getTracks().forEach((track) => {
                if (!connectSora?.pc) {
                    return;
                }
                const sender = connectSora.pc.getSenders().find((s) => {
                    if (!s.track) {
                        return false;
                    }
                    return s.track.kind === track.kind;
                });
                if (sender) {
                    sender.replaceTrack(track).then(null).catch(null);
                } else if (localMediaStream) {
                    if (track.kind === "video") {
                        connectSora.replaceVideoTrack(localMediaStream, track).then(null).catch(null);
                    } else if (track.kind === "audio") {
                        connectSora.replaceAudioTrack(localMediaStream, track).then(null).catch(null);
                    }
                }
            });
            dispatch(setLocalMediaStream(mediaStream));
        } finally {
            updateInitializingStream();
        }
    };

     const createFakeVideoTrack = () => {
        // Create a canvas element for drawing video frames
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the canvas dimensions to match the video frame size
        const width = 640; // Width of the video frame
        const height = 480; // Height of the video frame
        canvas.width = width;
        canvas.height = height;

        // Create a `MediaStream` object
        const stream = canvas.captureStream(); // Capture the canvas as a media stream

        // Get the video track from the stream
        const videoTrack = stream.getVideoTracks()[0];

        // Generate fake video frames
        function drawFakeVideoFrame () {
            if (ctx) {
                ctx.fillStyle = randomColor;
                ctx.fillRect(0, 0, width, height);

                // Add text to the frame (centered)
                ctx.fillStyle = "white";
                ctx.font = "50px Noto Sans JP";
                const text = window.nameFakeVideoTrack ? window.nameFakeVideoTrack : "";
                const textWidth = ctx.measureText(text).width;
                const x = (width - textWidth) / 2; // Calculate the x position for centering
                const y = height / 2; // Center vertically

                ctx.fillText(text, x, y);

                requestAnimationFrame(drawFakeVideoFrame); // Keep drawing frames
            }
        }

        // Start generating fake video frames
        drawFakeVideoFrame();
        return videoTrack;
    };

    const handleCloseInputNameDialog = (name: string) => {
        let clientId = Date.now().toString();
        const clientIdSessionStorage = window.sessionStorage.getItem("clientId");
        if (clientIdSessionStorage) {
            clientId = clientIdSessionStorage;
        } else {
            window.sessionStorage.setItem("clientId", clientId);
        }
        dispatch(setOpenInputNameDialog(false));
        dispatch(updateMetadata({
            name
        }));
        setOptions({
            ...options,
            clientId
        });
        setOptionsShareScreen({
            ...optionsShareScreen,
            clientId
        });
        isConnectSora.current = true;
    };

    const setUserIdAndAuthTokenForMetadata = () => {
        if (userId && authToken) {
            dispatch(updateMetadata({
                u: userId,
                a: authToken
            }));
            setOptions({
                ...options,
                clientId: userId
            });
            setOptionsShareScreen({
                ...optionsShareScreen,
                clientId: userId
            });
        }
    };

    const sendMessageRecording = (isRecording: boolean) => {
        if (connectSora.connectionId !== null && localMediaStream) {
            const connection = remoteConnection.find(connection => connection.mediaStreamId === localMediaStream.id);
            const message = {
                isRecording,
                name: connection?.realName
            };
            connectSora.sendMessage(LabelDataChannel.LABEL_RECORDING, new TextEncoder().encode(JSON.stringify(message)));
        }
    };

    const sendMessageChat = () => {
        sendMessage();
    };

    const handleKeyUp = () => {
        sendMessage();
    };

    const sendMessage = () => {
        if (localMediaStream && textMessage.trim()) {
            const connection = remoteConnection.find(connection => connection.mediaStreamId === localMediaStream.id);

            const messageChat: MessageChat = {
                content: textMessage.trim(),
                senderImageUrl: connection?.imageUrl,
                time: new Date().getTime(),
                senderName: connection?.name,
                senderId: connectSora.connectionId || undefined,
                locationId: localMediaStream.id,
                receiverId: objectChat
            };

            dispatch(setMessageChats(messageChat));

            const message = {
                ...messageChat,
                senderName: connection?.realName
            };

            connectSora.sendMessage(LabelDataChannel.LABEL_MESSAGE_CHAT, new TextEncoder().encode(JSON.stringify(message)));
            dispatch(setTextMessage(""));

            if (messageChat.receiverId === "all") {
                const params = {
                    channel_id: channelId,
                    session_id: sessionId,
                    client_id: connectSora.clientId,
                    name: connection?.realName,
                    chat: messageChat.content
                };
                axios.post(`${process.env.REACT_APP_SESSION_API || ""}/room_chat`, params)
                    .then(null)
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    };

    const startPinAll = (connectionId: string) => {
        if (localMediaStream && connectionId === localMediaStream.id && connectSora.connectionId) {
            connectionId = connectSora.connectionId;
        }
        // connection set Pin
        const connectionSetPin = remoteConnection.find(connection => connection.mediaStreamId === localMediaStream?.id);
        const message = {
            // connected gim
            connectionId,
            connectionSetPin
        };
        connectSora.sendMessage(LabelDataChannel.LABEL_PIN_ALL_ON, new TextEncoder().encode(JSON.stringify(message)));
        dispatch(setConnectionIdPin(connectionId));

        if (connectionSetPin) {
            dispatch(setConnectionSetPin(connectionSetPin));
        }
    };

    const removePinAll = () => {
        const nameRemovePin = remoteConnection.find(connection => connection.mediaStreamId === localMediaStream?.id)?.realName;
        const message = {
            nameRemovePin
        };
        dispatch(setConnectionSetPin(null));
        connectSora.sendMessage(LabelDataChannel.LABEL_PIN_ALL_OFF, new TextEncoder().encode(JSON.stringify(message)));
    };

    const sendMessageCountdownUpdate = (countdown: number, isClick: boolean, isStopBreakoutRoom?: boolean) => {
        const nameSetTime = isClick ? remoteConnection.find(connection => connection.mediaStreamId === localMediaStream?.id)?.realName : nameSetCountdown;
        const message = {
            countdown: countdown.toString(),
            nameSetTime,
            isStopBreakoutRoom
        };
        connectSora.sendMessage(LabelDataChannel.LABEL_COUNT_DOWN, new TextEncoder().encode(JSON.stringify(message)));
        if (nameSetTime) {
            dispatch(setNameSetCountdown(nameSetTime));
        }
    };

    const sendMessageParticles = (particles: Particles) => {
        connectSora.sendMessage(LabelDataChannel.LABEL_SHOW_PARTICLES, new TextEncoder().encode(JSON.stringify(particles)));
    };

    useEffect(() => {
        if (localMediaStream && connectSora.connectionId) {
            putSignalingNotifyMetadata(channelId, connectSora.connectionId, "speakingTime", speakingTimeMine).then(null).catch(error => {
                console.log(error);
            });
            const params = {
                channel_id: channelId,
                session_id: sessionId,
                client_id: connectSora.clientId,
                speaking_time: speakingTimeMine
            };
            axios.post(`${process.env.REACT_APP_SESSION_API || ""}/speaking_time`, params)
                .then(null)
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [speakingTimeMine]);

    useEffect(() => {
        // get speaking_time in database
        if (sessionId && connectSora.clientId && isMeeting) {
             axios.get<GetSpeakingTimeResponse>(`${process.env.REACT_APP_SESSION_API || ""}/speaking_time?channel_id=${channelId}&session_id=${sessionId}&client_id=${connectSora.clientId}`)
                .then((res) => {
                    if (res.data.speaking_time) {
                        dispatch(setSpeakingTimeMine(res.data.speaking_time));
                    }
                })
                .catch(null);
        }
    }, [sessionId, connectSora.clientId, isMeeting]);

    useEffect(() => {
        if (localMediaStream && connectSora.connectionId) {
            putSignalingNotifyMetadata(channelId, connectSora.connectionId, "isMirroringWebcam", isMirroringWebcam).then(null).catch(error => {
                console.log(error);
            });
        }
    }, [isMirroringWebcam]);

    const noiseSuppression = async (mediaStream: MediaStream) => {
        clearTimeout(timeoutUpdateInitializingStream.current);
        try {
            if (mediaStream?.getAudioTracks()?.length > 0 && noiseSuppressionProcessor) {
                if (noiseSuppressionProcessor.isProcessing()) {
                    noiseSuppressionProcessor.stopProcessing();
                }
                const track = mediaStream.getAudioTracks()[0];

                if (track !== undefined) {
                    const processedAudioTrack = await noiseSuppressionProcessor.startProcessing(track);
                    mediaStream.getAudioTracks().forEach(track => {
                        mediaStream.removeTrack(track);
                    });
                    mediaStream.addTrack(processedAudioTrack);
                }
            }
        } catch (err) {
            console.error(`you got an error: ${err as string}`);
        }
        return mediaStream;
    };

    useEffect(() => {
        const isLoginJoinMeeting = sessionStorage.getItem(LOGIN_JOIN_MEETING);
        if (redirect && !window.redirectParam && isLoginJoinMeeting) {
            dispatch(setJoinMeetingButtonDisabled(true));
            window.redirectParam = Boolean(redirect);
            setUserIdAndAuthTokenForMetadata();
            setTimeout(() => {
                setAutoConnectSora(true);
            }, 2000);
            sessionStorage.removeItem(LOGIN_JOIN_MEETING);
        }
    }, [redirect]);

    useEffect(() => {
        if (isAutoConnectSora) {
            connectSoraFunc().then(() => {
                dispatch(setJoinMeetingButtonDisabled(false));
                dispatch(setFirstLogin(true));
            }).catch((e) => {
                handelErrorConnectSora(e);
            });
        }
    }, [isAutoConnectSora]);

    const lightAdjustment = async (mediaStream: MediaStream) => {
        clearTimeout(timeoutUpdateInitializingStream.current);
        try {
            if (mediaStream?.getVideoTracks()?.length > 0 && lightAdjustmentProcessor) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                lightAdjustmentProcessor.stopProcessing();
                const track = mediaStream.getVideoTracks()[0];

                if (track !== undefined) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    const processedAudioTrack = await lightAdjustmentProcessor.startProcessing(track);
                    mediaStream.getVideoTracks().forEach(track => {
                        mediaStream.removeTrack(track);
                    });
                    mediaStream.addTrack(processedAudioTrack);
                }
            }
        } catch (err) {
            console.error(`you got an error: ${err as string}`);
        }
        return mediaStream;
    };

    useEffect(() => {
        if (lightAdjustmentProcessor) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            lightAdjustmentProcessor.setStrength(strengthLightAdjustment);
        }
    }, [strengthLightAdjustment]);

    const updateInitializingStream = () => {
        clearTimeout(timeoutUpdateInitializingStream.current);
        timeoutUpdateInitializingStream.current = setTimeout(() => {
            dispatch(setInitializingStream(false));
        }, 2000);
    };

    useEffect(() => {
        isNoDisconnectHandlingSoraRef.current = true;
        setTimeout(() => {
            isNoDisconnectHandlingSoraRef.current = false;
        }, 1000);

        const data = {
            channel_id: channelId,
            data: {
                type: "update_language_subtitle",
                clientId: connectSora.clientId
            }
        };
        pushChannel(data).then(() => {
            if (connectSora.clientId) {
                isConnectSora.current = true;
                setOptions({
                    ...options,
                    audioStreamingLanguageCode: subtitleLanguage,
                    clientId: connectSora.clientId
                });
                if (isMeeting) {
                    const keyEnqueueSnackbar = enqueueSnackbar(t("The speaker language has been changed"), { variant: "info" });
                    setKeyEnqueueSnackbarSubtitleLanguageChange(keyEnqueueSnackbar);
                    dispatch(handleSubtitleLanguageChange());
                }
            }
        }).catch(err => { console.log(err); });
    }, [subtitleLanguage]);

    const handleClickSubtitle = () => {
        if (!isSubTitle) {
            handleStartSubtitle(channelId);
        }
        dispatch(setIsSubTitle(!isSubTitle));
    };

    const isNarrow = () => {
        return isShowChat || isSHowParticipantList || isShowBreakoutRoom || isSubTitle;
    };

    const handleAddRoomReaction = (emoji: string) => {
        const connection = remoteConnection.find(connection => connection.isMine);
        if (connectSora.clientId && connection) {
            const params = {
                channel_id: channelId,
                session_id: sessionId,
                client_id: connectSora.clientId,
                reaction: emoji,
                name: connection.realName
            };
            axios.post(`${process.env.REACT_APP_SESSION_API || ""}/room_reaction`, params)
                .then(null)
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const networkStatusLog = (connectionId: string, networkStatus: number) => {
        const params = {
            channel_id: channelId,
            session_id: sessionId,
            connection_id: connectionId,
            network_status: networkStatus
        };
        axios.post(`${process.env.REACT_APP_SESSION_API || ""}/network_status_log`, params)
            .then(null)
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (isMeeting) {
            handleSubtitleTranslationLanguage(channelId, sessionId, subtitleTranslationLanguage);
        } else {
            handleSubtitleTranslationLanguage(channelId, sessionId, "0");
        }
    }, [subtitleTranslationLanguage, sessionId, channelId, isMeeting]);

    useEffect(() => {
        const beforeunload = () => {
            handleSubtitleTranslationLanguage(channelId, sessionId, "0");
        };

        document.addEventListener("beforeunload", beforeunload);

        return () => {
            document.removeEventListener("beforeunload", beforeunload);
        };
    }, [channelId, sessionId]);

    const handleShowChat = () => {
        if (!isSeenMessage && !isShowChat) {
            dispatch(setIsSeenMessage(true));
        }
        dispatch(setShowChat(!isShowChat));
    };

    const handleClickShowBreakoutRoom = () => {
        dispatch(setShowBreakoutRoom(!isShowBreakoutRoom));
    };

    const reconnectBreakoutRoom = async () => {
        if (localMediaStream) {
            let connected = false;
            const key = enqueueSnackbar(t("Please wait while we try to reconnect."), { variant: "info", autoHideDuration: null });
            for (let i = 1; i <= 10; i++) {
                try {
                    await connectSora.connect(localMediaStream);
                    connected = true;
                } catch (e) {
                    console.log(e);
                    connected = false;
                }
                if (connected) {
                    closeSnackbar(key);
                    break;
                }
                await new Promise((resolve) => setTimeout(resolve, i * 500 + 500));
            }
        }
    };

    const confirmCallEnd = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Dialog open={true} className="customized-dialog" fullWidth maxWidth="xs"
                            PaperProps={{
                                style: {
                                    backgroundColor: theme.palette.background.paper,
                                    color: theme.palette.text.primary
                                }
                            }}>
                        <DialogTitle>{t("confirmation")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText color={theme.palette.text.primary}>
                                {stringFormat(t("You are about to leave {0}. Is this OK?"), roomName)}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { onClose(); }}>{t("cancel")}</Button>
                            <Button onClick={() => { callEnd().then(null).catch(null); onClose(); }}>{t("Leave the room")}</Button>
                        </DialogActions>
                    </Dialog>
                );
            }
        });
    };

    const sendMessageWhenLeavingRoom = async () => {
        const data = {
            channel_id: channelId,
            data: {
                type: "connection_disconnect",
                connectionId: myConnectionId
            }
        };
        await pushChannel(data);
    };

    return <div className={`container-display ${isNarrow() ? "narrow" : ""}`} style={{ width: `calc(100% - ${isNarrow() ? widthSidebar : 0}px)` }}>
        <Helmet>
            <title>{roomName !== null ? `#${roomName}` : ""} ACSession</title>
        </Helmet>
        {
            isError && <ErrorPopup/>
        }
        <SubTitleList/>
        <Chat sendMessageChat={sendMessageChat} handleKeyUp={handleKeyUp}/>
        <ParticipantList connectionIdRemote={connectSora.connectionId} connectionIdLocal={localMediaStream?.id}/>
        <BreakoutRooms sendMessageCountdownUpdate={sendMessageCountdownUpdate}></BreakoutRooms>
        {
            isMeeting
                ? <MeetingComponent startPinAll={startPinAll} removePinAll={removePinAll} stopShare={stopShare}
                                    sendMessageCountdownUpdate={sendMessageCountdownUpdate}/>
                : <CushionPage
                    connectSendrecv={connectSendrecv}
                    handleClickMicIcon={handleClickMicIcon}
                    handleClickCameraIcon={handleClickCameraIcon}
                    featuresRef={featuresRef}/>
        }
        <div hidden={!isMeeting}>
            <Features shareScreen={shareScreen}
                      stopShare={stopShare}
                      callEnd={confirmCallEnd}
                      handleClickMicIcon={handleClickMicIcon}
                      handleClickCameraIcon={handleClickCameraIcon}
                      stopDevice={stopDevice}
                      sendMessageRecording={sendMessageRecording}
                      ref={featuresRef}
                      sendMessageParticles={sendMessageParticles} handleAddRoomReaction={handleAddRoomReaction}/>
        </div>
        <InputNameDialog open={isOpenInputNameDialog} handleClose={handleCloseInputNameDialog}/>
        <VirtualBackgroundModal isShow={isShowVirtualBackgroundModal}
                                handleClose={() => {
                                    dispatch(setShowVirtualBackgroundModal(!isShowVirtualBackgroundModal));
                                }}/>
        <ErrorCameraDialog
            open={isOpenErrorCameraDialog}
            handleClose={() => {
                dispatch(setOpenErrorCameraDialog(false));
            }}/>
        <ErrorMicDialog
            open={isOpenErrorMicDialog}
            handleClose={() => {
                dispatch(setOpenErrorMicDialog(false));
            }}/>
        {
            isMeeting &&
            <div className="list-btn" style={{right: `${isNarrow() ? widthSidebar : 0}px`}}>
                <HtmlTooltip title={t("participant-list")} placement="left">
                    <span className="btn" onClick={() => {
                        dispatch(setSHowParticipantList(!isSHowParticipantList));
                    }}>
                    <AcMemberOutline width="20px" color="#ffffff"/>
                </span>
                </HtmlTooltip>
                <HtmlTooltip title={t("chat")} placement="left">
                    <span className="btn btn-chat" onClick={handleShowChat}>
                    <img src="/img/ac-chat.svg" alt="chat"/>
                        {
                            !isSeenMessage &&
                            <div className="is-seen-message"></div>
                        }
                </span>
                </HtmlTooltip>
                <HtmlTooltip title={t("sub-title")} placement="left">
                    <span className="btn btn-subtitle" onClick={handleClickSubtitle}>
                    <SubTitleIcon width="20px" height="20px" color="#ffffff"/>
                </span>
                </HtmlTooltip>
                {
                    isMeetingOwner &&
                    <HtmlTooltip title={t("title breakout room")} placement="left">
                        <span className="btn" onClick={handleClickShowBreakoutRoom}>
                            <SubRoomIcon width="20px" height="20px" color="#ffffff"/>
                        </span>
                    </HtmlTooltip>
                }
            </div>
        }
        <BreakoutRoomStartDialog open={isOpenBreakoutRoomStartDialog}
                                 handleClose={() => { setOpenBreakoutRoomStartDialog(false); }}/>
    </div>;
}

export default Meeting;
