import "./sidebarLeft.css";
import {Menu, MenuItem as MenuItemSidebar, Sidebar} from "react-pro-sidebar";
import {useDispatch, useSelector} from "react-redux";
import {type AppDispatch, type RootState} from "../../../store";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import {
    PATH_ADMIN,
    PATH_ADMINISTRATOR_SETUP,
    PATH_RECORD,
    PATH_TOTALING,
    PATH_AI_PROMPT,
    PATH_DATA_AGGREGATION,
    SELECT_SECTION_ID
} from "../../../constants/constant";
import {InputLabel, type SelectChangeEvent, Typography} from "@mui/material";
import {handleErrorMessage, stringFormat} from "../../../utils/utils";
import React, {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import {useSnackbar} from "notistack";
import {getCookieByName} from "../../../utils/CookieUtil";
import {setSectionList, setSelectSectionId} from "../../../services/sora/soraSlice";
import type {ListSectionResponse} from "../../../types/types";

export function SidebarLeft () {
    const { toggledSidebarLeft, selectSectionId, sectionList } = useSelector((state: RootState) => state.sora);
    const { t } = useTranslation();
    const location = useLocation();
    const { isAdmin, isRoomCreatePermission } = useSelector((state: RootState) => state.authentication);
    const [userPermissionName, setUserPermissionName] = useState<string>("");
    const {enqueueSnackbar} = useSnackbar();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const dispatch = useDispatch<AppDispatch>();
    const [selectSectionIdValue, setSelectSectionIdValue] = useState(selectSectionId || "0");

    useEffect(() => {
        if (!sectionList) {
            getSectionList();
        }
    }, []);

    useEffect(() => {
        const localStorageSelectSectionId = localStorage.getItem(SELECT_SECTION_ID);
        if (localStorageSelectSectionId === "0") {
            dispatch(setSelectSectionId(""));
            return;
        }
        const section = sectionList?.find(section => section.id.toString() === localStorageSelectSectionId) || sectionList?.[0];
        if (section) {
            dispatch(setSelectSectionId(section.id));
        }
    }, [sectionList]);

    useEffect(() => {
        let userPermission = "User";
        if (isRoomCreatePermission) {
            userPermission = "Session Admin";
        }
        if (isAdmin) {
            userPermission = "Super Admin";
        }
        setUserPermissionName(userPermission);
    }, [isAdmin, isRoomCreatePermission]);

    useEffect(() => {
        setSelectSectionIdValue(selectSectionId || "0");
    }, [selectSectionId]);

    useEffect(() => {
        dispatch(setSelectSectionId(selectSectionIdValue === "0" ? "" : selectSectionIdValue));
    }, [selectSectionIdValue]);

    const getSectionList = () => {
        axios.get<ListSectionResponse>(`${process.env.REACT_APP_SESSION_API || ""}/list_section?u=${userId}&a=${authToken}`)
            .then((res) => {
                dispatch(setSectionList(res.data.list_section));
            })
            .catch((err) => {
                handleErrorMessage(err, enqueueSnackbar);
            });
    };

    const handleSelectSectionId = (event: SelectChangeEvent) => {
        setSelectSectionIdValue(event.target.value);
        localStorage.setItem(SELECT_SECTION_ID, event.target.value);
    };

    return <Sidebar
        className="sidebar-left"
        width="261px"
        backgroundColor="#ffffff"
        rootStyles={{
            position: "fixed",
            borderColor: "#DAE1E7",
            "&.ps-broken": {
                top: "54px"
            },
            ".ps-sidebar-container": {
                paddingTop: "30px",
                display: "flex",
                flexFlow: "column",
                justifyContent: "space-between",
                height: "calc(100vh - 50px)"
            }
        }}
        toggled={toggledSidebarLeft}
        customBreakPoint="768px">
        <Menu menuItemStyles={{
            button: ({ level, active}) => {
                if (level === 0) {
                    return {
                        color: active ? "#3386CC" : "#666666",
                        backgroundColor: active ? "#EAF3FA" : "#ffffff",
                        fontSize: "16px",
                        borderRadius: "0 30px 30px 0",
                        padding: "10px 30px",
                        fontWeight: active ? "bold" : "normal",
                        marginRight: "30px",
                        ":hover": {
                            color: "#3386CC",
                            backgroundColor: "#EAF3FA",
                            fontWeight: "bold"
                        }
                    };
                }
            }
        }}>
            <Typography className="menu-name">{stringFormat(t("Logged in as"), t(userPermissionName))}</Typography>
            <div className="select-group-project">
                <FormControl margin="dense" size="small" fullWidth>
                    <InputLabel>{t("Department")}</InputLabel>
                    <Select label={t("Department")}
                            value={selectSectionIdValue}
                            onChange={handleSelectSectionId}>
                            <MenuItem value="0">{t("all")}</MenuItem>;
                        {
                            sectionList?.map((item, index) => {
                                return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                            })
                        }
                    </Select>
                </FormControl>
            </div>
            <MenuItemSidebar className="menu-item" active={location.pathname === PATH_ADMIN} component={<Link to={PATH_ADMIN} />}>{t("my room")}</MenuItemSidebar>
            <MenuItemSidebar className="menu-item" active={location.pathname === PATH_RECORD} component={<Link to={PATH_RECORD} />}>{t("record")}</MenuItemSidebar>
            {
                isAdmin &&
                <MenuItemSidebar className="menu-item" active={location.pathname === PATH_ADMINISTRATOR_SETUP} component={<Link to={PATH_ADMINISTRATOR_SETUP} />}>{t("Session Admin Settings")}</MenuItemSidebar>
            }
            <MenuItemSidebar active={location.pathname === PATH_AI_PROMPT} component={<Link to={PATH_AI_PROMPT} />}>{t("AI prompts")}</MenuItemSidebar>
            <MenuItemSidebar active={location.pathname === PATH_TOTALING} component={<Link to={PATH_TOTALING} />}>{t("Totaling")}</MenuItemSidebar>
            <MenuItemSidebar active={location.pathname === PATH_DATA_AGGREGATION} component={<Link to={PATH_DATA_AGGREGATION} />}>{t("Data aggregation")}</MenuItemSidebar>
        </Menu>
        <div className="sidebar-footer">
            <a href="https://aoba-bbt.com/">{t("Aoba-BBT, Inc.")}</a>
            <a href="https://www.bbt757.com/privacy.html">{t("privacy policy")}</a>
            <a href="https://www.bbt757.com/agreement.html">{t("terms of service")}</a>
            <span className="copyright">© 2002-{new Date().getFullYear()} Aoba-BBT, Inc.</span>
        </div>
    </Sidebar>;
}
