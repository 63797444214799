import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {Table, TableBody, TableContainer, TableHead, TableRow} from "@mui/material";
import CreateRoomModal from "../../../components/modals/room/createRoomModal";
import "./index.css";
import ConfirmDialog from "../../../components/modals/confirmDialog";
import {getCookieByName} from "../../../utils/CookieUtil";
import axios from "axios";
import Layout from "../layout";
import {ButtonAddRoom, ButtonDeleteRoom, ButtonUpdateRoom} from "../../../components/styled/Button";
import {AcAdd} from "../../../components/icon/AcAdd";
import {StyledTableCell} from "../../../components/styled/Table";
import {AcClipboard} from "../../../components/icon/AcClipboard";
import {AcGarbagecanOutline} from "../../../components/icon/AcGarbagecanOutline";
import {type Channel, getListChannelOfUser, getListConnections} from "../../../services/sora/soraApi";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import {getUrlMeeting, stringFormat} from "../../../utils/utils";
import {ALLOW_ACCESS_TYPE, PATH_ACCESS_LOG} from "../../../constants/constant";
import type {Room} from "../../../types/types";
import {Link} from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import {useSelector} from "react-redux";
import type {RootState} from "../../../store";

export function Admin () {
    const [listChannel, setListChannel] = useState<Channel[]>();
    // List Owner's Channel
    const [listOwnersChannel, setListOwnersChannel] = useState<Room[]>();
    const {enqueueSnackbar} = useSnackbar();
    const [isShowModalCreateRoom, setShowModalCreateRoom] = useState<boolean>(false);
    const [isShowModalConfirmDelete, setShowModalConfirmDelete] = useState<boolean>(false);
    const [isUpdate, setUpdate] = useState<boolean>(false);
    const [channelIdModalFocus, setChannelIdModalFocus] = useState<string>();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const currentLanguage = i18next.language;
    const { t } = useTranslation();
    const roomModalInit = {
        channel_id: "",
        name: "",
        owner: {
            account_id: userId,
            name: ""
        },
        room_type: 0,
        allow_user_access: ALLOW_ACCESS_TYPE.owner_allow,
        allow_guest_access: ALLOW_ACCESS_TYPE.owner_allow,
        managers: [],
        allow_user_pin: false,
        main_channel_id: null,
        ai_prompt_id: null,
        ai_prompt_title: "",
        bbt_section_id: null,
        bbt_section_name: ""
    };
    const [roomModal, setRoomModal] = useState<Room>(roomModalInit);
    const { isRoomCreatePermission } = useSelector((state: RootState) => state.authentication);
    const { selectSectionId, sectionList } = useSelector((state: RootState) => state.sora);

    useEffect(() => {
        getListConnections().then((channels) => {
            setListChannel(channels);
        }).catch(() => {
            enqueueSnackbar(t("Error call ListConnections"), {variant: "error"});
        });
        axios.get(`${process.env.REACT_APP_SESSION_API || ""}/record?u=${userId || ""}&a=${authToken || ""}&page=${1}&page_size=${1}`).then(null).catch(null);
    }, []);

    const getLengthConnectionsOfChannelByChannelId = (channelId: string | undefined) => {
        const channel = listChannel?.find(item => item.channel_id === channelId);
        if (channel?.listConnections.length) {
            return channel.listConnections.length;
        }
        return 0;
    };

    useEffect(() => {
        let timeout: any;
        if (sectionList) {
            timeout = setTimeout(() => {
                setListOwnersChannelOfUser();
            }, 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isUpdate, userId, authToken, selectSectionId, sectionList]);

    const setListOwnersChannelOfUser = () => {
        getListChannelOfUser(userId, authToken, selectSectionId).then((rooms) => {
            setListOwnersChannel(rooms);
        }).catch(() => {
            enqueueSnackbar(t("Error call list rooms"), {variant: "error"});
        });
    };

    const showModalCreateRoom = () => {
        setShowModalCreateRoom(true);
        setRoomModal(roomModalInit);
    };
    const handleCloseModalCreateRoom = () => {
        setShowModalCreateRoom(false);
        setRoomModal(roomModalInit);
    };
    const handleOpenModalEditRoom = (room: Room, channelId: string) => {
        setShowModalCreateRoom(true);
        if (room.allow_user_access !== ALLOW_ACCESS_TYPE.allow && room.allow_user_access !== ALLOW_ACCESS_TYPE.owner_allow && room.allow_user_access !== ALLOW_ACCESS_TYPE.user_allow) {
            room.allow_user_access = ALLOW_ACCESS_TYPE.owner_allow;
        }
        if (room.allow_guest_access !== ALLOW_ACCESS_TYPE.owner_allow && room.allow_guest_access !== ALLOW_ACCESS_TYPE.user_allow) {
            room.allow_guest_access = ALLOW_ACCESS_TYPE.owner_allow;
        }
        setRoomModal(room);

        getListConnections().then((channels) => {
            setListChannel(channels);
        }).catch(() => {
            enqueueSnackbar(t("Error call ListConnections"), {variant: "error"});
        });
    };

    const handleConfirmDeleteChannel = async () => {
        const lengthConnectionsOfChannelByChannelId = getLengthConnectionsOfChannelByChannelId(channelIdModalFocus);
        if (userId !== null && authToken !== null && lengthConnectionsOfChannelByChannelId === 0) {
            await axios.delete(`${process.env.REACT_APP_SESSION_API || ""}/session_room?u=${userId}&a=${authToken}&channel_id=${roomModal.channel_id}`)
                .finally(() => {
                    setShowModalConfirmDelete(false);
                    setListOwnersChannelOfUser();
                });
        }
    };
    const handleCloseModalDeleteChannel = () => {
        setShowModalConfirmDelete(false);
    };
    const handleOpenConfirmDeleteChannelModal = (room: Room) => {
        setRoomModal(room);
        setShowModalConfirmDelete(true);

        getListConnections().then((channels) => {
            setListChannel(channels);
        }).catch(() => {
            enqueueSnackbar(t("Error call ListConnections"), {variant: "error"});
        });
    };

    const clipboardLink = (channelId: string) => {
        navigator.clipboard.writeText(getUrlMeeting(channelId)).then(r => {
            enqueueSnackbar(t("copied"), { variant: "success", autoHideDuration: 1000});
        }).catch(null);
    };

    const getRoomAccessEntry = (accessType: number) => {
        let result = {
            text: "",
            icon: ""
        };
        switch (accessType) {
            case ALLOW_ACCESS_TYPE.owner_allow:
                result = {
                    text: "Owner approval required",
                    icon: "/img/ac-member-outline-1.svg"
                };
                break;
            case ALLOW_ACCESS_TYPE.user_allow:
                result = {
                    text: "AirCampus user approval required",
                    icon: "/img/ac-member02-outline.svg"
                };
                break;
            case ALLOW_ACCESS_TYPE.allow:
                result = {
                    text: "If you know the URL you can enter",
                    icon: "/img/public_40dp.svg"
                };
                break;
        }
        return result;
    };

    const createRoom = () => {
        if (!selectSectionId && sectionList && sectionList.length > 0) {
            enqueueSnackbar(t("Please select a department before creating a room."), {variant: "error"});
            return;
        }
        showModalCreateRoom();
        setUpdate(false);
    };

    return (
        <Layout>
            {
                isRoomCreatePermission &&
                <div className="create-room">
                    <ButtonAddRoom onClick={createRoom} startIcon={<AcAdd width="14px" color="#ffffff"/>}>
                        {t("create room")}
                    </ButtonAddRoom>
                </div>
            }

            {
                listOwnersChannel && listOwnersChannel.length > 0 &&
                <div style={{minWidth: "768px"}}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell width={"calc((100% - 300px) / 6)"}>{t("room name")}</StyledTableCell>
                                    <StyledTableCell width={"calc((100% - 300px) / 6)"}>{t("Owner")}</StyledTableCell>
                                    <StyledTableCell width={"calc((100% - 300px) / 6)"}>{t("User entry")}</StyledTableCell>
                                    <StyledTableCell width={"calc((100% - 300px) / 6)"}>{t("Guest entry")}</StyledTableCell>
                                    <StyledTableCell width={"calc((100% - 300px) / 6)"}>{t("AI prompt")}</StyledTableCell>
                                    <StyledTableCell width={"calc((100% - 300px) / 6)"}>{t("Department")}</StyledTableCell>
                                    <StyledTableCell width={100}>{t("Entrance record")}</StyledTableCell>
                                    <StyledTableCell width={currentLanguage === "ja" ? "135px" : "200px"} align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listOwnersChannel.filter(row => !row.main_channel_id).map((row) => (
                                    <TableRow
                                        key={row.channel_id}
                                        sx={{"&:last-child th": {border: 0}}}
                                    >
                                        <StyledTableCell align="left">
                                            {
                                                getLengthConnectionsOfChannelByChannelId(row.channel_id) > 0 &&
                                                <span className="there-are-users"/>
                                            }
                                            <a target="_blank" href={`/mtg/${row.channel_id}`} rel="noreferrer">{row.name}</a>
                                            {
                                                getLengthConnectionsOfChannelByChannelId(row.channel_id) > 0 &&
                                                <span>{stringFormat(t("person"), getLengthConnectionsOfChannelByChannelId(row.channel_id))}</span>
                                            }
                                            <span className="clipboard-link"
                                                  onClick={() => { clipboardLink(row.channel_id); }}>
                                                    <AcClipboard height="14px" color="#666666"/>
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.owner.name}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <img className="img-room-access-entry" src={getRoomAccessEntry(row.allow_user_access).icon} alt="access"/>
                                            {t(getRoomAccessEntry(row.allow_user_access).text)}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <img className="img-room-access-entry" src={getRoomAccessEntry(row.allow_guest_access).icon} alt="access"/>
                                            {t(getRoomAccessEntry(row.allow_guest_access).text)}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.ai_prompt_title}</StyledTableCell>
                                        <StyledTableCell>{row.bbt_section_name}</StyledTableCell>
                                        <StyledTableCell>
                                        <Link className="access-log" to={`${PATH_ACCESS_LOG}?channel_id=${row.channel_id}`} style={{marginRight: 10}}>
                                                <ArticleIcon sx={{height: 18, width: 18, color: "#666666"}} />
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <div>
                                                <ButtonDeleteRoom onClick={() => { handleOpenConfirmDeleteChannelModal(row); setChannelIdModalFocus(row.channel_id); }}
                                                                  startIcon={<AcGarbagecanOutline width="14px" color="#F25A5A" />}>
                                                    {t("delete")}
                                                </ButtonDeleteRoom>
                                                <ButtonUpdateRoom onClick={() => { handleOpenModalEditRoom(row, row.channel_id); setUpdate(true); setChannelIdModalFocus(row.channel_id); }}>
                                                    {t("detail")}
                                                </ButtonUpdateRoom>
                                            </div>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
            <CreateRoomModal isUpdate={isUpdate} open={isShowModalCreateRoom}
                             handleClose={handleCloseModalCreateRoom}
                             setListOwnersChannelOfUser={setListOwnersChannelOfUser}
                             roomModal={roomModal}
                             listChannel={listChannel}
                             channelIdModalFocus={channelIdModalFocus}/>
            <ConfirmDialog open={isShowModalConfirmDelete}
                           handleClose={handleCloseModalDeleteChannel}
                           handleSubmit={handleConfirmDeleteChannel}
                           description={getLengthConnectionsOfChannelByChannelId(channelIdModalFocus) > 0 ? t("You can not delete the room") : t("Can I delete the room?")}
                           textClose={t("cancel")}
                           textConfirm={t("delete")}
                           title={t("delete room")}
            disableButton={ getLengthConnectionsOfChannelByChannelId(channelIdModalFocus) > 0}/>
        </Layout>
    );
}
