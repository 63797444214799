import "./cushionPage.css";
import React from "react";
import {useTranslation} from "react-i18next";

export default function StartCallMessage () {
    const { t } = useTranslation();

    return <div className="start-call-message" dangerouslySetInnerHTML={{ __html: t("Check your microphone and camera settings, and when you're ready, press the Start Call button.") }}>
    </div>;
}
