import React from "react";

interface AcPhoneProp {
    width?: string
    height?: string
    color?: string
}

export function AcClipboard (props: AcPhoneProp) {
    return <svg width={props.width} height={props.height}
                xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32">
        <defs>
        </defs>
        <g>
            <g id="_レイヤー_1" data-name="レイヤー_1">
                <path fill={props.color}
                      d="M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16,16-7.2,16-16S24.8,0,16,0ZM16,29.7V2.3c7.6,0,13.7,6.1,13.7,13.7s-6.1,13.7-13.7,13.7Z"/>
            </g>
        </g>
    </svg>;
}
