import React from "react";

interface Props {
    width?: string
    height?: string
    color?: string
}

export default function AcIcon (props: Props) {
    return <svg width={props.width} height={props.height}
                version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 80 80" xmlSpace="preserve">
        <g>
            <g>
                <g>
                    <g>
                        <path fill={props.color}
                              d="M42.6,59l-7.3,7.3c-0.6,0.6-0.6,1.7,0,2.3l10.2,10.2c0.4,0.4,1.1,0.6,1.7,0.5c3.7-0.7,7.4-1.9,10.9-3.7 c1.1-0.6,1.3-2.1,0.4-2.9C57,71.1,48.5,62.6,44.8,58.9C44.3,58.4,43.3,58.4,42.6,59z"/>
                        <path fill={props.color}
                              d="M1.4,39.1C0.9,38.6,0,39,0,39.7c-0.1,10.4,3.8,20.8,11.7,28.6S30,80.1,40.4,80c0.7,0,1.1-0.9,0.6-1.4 L1.4,39.1z"/>
                        <path fill={props.color}
                              d="M39.6,0c-0.7,0-1.1,0.9-0.6,1.4l7.4,7.4c0.6,0.6,0.6,1.6,0,2.2c-0.6,0.6-1.6,0.6-2.2,0l-9.9-9.8 c-0.4-0.4-1.1-0.6-1.7-0.5c-3.6,0.7-7.4,1.9-10.8,3.7c-1.1,0.6-1.3,2.1-0.4,2.9l51.3,51.3c0.9,0.9,2.4,0.7,2.9-0.4 c1.8-3.4,3-7.1,3.7-10.8c0.1-0.6-0.1-1.2-0.5-1.7L55.2,22.2c-0.6-0.6-0.6-1.6,0-2.2s1.6-0.6,2.2,0l21.2,21.2 c0.5,0.5,1.4,0.2,1.4-0.6c0.1-10.4-3.7-20.9-11.7-28.7S50-0.1,39.6,0z"/>
                        <path fill={props.color}
                              d="M18,8.5c-0.6-0.6-1.7-0.7-2.4-0.2c-1.3,1-2.6,2.1-3.9,3.3c-1.2,1.2-2.3,2.5-3.4,3.9 c-0.6,0.7-0.5,1.8,0.2,2.4L62,71.5c0.6,0.6,1.7,0.7,2.4,0.2c1.4-1,2.7-2.1,3.9-3.4c1.2-1.2,2.3-2.5,3.3-3.9 c0.6-0.7,0.5-1.8-0.2-2.4L18,8.5z"/>
                        <path fill={props.color}
                              d="M33.1,64l7.2-7.2c0.6-0.6,0.6-1.7,0-2.3l-32.9-33c-0.9-0.9-2.4-0.7-2.9,0.4c-1.7,3.4-3,7.1-3.7,10.9 c-0.1,0.6,0.1,1.2,0.5,1.7c2.6,2.6,18.1,18.1,29.6,29.6C31.5,64.7,32.5,64.7,33.1,64z"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>;
}
