import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../../store";
import {
    setBreakoutRoom,
    setJoinBreakoutRoom,
    setRemoteConnectionBreakoutRoomStarted
} from "../../../services/sora/soraSlice";
import "./breakoutRoomDialog.css";

interface Props {
    open: boolean
    handleClose: any
}

export default function BreakoutRoomStartDialog (props: Props) {
    const { t } = useTranslation();
    const {
        breakoutRooms,
        mainRoomName,
        channelId,
        isBreakoutRoomStarted,
        remoteConnection
    } = useSelector((state: RootState) => state.sora);
    const dispatch = useDispatch<AppDispatch>();

    const updateBreakoutRooms = (channelIdBreakoutRoom: string, roomName: string) => {
        props.handleClose();
        // Keep the original list of participants when entering the child room
        if (channelIdBreakoutRoom !== channelId) {
            if (!isBreakoutRoomStarted) {
                dispatch(setRemoteConnectionBreakoutRoomStarted(remoteConnection));
            }
            dispatch(setJoinBreakoutRoom(true));
        } else {
            dispatch(setJoinBreakoutRoom(false));
        }
        dispatch(setBreakoutRoom({
            channelId: channelIdBreakoutRoom,
            roomName
        }));
    };

    return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog" fullWidth>
        <DialogTitle>
            <span>{t("title breakout room")}</span>
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30, color: "#1976d2"}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            <p className="breakout-room-dialog-message">{t("The breakout session has started. The owner and alternate owner should select a room and move to it.")}</p>
            {
                breakoutRooms.map((room, index) => {
                    return <div key={index}>
                        <a className="breakout-room-dialog-item" onClick={() => {
                            updateBreakoutRooms(room.channel_id, `${mainRoomName} : ${room.name}`);
                        }}>{room.name}</a><br/>
                    </div>;
                })
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>{t("Close")}</Button>
        </DialogActions>
    </Dialog>;
}
