import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid
} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import type {RootState} from "../../../store";
import {pushChannel} from "../../../services/sora/soraApi";
import {type RemoteConnection} from "../../../services/sora/soraSlice";
import axios from "axios";
import {getCookieByName} from "../../../utils/CookieUtil";
import {stringFormat} from "../../../utils/utils";
import "./breakoutRoomDialog.css";
import RoomsUser from "../../rooms/roomsUser";

interface Props {
    open: boolean
    handleClose: any
    breakoutRoomSetting: string
    nameRoomSelected: string | undefined
}

export default function BreakoutRoomDialog (props: Props) {
    const { t } = useTranslation();
    const { remoteConnection, channelId } = useSelector((state: RootState) => state.sora);
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const [isCheck, setIsCheck] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, connection: RemoteConnection) => {
        const breakoutRoom = event.target.checked ? props.breakoutRoomSetting : undefined;
        const data = {
            channel_id: channelId,
            data: {
                type: "setting_breakout_room",
                connectionId: connection.connectionId,
                breakoutRoom
            }
        };
        pushChannel(data).then(null).catch((error) => {
            console.log(error);
        });
        if (connection.userId) {
            const dataGuest = {
                user_name: connection.name,
                client_id: connection.userId,
                allowed: !!breakoutRoom
            };
            axios.post(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/room/${props.breakoutRoomSetting}/guests?u=${userId}&a=${authToken}`, dataGuest).then(null).catch((error) => {
                console.log(error);
            });
        }
    };

    useEffect(() => {
        const shouldCheck = remoteConnection.some(connection =>
            !connection.isShareScreen &&
            !connection.isMine &&
            !connection.isMeetingOwner &&
            (!connection.breakoutRoom || (props.breakoutRoomSetting === connection.breakoutRoom))
        );

        if (shouldCheck) {
            setIsCheck(true);
        } else {
            setIsCheck(false);
        }
    }, [remoteConnection, props.breakoutRoomSetting]);

    return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog" fullWidth>
        <DialogTitle>
            <span>{stringFormat(t("Breakout Rooms"), props.nameRoomSelected)}</span>
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30, color: "#1976d2"}}/>
            </Icon>
        </DialogTitle>
        <DialogContent sx={{
            paddingBottom: 0,
            width: "100%"
        }}>
            <FormGroup className="breakout-room-dialog">
                <Grid container spacing={2}>
                    {
                        remoteConnection.map((connection, index) => {
                        if (!connection.isShareScreen && !connection.isMine && !connection.isMeetingOwner) {
                            if (!connection.breakoutRoom || (props.breakoutRoomSetting === connection.breakoutRoom)) {
                                const checked = props.breakoutRoomSetting === connection.breakoutRoom;
                                return (
                                    <Grid item xs={6} key={index}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(event) => { handleChange(event, connection); }} checked={checked} />}
                                            label={
                                                <RoomsUser
                                                    key={index}
                                                    name={connection.realName}
                                                    connection={connection}
                                                    index={index} />
                                            }
                                        />
                                    </Grid>
                                );
                            }
                        }
                        return null;
                    })}
                </Grid>
            </FormGroup>
        </DialogContent>
        <DialogContent sx={{
            paddingTop: 0,
            overflowY: "hidden"
        }}>
            {
                isCheck &&
                <span>{stringFormat(t("Breakout Rooms notice"), props.nameRoomSelected)}</span>
            }
            {
                !isCheck &&
                <span>{t("no members to assign")}</span>
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>{t("Close")}</Button>
        </DialogActions>
    </Dialog>;
}
