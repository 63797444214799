import "./fearures.css";
import Icon from "../icon/icon";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {useDispatch, useSelector} from "react-redux";
import {
    getRecordingData,
    getSession,
    ParticlesType,
    setCameraOn,
    setEmojiParticles,
    setFireworksParticles,
    setFirstLogin,
    setIsOpenErrorDevices,
    setIsSeenMessage,
    setIsSubTitle,
    setMicroOn,
    setOpenErrorCameraDialog,
    setOpenErrorMicDialog,
    setOpenSubtitleSetting,
    setRecording,
    setRecordingData,
    setShowBreakoutRoom,
    setShowChat,
    setSHowParticipantList,
    setShowRoomDetailModal,
    setSnowParticles
} from "../../services/sora/soraSlice";
import {
    CAMERA_ID,
    IS_CAMERA,
    IS_MIC,
    MICRO_ID, NOT_ALLOWED_ERROR,
    RECVONLY,
    SPEAKER_ID
} from "../../constants/constant";
import DeviceModal from "../modals/device/deviceModal";
import {setShowVirtualBackgroundModal} from "../../services/virtualBackground/virtualBackgroundSlice";
import {MenuItem, Popover, useTheme} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import StyledMenu from "../styled/StyledMenu";
import DeviceErrorModal from "../modals/device/deviceErrorModal";
import {ParticlesContainer} from "../particlesContainer/particlesContainer";
import {FireworksParticles} from "../particlesContainer/fireworksParticles";
import axios from "axios";
import {useSnackbar} from "notistack";
import AcScreenSharing from "../icon/AcScreenSharing";
import AcChat from "../icon/AcChat";
import {AcMemberOutline} from "../icon/AcMemberOutline";
import AcStarsOutline from "../icon/AcStarsOutline";
import {useTranslation} from "react-i18next";
import React, {forwardRef, type Ref, useEffect, useImperativeHandle, useState} from "react";
import {type AppDispatch, type RootState} from "../../store";
import {type Device, setCameraUsing, setMicUsing, setSpeakerUsing} from "../../services/devices/devicesSlice";
import RoomDetailModal from "../modals/roomDetail/roomDetailModal";
import {getCookieByName} from "../../utils/CookieUtil";
import type {RecordingData, StartRecordingResponse} from "../../types/types";
import RecordModal from "../modals/record/recordModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingQuickly from "../modals/device/settingQuickly";
import EmojiContainer from "../emojiContainer/emojiContainer";
import {emojiList, type IEmoji} from "../chat/emoji";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SubTitleIcon from "../icon/SubTitleIcon";
import {handleStartSubtitle} from "../../services/sora/soraApi";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SubRoomIcon from "../icon/SubRoomIcon";
import useSound from "use-sound";
import ThemePopover from "../themePopover/themePopover";
import {AcClipboard} from "../icon/AcColor";
import AcIcon from "../icon/AcIcon";

interface FeaturesProps {
    callEnd?: any
    shareScreen?: any
    stopShare?: any
    stopDevice?: any
    handleClickMicIcon: any
    handleClickCameraIcon: any
    sendMessageRecording: any
    sendMessageParticles: any
    handleAddRoomReaction: any
}

export interface FeaturesRef {
    handleChangeCamera: (event: React.ChangeEvent<HTMLSelectElement>) => void
    handleChangeMic: (event: React.ChangeEvent<HTMLSelectElement>) => void
    handleChangeSpeaker: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

const Features = forwardRef((props: FeaturesProps, ref: Ref<FeaturesRef>) => {
    const {
        isMeeting,
        isCameraOn,
        isMicroOn,
        isMineShare,
        isShowChat,
        isSHowParticipantList,
        isSubTitle,
        isOpenErrorDevices,
        isRecording,
        snowParticles,
        fireworksParticles,
        emojiParticles,
        connectType,
        roomName,
        isSeenMessage,
        isShowRoomDetailModal,
        breakoutRoom,
        isMeetingOwner,
        remoteConnection,
        isErrorCamera,
        isErrorMic,
        channelId,
        recordingData,
        sessionId,
        isShowBreakoutRoom,
        micErrorType,
        camerasErrorType
    } = useSelector((state: RootState) => state.sora);
    const [openSettingDevices, setOpenSettingDevices] = React.useState(false);
    const {cameras, micros, speakers, isLoading} = useSelector((state: RootState) => state.devices);
    const { enqueueSnackbar } = useSnackbar();
    const {isShowVirtualBackgroundModal} = useSelector((state: RootState) => state.virtualBackground);
    const [isExpandMore, setExpandMore] = useState<boolean>(true);
    useImperativeHandle(ref, () => ({ handleChangeCamera, handleChangeMic, handleChangeSpeaker }));
    const { t } = useTranslation();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const [isShowRecordModal, setShowRecordModal] = useState<boolean>(false);
    const [isShowSettingMicQuickly, setIsShowSettingMicQuickly] = useState<boolean>(false);
    const [isShowSettingCameraQuickly, setIsShowSettingCameraQuickly] = useState<boolean>(false);
    const [anchorEmojiEl, setAnchorEmojiEl] = React.useState<HTMLElement | null>(null);
    const openAnchorEmojiEl = Boolean(anchorEmojiEl);
    const [emojiQuantity, setEmojiQuantity] = useState(5);
    const [emojisSound] = useSound("/mp3/emojis_sound.mp3", {
        volume: 0.2,
        sprite: {
            smile: [115965, 563],
            "heart-eyes": [101695, 4527],
            "heart-face": [114085, 1870],
            rofl: [123017, 2094],
            "loudly-crying": [110458, 3627],
            "grin-sweat": [8425, 640],
            "slightly-happy": [2500, 650],
            "star-struck": [118028, 2920],
            "partying-face": [116537, 1480],
            screaming: [107105, 2122],
            cry: [109404, 1047],
            "hug-face": [120835, 1971],
            "red-heart": [94920, 374],
            muscle: [94920, 374],
            clap: [91290, 2865],
            "thumbs-up": [94920, 374],
            "folded-hands": [91290, 2865],
            "party-popper": [32467, 887],
            "man-bowing": [94920, 374],
            "thinking-face": [62372, 985],
            surprised: [71487, 443],
            "see-no-evil-monkey": [125121, 1005],
            "hear-no-evil-monkey": [125121, 1005],
            "speak-no-evil-monkey": [125121, 1005],
            snow: [96444, 5000]
        }
    });
    const [anchorThemePopoverEl, setAnchorThemePopoverEl] = React.useState<HTMLElement | null>(null);
    const openAnchorThemePopoverEl = Boolean(anchorThemePopoverEl);
    const [btnOptionAnchorEl, setBtnOptionAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(btnOptionAnchorEl);
    const dispatch = useDispatch<AppDispatch>();
    const theme = useTheme();
    const [anchorSettingDeviceQuicklyEl, setAnchorSettingDeviceQuicklyEl] = React.useState<HTMLElement | null>(null);

    useEffect(() => {
        dispatch(setCameraOn(cameras.length > 0 && localStorage.getItem(IS_CAMERA) !== "false" && connectType !== RECVONLY));
        dispatch(setMicroOn(micros.length > 0 && localStorage.getItem(IS_MIC) !== "false" && connectType !== RECVONLY));
    }, [cameras, micros]);

    useEffect(() => {
        dispatch(getSession()).then(null).catch(null);
    }, [breakoutRoom, isMeeting]);

    useEffect(() => {
        dispatch(getRecordingData()).then(null).catch(null);
    }, [sessionId]);

    const handleOpenSettingDevices = () => {
        handleCloseBtnOption();
        setOpenSettingDevices(true);

        setIsShowSettingMicQuickly(false);
        setIsShowSettingCameraQuickly(false);
    };
    const handleCloseSettingDevices = () => {
        setOpenSettingDevices(false);
    };

    const handleChangeCamera = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;

        const selectedCamera = cameras.find(camera => camera.deviceId === value);
        const label = selectedCamera ? selectedCamera.label : "";

        const device: Device = {
            deviceId: value,
            label
        };
        dispatch(setCameraUsing(device));
        localStorage.setItem(CAMERA_ID, value);
    };

    const handleChangeMic = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        const selectedMicro = micros.find(micro => micro.deviceId === value);
        const label = selectedMicro ? selectedMicro.label : "";

        const device: Device = {
            deviceId: value,
            label
        };
        dispatch(setMicUsing(device));
        localStorage.setItem(MICRO_ID, value);
    };

    const handleChangeSpeaker = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        const selectedSpeaker = speakers.find(speaker => speaker.deviceId === value);
        const label = selectedSpeaker ? selectedSpeaker.label : "";

        const device: Device = {
            deviceId: value,
            label
        };
        dispatch(setSpeakerUsing(device));
        localStorage.setItem(SPEAKER_ID, value);
    };

    // Completed later

    const handleRecordClick = () => {
        if (breakoutRoom?.channelId) {
            if (isRecording) {
                const params = { channel_id: breakoutRoom.channelId };
                axios.post(`${process.env.REACT_APP_WEBRTC_API || ""}`, params, {
                    headers: {
                        "x-sora-target": "Sora_20231220.StopRecording"
                    }
                }).then(() => {
                    dispatch(setRecording(false));
                    props.sendMessageRecording(false);
                }).catch(() => {
                    enqueueSnackbar(t("Error call StopRecording"), { variant: "error" });
                });
            } else {
                setShowRecordModal(true);
                setBtnOptionAnchorEl(null);
            }
        }
    };

    const startRecording = (accountId: string, autoGenerateSummary: boolean, shareRecordList: string[]) => {
        if (!breakoutRoom) {
            return;
        }
        const params = {
            channel_id: breakoutRoom.channelId,
            metadata: {
                auto_generate_summary: autoGenerateSummary,
                user_id: userId,
                auth_token: authToken
            }
        };
        axios.post<StartRecordingResponse>(`${process.env.REACT_APP_WEBRTC_API || ""}`, params, {
            headers: {
                "x-sora-target": "Sora_20231220.StartRecording"
            }
        }).then((res) => {
            dispatch(setRecording(true));
            props.sendMessageRecording(true);
            const params = {
                channel_id: breakoutRoom.channelId,
                account_id: accountId || userId,
                record_id: res.data.recording_id,
                session_id: res.data.session_id,
                channel_name: roomName,
                share_record_list: shareRecordList
            };
            axios.post<RecordingData>(`${process.env.REACT_APP_SESSION_API || ""}/record?u=${userId}&a=${authToken}`, params)
                .then(recordingDataRes => {
                    if (recordingDataRes.status === 200) {
                        dispatch(setRecordingData(recordingDataRes.data));
                    }
                })
                .catch(() => {
                    enqueueSnackbar(t("Error call StartRecording"), { variant: "error" });
                });
        }).catch(() => {
            enqueueSnackbar(t("Error call StartRecording"), { variant: "error" });
        });
    };

    const handleClickBtnOption = (event: React.MouseEvent<HTMLElement>) => {
        setBtnOptionAnchorEl(event.currentTarget);
    };
    const handleCloseBtnOption = () => {
        setBtnOptionAnchorEl(null);
    };
    const handleClickParticles = (particlesType: ParticlesType) => {
        const name = remoteConnection.find(connection => connection.isMine)?.realName || "";
        const particle = {
            isShow: true,
            type: particlesType,
            name,
            emojiQuantity
        };
        props.sendMessageParticles(particle);
        switch (particlesType) {
            case ParticlesType.SNOW:
                dispatch(setSnowParticles(particle));
                break;
            case ParticlesType.FIREWORKS:
                dispatch(setFireworksParticles(particle));
                break;
            case ParticlesType.EMOJI:
                dispatch(setEmojiParticles(particle));
                break;
        }
    };

    const handleShowChat = () => {
        if (!isSeenMessage && !isShowChat) {
            dispatch(setIsSeenMessage(true));
        }
        dispatch(setShowChat(!isShowChat));
    };

    useEffect(() => {
        let timeoutParticle: any;
        if (snowParticles.isShow) {
            emojisSound({id: "snow"});
            timeoutParticle = setTimeout(() => {
                const particle = {
                    isShow: false
                };
                dispatch(setSnowParticles(particle));
            }, 5000);
        }

        return () => {
            clearTimeout(timeoutParticle);
        };
    }, [snowParticles]);

    useEffect(() => {
        let timeoutParticle: any;
        if (fireworksParticles.isShow) {
            timeoutParticle = setTimeout(() => {
                const particle = {
                    isShow: false
                };
                dispatch(setFireworksParticles(particle));
            }, 5000);
        }

        return () => {
            clearTimeout(timeoutParticle);
        };
    }, [fireworksParticles]);

    useEffect(() => {
        let timeoutParticle: any;
        if (emojiParticles.isShow) {
            if (emojiParticles.emojiType) {
                emojisSound({id: emojiParticles.emojiType});
            }

            timeoutParticle = setTimeout(() => {
                const particle = {
                    isShow: false
                };
                dispatch(setEmojiParticles(particle));
            }, 5000);
        }

        return () => {
            clearTimeout(timeoutParticle);
        };
    }, [emojiParticles]);

    useEffect(() => {
        if (isLoading && !isMeeting) {
            dispatch(setIsOpenErrorDevices(cameras.length === 0 || micros.length === 0));
        }
    }, [isLoading]);

    const handleOpenRoomDetailModal = () => {
        setBtnOptionAnchorEl(null);
        dispatch(setShowRoomDetailModal(true));
    };

    const handleCloseRoomDetailModal = () => {
        dispatch(setShowRoomDetailModal(false));
    };

    const handleShowSettingMicQuickly = (event: React.MouseEvent<HTMLElement>) => {
        if (micros.length > 0) {
            setIsShowSettingMicQuickly(!isShowSettingMicQuickly);
            setAnchorSettingDeviceQuicklyEl(event.currentTarget);
        } else {
            handleOpenErrorMicDialog();
        }
    };

    const handleShowSettingCameraQuickly = (event: React.MouseEvent<HTMLElement>) => {
        if (cameras.length > 0) {
            setIsShowSettingCameraQuickly(!isShowSettingCameraQuickly);
            setAnchorSettingDeviceQuicklyEl(event.currentTarget);
        } else {
            handleOpenErrorCameraDialog();
        }
    };

    const handleCloseSettingQuickly = () => {
        setIsShowSettingMicQuickly(false);
        setIsShowSettingCameraQuickly(false);
    };

    useEffect(() => {
        setIsShowSettingMicQuickly(false);
        setIsShowSettingCameraQuickly(false);
    }, [micros, cameras]);

    const handleClickEmojiEl = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEmojiEl(event.currentTarget);
    };

    const handleCloseEmojiEl = () => {
        setAnchorEmojiEl(null);
    };

    const handleClickThemePopover = (event: React.MouseEvent<HTMLElement>) => {
        setBtnOptionAnchorEl(null);
        setAnchorThemePopoverEl(btnOptionAnchorEl);
    };

    const handleCloseThemePopover = () => {
        setAnchorThemePopoverEl(null);
    };

    const handleClickEmoji = (emoji: IEmoji) => {
        switch (emoji.name) {
            case "fireworks":
                handleClickParticles(ParticlesType.FIREWORKS);
                break;
            case "snow":
                handleClickParticles(ParticlesType.SNOW);
                break;
            default: {
                const name = remoteConnection.find(connection => connection.isMine)?.realName || "";
                const particle = {
                    isShow: true,
                    type: ParticlesType.EMOJI,
                    emojiType: emoji.name,
                    name,
                    emojiQuantity
                };
                dispatch(setEmojiParticles(particle));
                props.sendMessageParticles(particle);
            }
        }
        props.handleAddRoomReaction(emoji.name);
    };

    const handleOpenErrorCameraDialog = () => {
        if (camerasErrorType === NOT_ALLOWED_ERROR) {
            dispatch(setIsOpenErrorDevices(true));
        } else {
            dispatch(setOpenErrorCameraDialog(true));
        }
    };

    const handleOpenErrorMicDialog = () => {
        if (micErrorType === NOT_ALLOWED_ERROR) {
            dispatch(setIsOpenErrorDevices(true));
        } else {
            dispatch(setOpenErrorMicDialog(true));
        }
    };

    const handleClickSubtitle = () => {
        if (!isSubTitle) {
            handleStartSubtitle(channelId);
            dispatch(setOpenSubtitleSetting(true));
        }
        setBtnOptionAnchorEl(null);
        dispatch(setIsSubTitle(!isSubTitle));
    };

    const handleShareRecordings = () => {
        setShowRecordModal(true);
        setBtnOptionAnchorEl(null);
    };

    const handleChangeEmojiQuantity = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const quantity = Number.parseInt(event.target.value) || 1;
        setEmojiQuantity(quantity);
    };

    const handleClickCloseErrorDevice = async () => {
        dispatch(setIsOpenErrorDevices(false));
    };

    return (
        <>
            <div className={`container-features ${isExpandMore ? "is-expand-more" : "is-expand-close"}`}>
                <span className="btn-expand" onClick={() => { setExpandMore(!isExpandMore); dispatch(setFirstLogin(false)); }}>
                    {
                        isExpandMore
                            ? <img src="/img/ci-arrow-down.svg" alt="ExpandMoreIcon" className="btn-expand-less"/>
                            : <img src="/img/ci-arrow-up.svg" alt="ExpandMoreIcon" className="btn-expand-less"/>
                    }
                </span>
                <div className="features-title">
                    <span className="span-icon-record">
                        <AcIcon width="16px" color={isRecording ? theme.palette.error.main : "#FFFFFF"}/>
                    </span>
                    <span className="room-name">＃ {roomName}</span>
                </div>
                <div className="basic-features">
                    {
                        connectType !== RECVONLY &&
                        <div className="container-setting-feature">
                            <div className="setting-feature">
                                <Icon titleTooltip={t("sound-setting-mode")} classCustom="bg-red"
                                      handleOnclick={handleShowSettingMicQuickly}>
                                    {
                                        isShowSettingMicQuickly
                                            ? <img src="/img/ci-arrow-down.svg" alt="mic"/>
                                            : <img src="/img/ci-arrow-up.svg" alt="mic"/>
                                    }
                                </Icon>
                                <SettingQuickly hiddenMic={!isShowSettingMicQuickly}
                                                hiddenCamera={!isShowSettingCameraQuickly}
                                                hiddenSpeaker={!isShowSettingMicQuickly}
                                                handleChangeMic={handleChangeMic}
                                                handleChangeCamera={handleChangeCamera}
                                                handleChangeSpeaker={handleChangeSpeaker}
                                                handleOpenSettingDevices={handleOpenSettingDevices}
                                                handleCloseSettingQuickly={handleCloseSettingQuickly}
                                                anchorSettingDeviceQuicklyEl={anchorSettingDeviceQuicklyEl}/>
                            </div>
                            <div>
                                {
                                    isErrorMic
                                        ? <Icon handleOnclick={handleOpenErrorMicDialog}
                                                titleTooltip="Show more info" classCustom="bg-red">
                                            <img src="/img/ac-mic-mute.svg" alt="mic"/>
                                            <div className="error-device">
                                                <PriorityHighIcon className="error-device-icon"></PriorityHighIcon>
                                            </div>
                                        </Icon>
                                        : isMicroOn
                                            ? <Icon handleOnclick={props.handleClickMicIcon}
                                                    titleTooltip={t("turn-off-the-mic")}>
                                                <img src="/img/ac-mic.svg" alt="mic"/>
                                            </Icon>
                                            : <Icon handleOnclick={props.handleClickMicIcon}
                                                    titleTooltip={t("turn-on-the-mic")} classCustom="bg-red">
                                                <img src="/img/ac-mic-mute.svg" alt="mic"/>
                                            </Icon>
                                }
                            </div>
                        </div>
                    }
                    {
                        connectType !== RECVONLY &&
                        <div className="container-setting-feature" hidden={connectType === RECVONLY}>
                            <div className="setting-feature">
                                <Icon titleTooltip={t("sound-setting-mode")} classCustom="bg-red"
                                      handleOnclick={handleShowSettingCameraQuickly}>
                                    {
                                        isShowSettingCameraQuickly
                                            ? <img src="/img/ci-arrow-down.svg" alt="camera"/>
                                            : <img src="/img/ci-arrow-up.svg" alt="camera"/>
                                    }
                                </Icon>
                            </div>
                            <div>
                                {
                                    cameras.length === 0 || isErrorCamera
                                        ? <Icon handleOnclick={handleOpenErrorCameraDialog}
                                              titleTooltip="Show more info"
                                              classCustom="bg-red">
                                            <img src="/img/ac-video-mute.svg" alt="video"/>
                                            <div className="error-device">
                                                <PriorityHighIcon className="error-device-icon"></PriorityHighIcon>
                                            </div>
                                        </Icon>
                                        : isCameraOn
                                            ? <Icon handleOnclick={props.handleClickCameraIcon}
                                                    titleTooltip={t("turn-off-the-camera")}>
                                                <img src="/img/ac-video.svg" alt="video"/>
                                            </Icon>
                                            : <Icon handleOnclick={props.handleClickCameraIcon}
                                                    titleTooltip={t("turn-on-the-camera")} classCustom="bg-red">
                                                <img src="/img/ac-video-mute.svg" alt="video"/>
                                            </Icon>
                                }
                            </div>
                        </div>
                    }
                    <div hidden={!isMeeting || isMineShare || connectType === RECVONLY} className="hidden-mobile">
                        <Icon handleOnclick={() => {
                            props.shareScreen();
                        }} titleTooltip={t("share-screen")}>
                            <AcScreenSharing width="20px" color="#484848"/>
                        </Icon>
                    </div>
                    <div hidden={!isMeeting || !isMineShare || connectType === RECVONLY} className="hidden-mobile">
                        <Icon handleOnclick={() => {
                            props.stopShare();
                        }} titleTooltip={t("stop-screen-sharing")}>
                            <AcScreenSharing width="20px" color="#3386CC"/>
                        </Icon>
                    </div>
                    <Icon handleOnclick={handleClickEmojiEl} classCustom="hidden-mobile" titleTooltip={t("Send a reaction")}>
                        <img style={{ width: 26 }} src="/img/sentiment_satisfied_alt.svg" alt="sentiment_satisfied_alt"/>
                    </Icon>
                    {
                        connectType !== RECVONLY &&
                        <Icon handleOnclick={() => {
                            dispatch(setShowVirtualBackgroundModal(!isShowVirtualBackgroundModal));
                        }}
                              classCustom="hidden-mobile"
                              titleTooltip={t("effect-settings")}>
                        <AcStarsOutline width="20px" color="#484848"/>
                        </Icon>
                    }
                    <div hidden={connectType === RECVONLY}>
                        <Icon handleOnclick={(event: React.MouseEvent<HTMLElement>) => { handleClickBtnOption(event); }} titleTooltip={t("option")}>
                            <img src="/img/ac-option.svg" alt="ac-option"/>
                        </Icon>
                        <StyledMenu
                            id="basic-menu"
                            anchorEl={btnOptionAnchorEl}
                            open={open}
                            onClose={handleCloseBtnOption}
                            MenuListProps={{
                                "aria-labelledby": "basic-button"
                            }}
                        >
                            <MenuItem onClick={handleOpenRoomDetailModal} disableRipple>
                                <InfoOutlinedIcon width="20px" style={{color: theme.palette.text.primary, marginRight: "10px"}} />
                                {t("Detailed settings for this Session")}
                            </MenuItem>
                            <MenuItem onClick={handleOpenSettingDevices}>
                                <SettingsIcon width="20px" style={{color: theme.palette.text.primary, marginRight: "10px"}} />{t("setting")}
                            </MenuItem>
                            {
                                !isMineShare
                                ? <MenuItem className="show-mobile" onClick={() => { props.shareScreen(); handleCloseBtnOption(); }}>
                                        <AcScreenSharing color={theme.palette.text.primary}/>{t("share-screen")}
                                    </MenuItem>
                                : <MenuItem className="show-mobile" onClick={() => { props.stopShare(); handleCloseBtnOption(); }}>
                                    <AcScreenSharing color={theme.palette.text.primary}/>{t("stop-screen-sharing")}
                                </MenuItem>
                            }
                            <MenuItem onClick={() => { dispatch(setSHowParticipantList(!isSHowParticipantList)); handleCloseBtnOption(); }}>
                                <AcMemberOutline color={theme.palette.text.primary}/>{isSHowParticipantList ? t("close-participant-list") : t("participant-list")}
                            </MenuItem>
                            <MenuItem onClick={() => { handleShowChat(); handleCloseBtnOption(); }}>
                                <AcChat color={theme.palette.text.primary}/>{isShowChat ? t("close-chat") : t("chat")}
                                {
                                    !isSeenMessage &&
                                    <div className="is-seen-message-mobile"></div>
                                }
                            </MenuItem>
                            {
                                isSubTitle
                                    ? <MenuItem onClick={handleClickSubtitle}>
                                        <SubTitleIcon width="20px" height="20px" color="#F25A5A"/>{t("sub-title")}
                                    </MenuItem>
                                    : <MenuItem onClick={handleClickSubtitle}>
                                        <SubTitleIcon width="20px" height="20px" color={theme.palette.text.primary}/>{t("sub-title")}
                                    </MenuItem>
                            }
                            <MenuItem onClick={() => { dispatch(setShowVirtualBackgroundModal(!isShowVirtualBackgroundModal)); handleCloseBtnOption(); }} className="show-mobile">
                                <AcStarsOutline color={theme.palette.text.primary}/>{t("effect-settings")}
                            </MenuItem>
                            {
                                (isMeeting && isMeetingOwner) &&
                                <MenuItem onClick={handleRecordClick} disableRipple>
                                    <RadioButtonCheckedIcon style={{ marginRight: "10px" }} className={`${isRecording ? "icon-recording" : ""}`}/>
                                    {isRecording ? t("Recording") : t("Start recording")}
                                </MenuItem>
                            }
                            {
                                (isMeeting && isMeetingOwner) &&
                                <MenuItem onClick={handleShareRecordings} disableRipple disabled={!isRecording || userId !== recordingData?.account_id}>
                                    <SettingsApplicationsIcon style={{ marginRight: "10px" }}/>
                                    {t("Share Recordings")}
                                </MenuItem>
                            }
                            <MenuItem onClick={handleClickEmojiEl} disableRipple className="show-mobile">
                                <SentimentSatisfiedAltIcon style={{ marginRight: "10px" }}/>{t("Send a reaction")}
                            </MenuItem>
                            {
                                isMeetingOwner &&
                                <MenuItem onClick={() => { handleCloseBtnOption(); dispatch(setShowBreakoutRoom(!isShowBreakoutRoom)); }}>
                                    <SubRoomIcon color={theme.palette.text.primary}/>{t("sub room")}
                                </MenuItem>
                            }
                            <MenuItem onClick={handleClickThemePopover}>
                                <AcClipboard color={theme.palette.text.primary} />{t("Theme")}
                            </MenuItem>
                        </StyledMenu>
                    </div>
                    <div hidden={!isMeeting}>
                        <Icon handleOnclick={() => { props.callEnd(); dispatch(setFirstLogin(false)); }}
                              titleTooltip={t("leave-call")} classCustom="bg-red">
                            <img src="/img/ac-phone-red.svg" alt="ac-option"/>
                        </Icon>
                    </div>
                </div>
                <div className="extra"></div>
            </div>
            <DeviceModal open={openSettingDevices}
                         handleClose={handleCloseSettingDevices}
                         cameras={cameras}
                         micros={micros}
                         speakers={speakers}
                         handleChangeCamera={handleChangeCamera}
                         handleChangeMic={handleChangeMic}
                         handleChangeSpeaker={handleChangeSpeaker}
            ></DeviceModal>
            <DeviceErrorModal open={isOpenErrorDevices} handleClose={handleClickCloseErrorDevice}></DeviceErrorModal>
            {
                snowParticles.isShow && <ParticlesContainer/>
            }
            {
                fireworksParticles.isShow && <FireworksParticles/>
            }
            {
                emojiParticles.isShow && <EmojiContainer />
            }
            <RoomDetailModal isShow={isShowRoomDetailModal} handleClose={handleCloseRoomDetailModal}></RoomDetailModal>
            <RecordModal
                isShow={isShowRecordModal}
                handleClose={() => { setShowRecordModal(false); }}
                startRecording={startRecording} />
            <Popover
                open={openAnchorEmojiEl}
                onClose={handleCloseEmojiEl}
                anchorEl={anchorEmojiEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
            >
                <div className="emoji-list">
                    {
                        emojiList.map((emoji, index) => {
                            return <img key={index} className="emoji-item" src={emoji.url} alt={emoji.name}
                                        onClick={() => { handleClickEmoji(emoji); }}
                            />;
                        })
                    }
                    <div className="emoji-quantity">
                        <select name="emoji-quantity" value={emojiQuantity} onChange={handleChangeEmojiQuantity}>
                            <option value="1">x 1</option>
                            <option value="5">x 5</option>
                            <option value="10">x 10</option>
                        </select>
                    </div>
                </div>
            </Popover>
            <ThemePopover open={openAnchorThemePopoverEl} onClose={handleCloseThemePopover}
                          anchorEl={anchorThemePopoverEl}/>
        </>
    );
});

Features.displayName = "Features";
export default Features;
