import React from "react";

interface Props {
    width?: string
    height?: string
    color?: string
}

export default function AcParticipantsOutline02 (props: Props) {
    return <svg width={props.width} height={props.height}
                xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32">
        <defs>
        </defs>
        <g>
            <g id="_レイヤー_1" data-name="レイヤー_1">
                <path fill={props.color}
                      d="M28,3H4C1.8,3,0,4.8,0,7v18c0,2.2,1.8,4,4,4h24c2.2,0,4-1.8,4-4V7c0-2.2-1.8-4-4-4ZM4,26.4c-.8,0-1.4-.6-1.4-1.4V7c0-.8.6-1.4,1.4-1.4h17.4v20.8H4ZM29.4,25c0,.8-.6,1.4-1.4,1.4h-4.3v-5.2h5.7v3.8ZM29.4,18.9h-5.7v-5.7h5.7v5.7ZM23.7,10.8v-5.2h4.3c.8,0,1.4.6,1.4,1.4v3.8h-5.7Z"/>
            </g>
        </g>
    </svg>;
}
