import Header from "./header/header";
import {SidebarLeft} from "./sidebarLeft/sidebarLeft";
import React from "react";
import "./layout.css";
import {ThemeProvider} from "@mui/material/styles";
import {ColorModeEnum, getTheme, ThemeEnum} from "../../services/theme/themeSlice";

interface Props {
    children: React.ReactNode
}

export default function Layout (props: Props) {
    const theme = getTheme(ThemeEnum.light, ColorModeEnum.slate_blue);

    return <ThemeProvider theme={theme}>
        <div>
            <Header/>
            <div className="body-content">
                <SidebarLeft/>
                <div className="content">
                    {props.children}
                </div>
            </div>
        </div>
    </ThemeProvider>;
}
